<template>
  <div>
    <van-tabs v-model="actives" title-active-color="#FE7729" color="#FE7729" @click="tabchange">
      <van-tab v-for="val in tablist" :key="`${val.name}${val.ind}`">
        <template #title>
          <div-text class="#333" size="3vw">{{ $t(`${val.name}`) }}</div-text>
        </template>
      </van-tab>
    </van-tabs>
    <div padding="0">
      <div-padding padding="2vw">
        <div-text size="3.4vw">
          {{ info.name }} {{ info.code }} ({{ $t(`${tablist[actives].name}`) }}) {{ $t('开') }}:{{ hover_data.open_price ||
            '0.00' }}
          {{ $t('高') }}:{{ hover_data.max_price || '0.00' }}
        </div-text>
      </div-padding>
      <div id="Zline" ref="myChart" style="width: 100%; height: 57vw"></div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts"
Date.prototype.Format = function (fmt) {
  let o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "h+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds(), //毫秒
  }
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    )
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      )
  return fmt
}
export default {
  props: {
    product_id: {
      type: [String, Number],
      default: '',
    },
    info: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      tablist: [
        { name: '分时', ind: 1 },
        { name: '日线', ind: 6 },
        { name: '周线', ind: 7 },
        { name: '月线', ind: 8 },
        { name: '年线', ind: 9 },
        { name: '1分', ind: 1 },
        { name: '5分', ind: 2 },
        { name: '15分', ind: 3 },
        { name: '30分', ind: 4 },
        { name: '小时', ind: 5 },
      ],
      actives: 5,
      charts: null,
      charts_data: [],
      hover_data: {},
      list_socket: null,//k线ws
      sned_time: null,
    }
  },
  created() {
    this.getkline()
  },
  mounted() {
    this.initEchart()
    window.onresize = function () {
      this.charts.resize()
    }
  },
  destroyed() {
    this.sned_time && clearInterval(this.sned_time)
    this.list_socket && this.list_socket.close()
  },
  methods: {
    getkline() {
      this.list_socket && this.list_socket.close()
      this.sned_time && clearInterval(this.sned_time)
      this.hover_data = {}
      this.charts_data = []
      this.$post({
        url: "/kline/lists",
        data: { product_id: this.product_id, num: 40, type: this.tablist[this.actives].ind },
        success: (res) => {
          if (!res.data.length) {
            this.calculate()
            this.list_init()
            return
          }
          let data = res.data
          this.charts_data = data.reverse();
          this.hover_data = res.data[0]
          this.calculate()
          this.list_init()
        },
        tip: () => { },
      })
    },
    //格式化数据用
    splitData(rawData) {
      let categoryData = []
      let values = []
      if (rawData.length) {
        rawData.forEach(item => {
          const date = new Date(item.time * 1000).Format("yyyy-MM-dd hh:mm")
          categoryData.push(date.slice(5))
          values.push([item.open_price, item.close_price, item.min_price, item.max_price])
          // vols.push(item.volume)
        })
      }
      return {
        categoryData: categoryData,
        values: values
      }
    },
    calculateMA(dayCount, chartsdata) {
      var result = [];
      for (var i = 0, len = chartsdata.values.length; i < len; i++) {
        if (i < dayCount) {
          result.push('-');
          continue;
        }
        var sum = 0;
        for (var j = 0; j < dayCount; j++) {
          sum += +chartsdata.values[i - j][1]
        }
        result.push((sum / dayCount).toFixed(2))
      }
      return result;
    },
    calculateday(chartsdata) {
      var result = [];
      for (var i = 0, len = chartsdata.values.length; i < len; i++) {
        let data = chartsdata.values[i]
        result.push(data[1])
      }
      return result;
    },
    //k线赋值
    calculate() {
      let chartsdata = this.splitData(this.charts_data)
      let data5 = this.calculateMA(5, chartsdata)
      let data10 = this.calculateMA(10, chartsdata)
      let data20 = this.calculateMA(20, chartsdata)
      let data30 = this.calculateMA(30, chartsdata)
      let intraday = this.calculateday(chartsdata) //分时
      let that = this
      let option = {
        tooltip: {
          formatter: function (params) {
            return params[0].name + '<br>' +
              `${params[0].marker} ${that.$t('open')}：` + params[0].value[1] + '<br>' +
              `${params[0].marker} ${that.$t('close')}：` + params[0].value[2] + '<br>' +
              `${params[0].marker} ${that.$t('low')}：` + params[0].value[3] + '<br>' +
              `${params[0].marker} ${that.$t('hig')}：` + params[0].value[4] + '<br>' +
              `${params[1].marker} MA5：` + params[1].value + '<br>' +
              `${params[2].marker} MA10：` + params[2].value + '<br>' +
              `${params[3].marker} MA20：` + params[3].value + '<br>' +
              `${params[4].marker} MA10：` + params[4].value + '<br>'
          }
        },
        xAxis: [
          {
            data: chartsdata.categoryData,
          }
        ],
        legend: {
          show: false,
          data: ['KLINE', 'MA5', 'MA10', 'MA20', 'MA30', this.$t('分时')],
          selected: {
            'KLINE': this.actives,
            'MA5': this.actives,
            'MA10': this.actives,
            'MA20': this.actives,
            'MA30': this.actives,
            '分时': !this.actives,
            '分時': !this.actives,
            'Intraday': !this.actives,
          }
        },
        series: [
          {
            name: "KLINE",
            type: "candlestick",
            data: chartsdata.values,
            // markLine: {
            //   symbol: "",
            //   silent: true,
            //   data: [
            //     {
            //       yAxis: chartsdata.values.length == 0 ? 0 : chartsdata.values[chartsdata.values.length - 1][1],
            //       label: {
            //         margin: 0,
            //         color: "#ffffff",
            //         position: "end",
            //         backgroundColor: "#000000",
            //         padding: [3, 3, 1, 3],
            //         formatter(val) {
            //           return val.data.yAxis
            //         },
            //       },
            //       lineStyle: {
            //         type: "dotted",
            //         color: "#333",
            //       },
            //     },
            //   ],
            // },
            // barWidth: 5,
          },
          { name: "MA5", data: data5 },
          { name: "MA10", data: data10 },
          { name: "MA20", data: data20 },
          { name: "MA30", data: data30 },
          { name: this.$t('分时'), data: intraday },
        ],
      }
      this.charts.hideLoading()
      this.charts.setOption(option)
      this.charts.resize()
    },
    tabchange(index) {
      console.log('cahe', index);
      this.sned_time && clearInterval(this.sned_time)
      this.list_socket && this.list_socket.close()
      this.getkline()
    },
    //k线列表 ws init
    list_init() {
      if (typeof WebSocket === "undefined") {
        this.$toast("您的浏览器不支持socket")
      } else {
        this.list_socket = new WebSocket(this.wsPath)
        this.list_socket.onopen = this.list_open
        this.list_socket.onmessage = this.list_message
        this.list_socket.onerror = this.list_error
        this.list_socket.onclose = this.list_close
      }
    },
    //k线列表 ws
    list_open() {
      // console.log("k线连接成功")
      this.list_send()
      this.sned_time = setInterval(() => {
        this.list_send()
      }, 3000)
    },
    //k线列表 ws
    list_send() {
      // console.log("k线发送")
      const params = JSON.stringify({ type: 'now_price', product_id: this.product_id, time_type: this.tablist[this.actives].ind })
      this.list_socket.send(params)
    },
    //k线列表 ws
    list_message(msg) {
      if (!msg?.data) return;
      let data = JSON.parse(msg.data)
      if (typeof data === "object" && data.type == "now_price" && data.data.length > 0) {
        this.charts_data = data.data.reverse();
        this.hover_data = data.data[0]
        this.calculate()
      }
    },
    //k线列表 ws
    list_error() {
      console.log("k线连接错误")
    },
    //k线列表 ws
    list_close() {
      console.log("k线关闭")
    },
    // k线初始化
    initEchart() {
      let _that = this
      let option = {
        tooltip: {
          trigger: "axis"
        },
        xAxis: [
          {
            show: false,
            type: "category",
          }
        ],
        yAxis: [
          {
            scale: true,
            splitArea: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "#666666",
              },
            },
            axisLabel: {
              textStyle: {
                color: "#666666",
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "#666666",
                type: "dashed",
              },
            },
            position: "right",
          }
        ],
        legend: {
          show: false,
          data: ['KLINE', 'MA5', 'MA10', 'MA20', 'MA30', this.$t('分时')],
          selected: {
            '分时': false,
            '分時': false,
            'Intraday': false,
          }
        },
        grid: [
          {
            left: "3%",
            right: "13%",
            bottom: '3%',
            top: '4%',
          }
        ],
        dataZoom: [
          {
            type: "inside",
            start: 75,
            end: 100,
            filterMode: 'empty'
          }
        ],
        series: [
          {
            name: "KLINE",
            type: "candlestick",
            data: [],
            itemStyle: {
              color: "#24ae64",
              color0: "#e04040",
              borderColor: "#24ae64",
              borderColor0: "#e04040",
            },
          },
          {
            name: "MA5",
            type: "line",
            smooth: true,
            showSymbol: false,
            lineStyle: {
              opacity: 0.5,
            },
          },
          {
            name: "MA10",
            type: "line",
            smooth: true,
            showSymbol: false,
            lineStyle: {
              opacity: 0.5,
            },
          },
          {
            name: "MA20",
            type: "line",
            smooth: true,
            showSymbol: false,
            lineStyle: {
              opacity: 0.5,
            },
          },
          {
            name: "MA30",
            type: "line",
            smooth: false,
            showSymbol: false,
            lineStyle: {
              opacity: 0.5,
            },
          },
          {
            name: this.$t('分时'),
            type: "line",
            data: [],
            showSymbol: false,
            smooth: false,
            lineStyle: {
              opacity: 0.5,
            },
            symbol: 'circle',
            symbolSize: 6,
            itemStyle: {
              normal: {
                color: '#8A9AEF',
                lineStyle: {
                  color: '#8A9AEF'
                }
              }
            },
            areaStyle: {
              background: 'linear-gradient(180deg, #D8B9D4 0%, #7584F4 100%)',
            },
          },
        ]
      }
      _that.charts = echarts.init(_that.$refs.myChart)
      _that.charts.setOption(option)
      _that.charts.resize()
    },
  },
}
</script>

<style scoped lang="less">
.flex {
  display: flex;
  align-items: center;
}
.echartbox {
  width: 100%;
  height: 57vw;
  #Zline {
    div {
      width: 100%;
      display: felx !important;
      justify-content: flex-end !important;
    }
    height: 100% !important;
    overflow: hidden;
  }
}
</style>