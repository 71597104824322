<template>
  <div-box>
    <nav-bar left-arrow :title="$t('系统设置')" fixed :bgColor="$c('#fff')" :leftColor="$c('#333')"
      :titleColor="$c('#333')" />

    <div-padding padding="4vw">
      <!-- <div-card>
        <div-padding padding="4vw 0">
          <van-cell>
            <template #title>
              <div-text>{{
                the_theme ? "关闭夜间模式" : "开启夜间模式"
              }}</div-text>
            </template>
<template #right-icon>
              <van-switch active-color="#e1503c" v-model="the_theme" size="18" @click="opt_themes()" />
            </template>
</van-cell>
</div-padding>
<van-divider />
</div-card> -->

      <div-card @click.native="router('langue')">
        <div-padding padding="4vw 0">
          <van-cell is-link>
            <template #title>
              <div-text>选择语言</div-text>
            </template>
            <template>
              <div-text>{{
      { zh: "简体中文", hk: "繁体中文", en: "English" }[
      this.getLang()
      ]
    }}</div-text>
            </template>
          </van-cell>
        </div-padding>
        <van-divider />
      </div-card>

      <div-card>
        <div-padding padding="4vw 0">
          <van-cell is-link @click="router('bind')">
            <template #title>
              <div-text>收款方式</div-text>
            </template>
          </van-cell>
        </div-padding>
        <van-divider />
      </div-card>
      <div-card>
        <div-padding padding="4vw 0">
          <van-cell is-link @click="router('address')">
            <template #title>
              <div-text>收获地址</div-text>
            </template>
          </van-cell>
        </div-padding>
        <van-divider />
      </div-card>

      <div-card>
        <div-padding padding="4vw 0">
          <van-cell is-link @click="router('business')">
            <template #title>
              <div-text v-if="business == 1">我是机构</div-text>
              <div-text v-if="business != 1">机构码</div-text>
            </template>
            <template>
              <div-text v-if="business == 1">{{ business_code }}</div-text>
              <div-text v-if="business != 1 && is_bind == 0">未绑定</div-text>
              <div-text v-if="business != 1 && is_bind == 1">已绑定</div-text>
            </template>
          </van-cell>
        </div-padding>
        <van-divider />
      </div-card>
      <div-card @click.native="router('bz_profile')">
        <div-padding padding="4vw 0">
          <van-cell is-link>
            <template #title>
              <div-text>备注</div-text>
            </template>
            <template>
              <div-right>
                <div-text one v-if="info.remark">{{ info.remark }}</div-text>
                <div-text one color="#999" v-else>{{ $t('暂无备注') }}</div-text>
              </div-right>
            </template>
          </van-cell>
        </div-padding>
        <van-divider />
      </div-card>
      <div-card>
        <div-padding padding="4vw 0">
          <van-cell is-link @click="router('agreement', 1)">
            <template #title>
              <div-text>用户协议</div-text>
            </template>
          </van-cell>
        </div-padding>
        <van-divider />
      </div-card>
      <div-card>
        <div-padding padding="4vw 0">
          <van-cell is-link @click="router('agreement', 2)">
            <template #title>
              <div-text>隐私政策</div-text>
            </template>
          </van-cell>
        </div-padding>
        <van-divider />
      </div-card>
      <div-card>
        <div-padding padding="4vw 0">
          <van-cell is-link @click="router('login_password')">
            <template #title>
              <div-text>修改登录密码</div-text>
            </template>
          </van-cell>
        </div-padding>
        <van-divider />
      </div-card>

      <div-card>
        <div-padding padding="4vw 0">
          <van-cell is-link @click="router('payment_password')">
            <template #title>
              <div-text>修改支付密码</div-text>
            </template>
          </van-cell>
        </div-padding>
        <van-divider />
      </div-card>

      <div-card>
        <div-padding padding="4vw 0">
          <van-cell is-link @click="logOff">
            <template #title>
              <div-text>注销账户</div-text>
            </template>
          </van-cell>
        </div-padding>
        <van-divider />
      </div-card>
      <div-height height="13vw"></div-height>
      <!-- 按钮 -->
      <div-btn ref="btn" radius="2vw" @fun="loginout">
        <div-text size="4vw" color="#FFF">退出登录</div-text>
      </div-btn>
      <div-height height="11vw"></div-height>

    </div-padding>
  </div-box>
</template>
<script>
export default {
  data() {
    return {
      business: "",
      business_code: "",
      is_bind: "",
      bind_code: "",
      checked: false,
      the_theme: this.theme == "dark",
      info: {},
    }
  },
  created() {
    this.get_info()
  },
  methods: {
    logOff() {
      this.$dialog
        .confirm({
          title: this.$t("注销账户"),
          message: this.$t("注销账户后，您的账户将无法找回，确定要注销吗？"),
        })
        .then(() => {
          this.$post({
            loading: true,
            url: "logOff",
            success: (res) => {
              this.$router.replace({
                name: "login",
              })
            },
            tip: () => { },
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    get_info() {
      // post 请求
      this.$post({
        loading: true,
        url: "user_profile",
        success: (res) => {
          this.info = res.data
          this.business = res.data.business
          if (this.business != 1) {
            this.get_business()
          }
          this.business_code = res.data.business_code
        },
        tip: () => { },
      })
    },
    get_business() {
      this.$get({
        loading: true,
        url: "business/bind_info",
        success: (res) => {
          this.is_bind = res.data.is_bind
          this.bind_code = res.data.bind_code
          console.log(res)
        },
        tip: () => { },
      })
    },
    change() {
      if (this.getLang() == "zh") {
        this.changeLang("en")
      } else {
        this.changeLang("zh")
      }
    },
    opt_themes() {
      this.opt_theme()
    },
    loginout() {
      this.$post({
        loading: true,
        url: "logout",
        success: (res) => {
          this.$router.replace({
            name: "login",
          })
        },
        tip: () => { },
      })
    },
  },
}
</script>
<style scoped lang="less"></style>
