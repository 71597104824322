<template>
  <div-box tabber>
    <div-navber title="详情"></div-navber>
    <van-tabs v-if="info.type == 3" v-model="active" title-active-color="#EF834C" color="#EF834C">
      <van-tab>
        <template #title>
          <div-padding padding="0 3vw">
            <div-text class="#666">报价</div-text>
          </div-padding>
        </template>
      </van-tab>
      <van-tab>
        <template #title>
          <div-padding padding="0 3vw">
            <div-text class="#666">简况</div-text>
          </div-padding>
        </template>
      </van-tab>
    </van-tabs>
    <div v-if="active == 0">
      <van-skeleton title :row="5" :loading="loading">
        <!-- 内容 -->
        <div-padding padding="4vw">
          <div-left>
            <div-text size="4vw" color="#000000" bold>{{ info.name }}</div-text>
            <div-width width="2vw"></div-width>
            <div-text size="4vw" color="#000000" bold>{{ info.code }}</div-text>
          </div-left>
          <div-height height="1vw" />
          <div-text size="3.5vw" color="#666">{{ info.status_desc }}</div-text>
          <div-height height="1vw" />
        </div-padding>
        <div-padding padding="0 4vw">
          <div-card :bg="$c('#ffffff')" radius="2vw" shadow="0px 1vw 2vw 0px rgba(0,0,0,0.16)">
            <div-padding padding="4vw 3vw">
              <van-row type="flex" justify="space-between" align="center">
                <van-col span="8">
                  <div-text size="5vw" color="#000000" bold>{{ info.market }}</div-text>
                  <div-left>
                    <div-text size="3vw" :color="info.up_price >= 0 ? '#E45F50' : '#31d90b'">{{ info.up_price >= 0 ? '+' +
                      info.up_price : info.up_price }}</div-text>
                    <div-width width="3vw"></div-width>
                    <div-text size="3vw" :color="info.up_ratio >= 0 ? '#E45F50' : '#31d90b'">{{ info.up_ratio >= 0 ? '+' +
                      info.up_ratio : info.up_ratio }}%</div-text>
                  </div-left>
                  <!-- <div-text size="3vw" color="#5B687E">成交额</div-text> -->
                </van-col>
                <van-col span="7">
                  <div-both>
                    <div-text size="3vw" color="#5B687E">最高</div-text>
                    <div-text size="3vw" color="#000000" v-if="info.highlow">{{ info.highlow.high }}</div-text>
                  </div-both>
                  <div-both>
                    <div-text size="3vw" color="#5B687E">最低</div-text>
                    <div-text size="3vw" color="#000000" v-if="info.highlow">{{ info.highlow.low }}</div-text>
                  </div-both>
                  <!-- <div-both>
                  <div-text size="3vw" color="#5B687E">资产规模</div-text>
                  <div-text size="3vw" color="#000000">634.887</div-text>
                </div-both> -->
                  <div-both>
                    <div-text size="3vw" color="#5B687E">换手率</div-text>
                    <div-text size="3vw" color="#000000">{{ info.exchange }}</div-text>
                  </div-both>
                  <div-both>
                    <div-text size="3vw" color="#5B687E">成交额</div-text>
                    <div-text size="3vw" color="#000000">{{ info.success_price }}</div-text>
                  </div-both>
                </van-col>
                <van-col span="2"> </van-col>
                <van-col span="7">
                  <div-both>
                    <div-text size="3vw" color="#5B687E">今开</div-text>
                    <div-text size="3vw" color="#000000">{{ info.open_price }}</div-text>
                  </div-both>
                  <div-both>
                    <div-text size="3vw" color="#5B687E">昨收</div-text>
                    <div-text size="3vw" color="#000000">{{ info.yesterday_close }}</div-text>
                  </div-both>
                  <div-both>
                    <div-text size="3vw" color="#5B687E">委比</div-text>
                    <div-text size="3vw" color="#000000">{{ info.apply_ratio }}</div-text>
                  </div-both>
                  <div-both>
                    <div-text size="3vw" color="#5B687E">成交量</div-text>
                    <div-text size="3vw" color="#000000">{{ info.success_num }}</div-text>
                  </div-both>
                  <!-- <div-both>
                  <div-text size="3vw" color="#ffffff">空</div-text>
                </div-both> -->
                </van-col>
              </van-row>
            </div-padding>
          </div-card>
        </div-padding>
      </van-skeleton>
      <!--把子组件放到想放的位置-->
      <div class="chart" v-if="info.name">
        <kline-chart :product_id='id' :info="info" />
      </div>
      <div-height height="2vw" bg="#F8F8F8"></div-height>
      <div-height height="4vw"></div-height>
      <sblist :product_id='id' :info="info" :sell_out="sell_son" />
      <van-dialog v-model="show" :beforeClose="beforeClose" show-cancel-button :confirmButtonColor="$c('#000000')"
        :cancelButtonColor="$c('#000000')">
        <div-card>
          <div-padding padding="6vw 6vw 0 6vw">
            <div-left center>
              <div-text size="5vw" bold>{{ name }}</div-text>
              <div-width width="5vw"></div-width>
              <div-text size="3vw" v-if="info.highlow">{{ $t('价格范围') }}：</div-text>
              <div-text size="3vw" v-if="info.highlow">{{ info.min_price }}</div-text>
              <div-text size="3vw" v-if="info.highlow">&nbsp;-&nbsp;</div-text>
              <div-text size="3vw" v-if="info.highlow">{{ info.max_price }}</div-text>
              <div-text size="3vw" v-if="info.highlow">&nbsp;</div-text>
              <div-text size="3vw" v-if="info.highlow">{{ $t('之间') }}</div-text>
            </div-left>
            <div-height height="4vw" />

            <div-card :bg="$c('#F8F8F8')" radius="1vw">
              <div-padding padding="3vw 4vw">
                <div-both>
                  <div-left center>
                    <div-img width="4vw" height="4vw">
                      <img src="~@/assets/img/icon01.png" v-if="theme == 'light'" />
                      <img src="~@/assets/img/icon01-.png" v-else />
                    </div-img>
                    <div-width width="3vw"></div-width>
                    <div-text>价格</div-text>
                  </div-left>

                  <van-stepper style="display: flex;" v-if="checked == true" :min="info.min_price" :max="info.max_price"
                    v-model="price" theme="round" button-size="22" :decimal-length="2" />
                  <van-stepper style="display: flex;" v-if="checked == false" :min="info.min_price" :max="info.max_price"
                    v-model="prices" theme="round" button-size="22" :disabled="shop" :decimal-length="2" />
                </div-both>
              </div-padding>
            </div-card>

            <div-height height="4vw" />

            <div-card :bg="$c('#F8F8F8')" radius="1vw">
              <div-padding padding="3vw 4vw">
                <div-both>
                  <div-left center>
                    <div-img width="4vw" height="4vw">
                      <img src="~@/assets/img/icon02.png" v-if="theme == 'light'" />
                      <img src="~@/assets/img/icon02-.png" v-else />
                    </div-img>
                    <div-width width="3vw"></div-width>
                    <div-text>数量</div-text>
                  </div-left>
                  <van-stepper style="display: flex" @change="onChange" :min="info.min_num" v-model="num" theme="round"
                    button-size="22" integer />
                </div-both>
              </div-padding>
            </div-card>

            <div-height height="4vw" />

            <div-card :bg="$c('#F8F8F8')" radius="1vw">
              <div-padding padding="3vw 4vw">
                <van-field clearable :border="false" v-model="password" type="password" max-length="6"
                  :placeholder="$t('请输入交易密码')">
                  <template #label>
                    <div-img width="4vw" height="4vw">
                      <img src="~@/assets/img/icon03.png" v-if="theme == 'light'" />
                      <img src="~@/assets/img/icon03-.png" v-else />
                    </div-img>
                  </template>
                </van-field>
              </div-padding>
            </div-card>
            <div-height height="4vw" />
            <div-left>
              <van-checkbox icon-size="5vw" checked-color="#EF834C" v-model="checked" :disabled="disabled"></van-checkbox>
              <div-width width="2vw"></div-width>
              <div-text size="4vw" color="#666">限价模式</div-text>
            </div-left>

            <div-height height="4vw" />
            <van-divider />
          </div-padding>
        </div-card>
      </van-dialog>
      <van-goods-action>
        <van-goods-action-button v-if="info.is_pack == 1" color="linear-gradient(137deg, #EF834C 0%, #E9A944 100%)"
          type="warning" :text="$t('购买套餐')" :to="`package?product_id=${id}`" />
        <van-goods-action-button color="#EF834C" type="warning" :text="$t('卖出')" @click="sell_out(2, '卖出')" />
        <van-goods-action-button color="#E9A944" type="danger" :text="$t('买入')" @click="sell_out(1, '买入')" />
        <van-goods-action-icon icon="star" :text="$t('自选')" :color="info.selected == 1 ? '#ff5000' : '#999999'"
          @click="open(info.selected)" />
      </van-goods-action>
    </div>
    <div v-if="active == 1">
      <div-padding padding="4vw">
        <Corp :info="info" />
      </div-padding>
    </div>
  </div-box>
</template>
<script>
import KlineChart from "./kline/k.vue"
import sblist from "./kline/sblist.vue"
import Corp from "./kline/corps.vue" //关键词吧
export default {
  name: "SimpleChart",
  components: {
    KlineChart,
    Corp,
    sblist,
  },
  data() {
    return {
      active: 0,
      shop: true,
      price: "", //价格
      prices: "",
      num: "", //数量
      name: "", //买入卖出
      // min: "",
      password: "",
      checked: false,
      show: false,
      list: [],
      info: {},
      limited_price: "",
      market_price: "",
      disabled: false,
      color: "",
      loading: true,
    }
  },
  created() {
    this.setUrlData()
    this.get_info()
  },
  methods: {
    onChange(value) {
      this.get_info()
      if (value <= this.info.min_num) {
        this.num = this.info.min_num
      } else {
        this.num = value
      }
    },
    open(selected) {
      this.$dialog
        .confirm({
          // title: "标题",
          message: `${this.$t("是否")}${selected ? this.$t("取消") : ""
            }${this.$t("自选")}`,
        })
        .then(() => {
          this.$post({
            url: "/user_collection/submit",
            data: {
              product_id: this.id,
            },
            success: (res) => {
              this.$toast(res.msg)

              this.get_info()
            },
            tip: () => { },
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    sell_out(by, name) {
      this.by = by
      this.name = name
      this.show = true
    },
    sell_son(by, name, price) {
      this.by = by
      if (this.limited_price == 0 && this.market_price == 1) {
        //市价交易
        this.checked = false
        this.disabled = true
        this.prices = this.info.market
        this.shop = true
      }
      if (this.limited_price == 1 && this.market_price == 0) {
        //限价交易
        this.checked = true
        this.disabled = false
        this.prices = price
        this.shop = false
      }
      if (this.limited_price == 1 && this.market_price == 1) {
        //自选
        if (price != this.price) {
          this.checked = true
          this.price = price
        } else {
          this.prices = price
          this.checked = false
        }
      }

      this.name = name
      this.show = true
    },
    beforeClose(action, done) {
      if (!this.price) {
        this.price = this.prices
      }
      if (action === "confirm") {
        this.$post({
          url: "/product_order/submit",
          data: {
            product_id: this.id,
            num: this.num,
            type: this.by, //1.买入2.卖出
            price: this.checked == true ? this.price : this.prices,
            password: this.password,
          },
          success: (res) => {
            this.$toast(res.msg)
            setTimeout(done, 1000)
          },
          tip: () => {
            setTimeout(done, 1000)
          },
        })
      } else {
        done()
      }
    },
    get_info() {
      this.loading = true
      this.$get({
        loading: true,
        url: "/product/detail",
        data: { id: this.id },
        success: (res) => {
          this.info = res.data
          this.limited_price = res.data.limited_price
          this.market_price = res.data.market_price
          //  limited_price是否限价交易
          //  market_price是否市价交易
          if (this.limited_price == 1 && this.market_price == 1) {
            //自选
            this.price = this.info.market
            this.prices = this.info.market
            console.log("//自选", this.checked)
          }
          if (this.limited_price == 1 && this.market_price == 0) {
            //限价交易
            this.checked = true
            this.disabled = true
          }
          if (this.limited_price == 0 && this.market_price == 1) {
            //市价交易
            this.checked = false
            this.disabled = true
            this.prices = this.info.market
            this.shop = true
            console.log("//市价交易", this.checked)
          }
          this.loading = false
        },
        tip: () => {
          this.loading = false
        },
      })
    },
  },
  mounted: function () { },
}
</script>

<style scoped lang="less">
/deep/.van-dialog {
  z-index: 99999999999 !important;
}
/deep/.van-stepper__input {
  width: 14vw !important;
}
//自选精选
/deep/.van-tabs__wrap {
  display: flex;
  .van-tabs__line {
    height: 1.5vw !important;
    width: 5vw !important;
  }
}
/deep/.van-progress__pivot {
  display: none;
}
/deep/.van-goods-action-button {
  border-radius: 1vw;
  margin: 0 2vw;
}
// 明亮
.light {
  /deep/.van-goods-action {
    background: #fff;
  }
  .van-goods-action-icon {
    color: #999999;
    background: #fff;
  }
}
//暗黑
.dark {
  /deep/.van-goods-action {
    background: #242424;
  }
  .van-goods-action-icon {
    color: #fff;
    background: #242424;
  }
}
</style>
