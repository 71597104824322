<template>
    <div-box>
        <div-navber title="商品" />
        <vant-up-down @list="(val) => (list = val)" res="res.data.data" url="/goods/lists"
            :data="{ is_home: 0, cate_id: id, keywords: keywords }" ref="child" :init="true">
            <div-padding padding="3vw ">
                <!-- 搜索 -->
                <div class="search">
                    <div-card bg="#F8F8F8" radius="5vw">
                        <div-both center>
                            <div-padding padding="2vw 4vw">
                                <div-left center>
                                    <div-img height="4vw" width="4vw">
                                        <img :src="require('@/assets/img/sousuo_icon.png')" />
                                    </div-img>
                                    <div-width width="4vw" />
                                    <van-field v-model="text" placeholder="请输入商品名称" />
                                </div-left>
                            </div-padding>
                            <div-card bg="#EF834C" radius="5vw" @click="search">
                                <div-padding padding="2vw 4vw">
                                    <div-text color="#fff" size="4vw">搜索</div-text>
                                </div-padding>
                            </div-card>
                        </div-both>
                    </div-card>
                </div>
            </div-padding>
            <div-padding padding="0 4vw">
                <div-both br>
                    <div class="item" @click="router('good', { id: item.id })" v-for="(item, index) in list"
                        :key="index">
                        <div-width size="44vw">
                            <div-card bg="#FBFBFB" radius="2vw">
                                <div-img width="44vw" height="44vw" radius="2vw">
                                    <img :src="httpPath + item.cover" />
                                </div-img>
                                <div-padding padding="3vw">
                                    <div-text size="3.5vw" color="#000000" one>{{ item.title }}</div-text>
                                    <div-height size="2vw" />
                                    <div-both>
                                        <div-text size="3.5vw" color="#FA7A26">￥{{ item.price }}</div-text>
                                    </div-both>
                                </div-padding>
                            </div-card>
                            <div-height size="3vw" />
                        </div-width>
                    </div>
                </div-both>
            </div-padding>
        </vant-up-down>
    </div-box>
</template>

<script>
export default {
    data() {
        return {
            text: '',
            list: [],
            id: '',
            keywords: ''
        };
    },
    created() {
        this.id = this.$route.query.id;
        setTimeout(() => {
            this.$refs.child.list_init();
        });
    },
    methods: {
        search() {
            // Perform search logic here
            // You can update the products list or make an API call to fetch the filtered products
        }
    }
};
</script>