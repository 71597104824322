<template>
  <div-box>
    <div-navber title="修改昵称"></div-navber>
    <div-height size="7vw"></div-height>

    <div-padding padding="0 4vw">
      <!-- 标题 -->
      <div-height height="12vw"></div-height>
      <!-- //顶部 -->

      <div-card>
        <div-padding padding="3vw 0">
          <van-field clearable v-model="phone" :placeholder="$t('请输入您的昵称')">
            <template #label>
              <div-text size="4vw" color="#666">昵称</div-text>
            </template>
          </van-field>
        </div-padding>
        <van-divider />
      </div-card>
      <div-height height="50vw"></div-height>
      <div-btn ref="btn" radius="2vw" @fun="save">
        <div-text size="4vw" color="#FFF">保存</div-text>
      </div-btn>
      <div style="height: 4vw"></div>
    </div-padding>
  </div-box>
</template>
<script>
export default {
  data() {
    return {
      phone: "", //手机号
    }
  },
  created() {},
  methods: {
    save() {
      if (!this.phone) {
        this.$refs.btn.disabled = false
        return this.$toast("请输入您的昵称")
      }

      // 修改密码
      this.$post({
        url: "/user/fix_info",

        data: {
          nickname: this.phone,
        },
        success: () => {
          this.$toast("修改成功")
          setTimeout(() => {
            this.goBack()
            this.$refs.btn.disabled = false
          }, 1500)
        },
        tip: (val) => {
          setTimeout(() => {
            this.$refs.btn.disabled = false
          }, 1500)
        },
      })
    },
  },
}
</script>
<style lang='less' scoped>
</style>
