<template>
  <div-box bg="#F9FAFB">
    <nav-bar left-arrow :title="$t('机构钱包')" fixed bgColor="#EF834C" leftColor="#fff" titleColor="#fff" />
    <div-height height="3vw"></div-height>
    <div-padding padding="4vw">
      <div-card :bg="$c('#ffffff')" radius="2vw" shadow="0px 1vw 2vw 0px rgba(0,0,0,0.16)">
        <div-padding padding="5vw 3vw">
          <div-height height="2vw"></div-height>
          <div-text center>可用机构余额(元)</div-text>
          <div-height height="3vw"></div-height>
          <div-text size="10vw" center>{{ business_money }}</div-text>
          <div-height height="8vw"></div-height>
          <div-center>
            <div-width width="42vw">
              <div-btn ref="btn" radius="2vw" @fun="router('cash_out_bus')" border="1px solid #E6E6E6" bg="#fff"
                color="#000000">
                <div-text color="#555">提现</div-text>
              </div-btn>
            </div-width>
          </div-center>
        </div-padding>
      </div-card>
    </div-padding>

    <div-padding padding="0 4vw">
      <vant-up-down ref="list" @list="(val) => (list = val)" res="res.data.data" url="/user/money_record"
        :data="{ credit_type: 2 }">
        <div-card :bg="$c('#ffffff')" radius="2vw" shadow="0px 1vw 2vw 0px rgba(0,0,0,0.16)">
          <div-padding padding="4vw ">
            <van-cell is-link @click="router('wallet_list_bus')">
              <template #title>
                <div-text size="4vw" color="#000000" bold>机构余额变动明细</div-text>
              </template>
              <template>
                <div-text color="#999999">全部</div-text>
              </template>
            </van-cell>
          </div-padding>
          <van-divider />

          <div class="item" v-for="(item, index) in list" :key="index">
            <div-padding padding="3vw 4vw">
              <div-both center>
                <div-left center>
                  <div>
                    <div-left center>
                      <div-text color="#333">{{ item.type_desc }}</div-text>
                      <div-width width="1vw"></div-width>
                    </div-left>
                    <div-height height="2vw"></div-height>
                    <div-text color="#8A8A8A">{{ item.created_at }}</div-text>
                  </div>
                </div-left>
                <div-right center>
                  <div-text color="#EF834C" bold v-if="item.type == 1">+{{ item.num }}</div-text>
                  <div-text color="#39AD44" bold v-if="item.type == 2">-{{ item.num }}</div-text>
                </div-right>
              </div-both>
            </div-padding>
          </div>
        </div-card>
      </vant-up-down>
    </div-padding>

    <!-- <vant-up-down ref="list" @list="(val) => (list = val)" res="res.data.data" url="business/income_list" data="">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div-padding padding="2vw 4vw">
          <div-card :bg="$c('#ffffff')" radius="1vw">
            <div-padding padding="3vw 4vw">
              <div class="reder"></div>

              <div-text bold>{{item.out_user.real_name}}</div-text>
              <div-height height="2vw"></div-height>
              <div-text size="3vw">{{item.out_user.phone}}</div-text>
              <div-height height="2vw"></div-height>
              <van-divider />

              <div-height height="2vw"></div-height>
              <div-both fill>
                <div-text size="3vw">{{$t("交易金额")}}：{{item.total_price}}</div-text>

                <div-text size="3vw">{{$t("收益金额")}}：{{item.business_price}}</div-text>
              </div-both>
            </div-padding>
          </div-card>
        </div-padding>
      </div>
    </vant-up-down> -->
  </div-box>
</template>

<script>
export default {
  data() {
    return {
      business_money: '--',
      list: "", //银行名称
    }
  },
  created() {
    // 滚动到顶部
    this.vant_list()
    this.get_info()
  },
  methods: {
    save() {
      console.log(123)
    },
    get_info() {
      this.$post({
        url: "/user_profile",
        success: (res) => {
          this.business_money = res.data.business_money
        },
        tip: () => { },
      })
    },
  },
}
</script>

<style lang="less" scoped="scoped">
.reder {
  width: 1vw;
  height: 4vw;
  background: #EF834C;
  position: absolute;
  top: 4vw;
  left: 0;
}
</style>
