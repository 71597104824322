<template>
  <div-box>
    <div-navber title="资讯列表"></div-navber>
    <!-- 封装上拉下拉组件 坐标 vant-extend 文件 -->

    <div class="new_box">
      <div-padding padding="0 4vw">
        <vant-up-down ref="list" @list="(val) => (list = val)" res="res.data.data" url="/article/lists
" :data="{ classify_id: 2 }">
          <div class="item" @click="router('zixun', { id: item.id })" v-for="(item, index) in list" :key="index">
            <div-card>
              <div-left>
                <div-img width="29vw" height="20vw" radius="2vw">
                  <img :src="$img(item.cover)" />
                </div-img>
                <div-padding padding="0 3vw">
                  <div-both column>
                    <div-text size="4vw" color="#000000" two bold>
                      {{ item.title }}
                    </div-text>
                    <div-text size="3vw" color="#8A8A8A">官方发布{{item.date}}</div-text>
                  </div-both>
                </div-padding>
              </div-left>
            </div-card>
            <div v-if="index * 1 + 1 != list.length">
              <div-height height="4vw" />
              <van-divider />
              <div-height height="4vw" />
            </div>
          </div>
        </vant-up-down>
      </div-padding>
      <div-height height="4vw" />
    </div>
  </div-box>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    }
  },
  created() {
    // 滚动到顶部
    this.vant_list()
  },
  methods: {},
}
</script>

<style scoped lang='scss'>
</style>
