<template>
  <div-box>
    <div-navber :title="{ 1: '确权明细', 2: '锁仓明细' }[position_type]" />
    <div-height height="3vw"></div-height>
    <vant-up-down ref="list" @list="(val) => (list = val)" res="res.data.data" url="/user_position/log_lists"
      :data="{ position_type, product_id }">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div-padding padding="4vw 4vw">
          <div-both center>
            <div-left center>
              <div>
                <div-left center>
                  <div-text color="#333" bold>{{ item.trend_desc }}</div-text>
                  <div-width width="1vw"></div-width>
                </div-left>
                <div-height height="1vw"></div-height>
                <div-text color="#8A8A8A" size="3vw">{{ item.created_at }}</div-text>
              </div>
            </div-left>
            <div-right center>
              <div-text color="#EF834C" bold v-if="item.type == 1">+{{ item.num }}</div-text>
              <div-text color="#39AD44" bold v-if="item.type == 2">-{{ item.num }}</div-text>
            </div-right>
          </div-both>
        </div-padding>
      </div>
    </vant-up-down>
  </div-box>
</template>

<script>
export default {
  data() {
    return {
      list: "",
      position_type: "",
      product_id: "",
    }
  },
  created() {
    this.setUrlData()
    this.vant_list()
  },
  methods: {},
}
</script>

<style lang="less" scoped="scoped"></style>
