<template>
  <div-box>
    <div-navber title="收款方式"></div-navber>
    <div-height size="3vw"></div-height>
    <div-padding padding="4vw">

      <div-card>
        <div-padding padding="4vw 0">
          <van-cell is-link @click="router('bank')">
            <template #title>
              <div-text>银行卡</div-text>
            </template>
            <template>
              <div-text>{{!bank ? '未绑定' : '已绑定'}}</div-text>
            </template>
          </van-cell>
        </div-padding>
        <van-divider />
      </div-card>

      <!-- <div-card>
        <div-padding padding="4vw 0">
          <van-cell is-link @click="router('wx')">
            <template #title>
              <div-text>微信</div-text>
            </template>
            <template>
              <div-text>{{!wx ? '未绑定' : '已绑定'}}</div-text>
            </template>
          </van-cell>
        </div-padding>
        <van-divider />
      </div-card> -->

      <!-- <div-card>
        <div-padding padding="4vw 0">
          <van-cell is-link @click="router('zfb')">
            <template #title>
              <div-text>支付宝</div-text>
            </template>
            <template>
              <div-text>{{!ali ? '未绑定' : '已绑定'}}</div-text>
            </template>
          </van-cell>
        </div-padding>
        <van-divider />
      </div-card> -->

    </div-padding>
  </div-box>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      info: {},
      bank: false,
      wx: false,
      ali: false,
    }
  },
  computed: {},
  watch: {},
  methods: {
    getdata() {
      this.$get({
        url: "/user_pay_collection/query",
        success: (res) => {
          console.log("收款方式", res)
          this.info = res.data
          this.bank = res.data.bank.status
          this.wx = res.data.wx.status
          this.ali = res.data.ali.status
        },
        tip: () => {},
      })
    },
  },
  created() {
    this.getdata()
  },
}
</script>
<style lang='less' scoped>
</style>
