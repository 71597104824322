<template>
  <div-box>
    <div-navber :title="$t('绑定银行卡')"></div-navber>
    <div-height size="3vw"></div-height>
    <div-padding padding="4vw">
      <div-card>
        <div-padding padding="4vw 0">
          <van-field input-align="right" clearable :border="false" v-model="name" :placeholder="$t('请输入持卡人姓名')">
            <template #label>
              <div-text size="4vw" color="#19211D">姓名</div-text>
            </template>
          </van-field>
        </div-padding>
        <van-divider />
      </div-card>

      <div-card>
        <div-padding padding="4vw 0">
          <van-field input-align="right" clearable :border="false" v-model="account_number"
            :placeholder="$t('请输入银行卡卡号')">
            <template #label>
              <div-text size="4vw" color="#19211D">银行卡号</div-text>
            </template>
          </van-field>
        </div-padding>
        <van-divider />
      </div-card>

      <div-card>
        <div-padding padding="4vw 0">
          <van-field input-align="right" clearable :border="false" v-model="bank" :placeholder="$t('请输入银行名称')">
            <template #label>
              <div-text size="4vw" color="#19211D">银行名称</div-text>
            </template>
          </van-field>
        </div-padding>
        <van-divider />
      </div-card>

      <div-card>
        <div-padding padding="4vw 0">
          <van-field clearable v-model="sms_code" type="text" :placeholder="$t('请输入您的验证码')">
            <template #label>
              <div-text size="4vw" color="#19211D">验证码</div-text>
            </template>
            <template #button>
              <send-code plain api="/sendsms" :send-tell="{ phone: phone }" :send-scene="{ scene: 'card' }"
                :no-token="true" color="#EF834C" round>
              </send-code>
            </template>
          </van-field>
        </div-padding>
        <van-divider />
      </div-card>

      <div-height height="20vw"></div-height>
      <div-btn ref="btn" radius="2vw" @fun="save">
        <div-text size="4vw" color="#FFF">确定</div-text>
      </div-btn>
      <div-height height="11vw"></div-height>
    </div-padding>
  </div-box>
</template>

<script>
export default {
  data() {
    return {
      type: 3, //类型 1 支付宝 2 微信 3 银行卡
      phone: "", // 手机号
      name: "", //姓名
      sms_code: "", //验证码
      account_number: "", //银行卡号
      bank: "", //银行名称
      qrcode: "", //二维码
    }
  },
  created() {
    this.get_info()
    this.get_phone()
  },
  methods: {
    get_info() {
      this.$get({
        url: "/user_pay_collection/query",
        success: (res) => {
          if (res.data.bank.info) {
            this.name = res.data.bank.info.name
            this.bank = res.data.bank.info.bank
            this.account_number = res.data.bank.info.account_number
          }
        },
        tip: () => { },
      })
    },
    get_phone() {
      this.$post({
        url: "user_profile",
        success: (res) => {
          console.log("获取手机号", res)
          this.phone = res.data.phone
        },
        tip: () => { },
      })
    },
    save() {
      if (!this.name) {
        this.$refs.btn.disabled = false
        this.$toast(this.$t("请输入持卡人姓名"))
        return
      }
      if (!this.account_number) {
        this.$refs.btn.disabled = false
        this.$toast(this.$t("请输入银行卡卡号"))
        return
      }
      if (!this.bank) {
        this.$refs.btn.disabled = false
        this.$toast(this.$t("请输入银行名称"))
        return
      }
      if (!this.sms_code) {
        this.$refs.btn.disabled = false
        this.$toast(this.$t("请输入您的验证码"))
        return
      }

      this.$post({
        url: "/user_pay_collection/bind",
        data: {
          type: this.type,
          account_number: this.account_number,
          bank: this.bank,
          name: this.name,
          sms_code: this.sms_code,
        },
        success: (res) => {
          this.$toast(res.msg)
          setTimeout(() => {
            this.$refs.btn.disabled = false
            this.goBack()
          }, 1500)
        },
        tip: () => {
          setTimeout(() => {
            this.$refs.btn.disabled = false
          }, 1500)
        },
      })
    },
  },
}
</script>

<style lang="less" scoped="scoped"></style>
