<template>
  <div-box>
    <div-navber title="实名认证"></div-navber>
    <div-height size="7vw"></div-height>

    <div-padding padding="0 4vw">
      <!-- 标题 -->
      <div-height height="12vw"></div-height>
      <!-- //顶部 -->
      <div-card>
        <div-padding padding="3vw 0">
          <van-field clearable v-model="name" :placeholder="$t('请输入您的姓名')">
            <template #label>
              <div-text size="4vw" color="#666">姓名</div-text>
            </template>
          </van-field>
        </div-padding>
        <van-divider />
      </div-card>
      <div-height height="4vw"></div-height>
      <div-card>
        <div-padding padding="3vw 0">
          <van-field clearable v-model="idcard" :placeholder="$t('请输入您的身份证')">
            <template #label>
              <div-text size="4vw" color="#666">身份证</div-text>
            </template>
          </van-field>
        </div-padding>
        <van-divider />
      </div-card>
      <!-- <div-height height="4vw"></div-height>
      <div-card>
        <div-padding padding="3vw 0">
          <van-field clearable v-model="bank" :placeholder="$t('请输入您的银行卡')">
            <template #label>
              <div-text size="4vw" color="#666">银行卡</div-text>
            </template>
          </van-field>
        </div-padding>
        <van-divider />
      </div-card> -->
      <div-height height="50vw"></div-height>
      <div-btn ref="btn" radius="2vw" @fun="save">
        <div-text size="4vw" color="#FFF">保存</div-text>
      </div-btn>

      <div style="height: 4vw"></div>
    </div-padding>
  </div-box>
</template>
<script>
export default {
  data() {
    return {
      idcard: "",
      name: "",
      bank: "",
    }
  },
  created() {},
  methods: {
    save() {
      if (!this.name) {
        this.$refs.btn.disabled = false
        return this.$toast("请输入您的姓名")
      }
      if (!this.idcard) {
        this.$refs.btn.disabled = false
        return this.$toast("请输入您的身份证号")
      }

      // if (!this.bank) {
      //   this.$refs.btn.disabled = false
      //   return this.$toast("请输入您的银行卡")
      // }
      // 修改密码
      this.$post({
        loading: true,
        url: "/user/real",
        data: {
          real_name: this.name,
          id_card: this.idcard,
          // bank_code: this.bank,
        },
        success: (res) => {
          this.$toast(res.msg)
          setTimeout(() => {
            this.goBack()
            this.$refs.btn.disabled = false
          }, 1500)
        },
        tip: (val) => {
          setTimeout(() => {
            this.$refs.btn.disabled = false
          }, 1500)
        },
      })
    },
  },
}
</script>
<style lang='less' scoped>
</style>
