<template>
  <div class="div-html">
    <div class="htmlcontentbox" v-html="html"></div>
  </div>
</template>
<script>
export default {
  name: "div-html",
  props: {
    html: {
      type: String,
      default: "",
    },
  },
}
</script>
<style lang='less' scoped>
// 富文本样式
.htmlcontentbox {
  width: 100%;

  /deep/img {
    max-width: 100% !important;
    max-height: 100% !important;
    object-fit: contain;
  }

  /deep/video {
    max-width: 100% !important;
    max-height: 100% !important;
    object-fit: contain;
  }

  /deep/ * {
    word-break: break-word;
    max-width: 100% !important;
    max-height: 100% !important;
  }
}
</style>
