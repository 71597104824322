<template>
  <div-box tabber>
    <div-navber title="行情"></div-navber>

    <vant-up-down ref="list" @list="(val) => (list = val)" res="res.data.data" url="/product/lists" data="">

      <!-- 标题 -->
      <div class="item">
        <div-padding padding="4vw">
          <van-row type="flex" justify="space-between" align="center">
            <van-col span="4">
              <div-text size="3vw">名称代码</div-text>
            </van-col>
            <van-col span="4">
              <div-text size="3vw" center>成交价</div-text>
            </van-col>
            <van-col span="4">
              <div-text size="3vw" center>开盘价</div-text>
            </van-col>
            <van-col span="4">
              <div-text size="3vw" center>收盘价</div-text>
            </van-col>
            <van-col span="4">
              <div-text size="3vw" right>成交量</div-text>
            </van-col>
          </van-row>
        </div-padding>
        <div-padding padding="0 4vw">
          <van-divider />
        </div-padding>
      </div>
      <div class="list">
        <div class="item" v-for="(item, index) in list" :key="index" @click="router('kling')">
          <div-padding padding="4vw">
            <van-row type="flex" justify="space-between" align="center">
              <van-col span="4">
                <div-text size="3vw" one>{{item.name}}</div-text>
                <div-text size="3vw" one>{{item.code}}</div-text>
              </van-col>
              <van-col span="4">
                <div-text size="3vw" color="#E45F50" center>{{item.success_price}}</div-text>
              </van-col>
              <van-col span="4">
                <div-text size="3vw" center>{{item.open_price}}</div-text>
              </van-col>
              <van-col span="4">
                <div-text size="3vw" center>{{item.close_price}}</div-text>
              </van-col>
              <van-col span="4">
                <div-text size="3vw" right>{{item.success_num}}</div-text>
              </van-col>
            </van-row>
          </div-padding>
          <div-padding padding="0 4vw">
            <van-divider />
          </div-padding>
        </div>
      </div>
    </vant-up-down>
  </div-box>
</template>
<script>
export default {
  data() {
    return {
      list: [],
    }
  },
  created() {
    this.vant_list()
  },
  methods: {},
}
</script>
<style scoped lang="less">
// 明亮
.light {
}
//暗黑
.dark {
}
</style>
