<template>
  <div class="index">
    <div-navber title="订单详情" />
    <!-- <van-nav-bar title="订单详情" :border="false" @click-left="back" left-arrow /> -->
    <div class="top">


      <div class="state" v-if="info.status == 0">
        <div class="left">
          <p class="title_one">待付款</p>
          <p class="title_two">订单等待付款</p>
        </div>
        <img src="~@/assets/img/good_order01.png" class="state_img" />
      </div>
      <div class="state" v-if="info.status == 1">
        <div class="left">
          <p class="title_one">待发货</p>
          <p class="title_two">订单待发货，请耐心等待</p>
        </div>
        <img src="~@/assets/img/good_order02.png" class="state_img" />
      </div>

      <div class="state" v-if="info.status == 2">
        <div class="left">
          <p class="title_one">待收货</p>
          <p class="title_two">订单已发货，请耐心等待</p>
        </div>
        <img src="~@/assets/img/good_order03.png" class="state_img" />
      </div>

      <div class="state" v-if="info.status == 3">
        <div class="left">
          <p class="title_one">已完成</p>
          <p class="title_two">订单已完成</p>
        </div>
        <img src="~@/assets/img/good_order04.png" class="state_img" />
      </div>

    </div>

    <div class="contactway">
      <img src="~@/assets/img/good_order11.png" class="address_img" />
      <div class="right">
        <p class="name" v-if="info.address">{{ info.address.people }} {{ info.address.phone }}</p>
        <p class="address" v-if="info.address">{{ info.address.desc }}</p>
      </div>
    </div>

    <div class="contactway" v-if="info.logistics_name">
      <img src="~@/assets/img/good_order12.png" class="address_img" />
      <div class="right">
        <p class="name">{{ info.logistics_name }}</p>
        <p class="address">{{ info.logistics_number }}</p>
      </div>
    </div>
    <div-height size="3vw" bg="#F9F9F9" />
    <div class="center">
      <img :src="httpPath + info.goods_cover" class="shangpin" />
      <div class="right">
        <p class="name two">{{ info.goods_name }}</p>
        <div class="down">
          <p class="price"><span>￥</span>{{ info.price }}</p>
          <p class="num">x{{ info.num }}</p>
        </div>
      </div>
    </div>


    <div-height size="3vw" bg="#F9F9F9" />
    <div class="bottom">
      <p class="title">订单详情</p>
      <div class="ordernumber">
        <p>订单号：{{ info.order_sn }}</p>
        <p class="copy" v-clipboard:copy="info.order_sn" v-clipboard:success="onCopy" v-clipboard:error="onError">
          复制
        </p>
      </div>
      <p v-if="info.created_at">下单时间：{{ info.created_at }}</p>
      <p v-if="info.pay_time">支付时间：{{ info.pay_time }}</p>
      <p v-if="info.pay_type == 1">付款方式：余额支付</p>
      <p v-if="info.pay_type == 2">付款方式：知产兑换</p>
      <p>商品总额：￥{{ info.total_price }}</p>
      <p v-if="info.send_at">
        发货时间：{{ info.send_at }}
      </p>
      <p v-if="info.finish_at">
        完成时间：{{ info.finish_at }}
      </p>
      <div v-if="info.refund_images && info.refund_images.length > 0">
        <div-padding padding="0">
          <div-left>
            <div-width size="25vw" v-for="(item, index) in info.refund_images" :key="index">
              <div class="item" @click="look(item)">
                <div-center>
                  <div-img width="22vw" height="22vw">
                    <img :src="item" />
                  </div-img>
                </div-center>
              </div>
            </div-width>
          </div-left>
        </div-padding>
      </div>
    </div>
    <van-goods-action>
      <van-goods-action-button color="#999999" v-if="info.status == 0" text="取消订单" :loading="loadinga"
        @click="cancel" />
      <van-goods-action-button color="linear-gradient(263deg, #EA3501 0%, #FE7729 100%)" v-if="info.status == 0"
        text="立即付款" :loading="loading"
        @click="router('order_pay', { order_sn: id, price: info.total_price, time: info.plan_cancel_at })" />
      <van-goods-action-button color=" #EA3501" v-if="info.status == 2" text="确认收货" :loading="loading"
        @click="getconfirm" />
    </van-goods-action>
    <div class="one" ref="word"></div>

  </div>
</template>

<script>
import { ImagePreview } from "vant";
export default {
  props: {},
  data() {
    return {
      id: this.$route.query.id,
      info: {},
      loadinga: false,
      loading: false,
    };
  },
  created() {
    this.getdata();
  },
  methods: {
    //支付回调
    payCallback(status) {
      if (status == "SUCCESS") {
        this.$toast({
          message: "支付成功",
          duration: 1000,
        })
        this.$router.go(-1);
      } else if (status == "FAIL") {
        this.$toast({
          message: "支付失败",
          duration: 1000,
        })
        this.$router.go(-1);
      } else if (status == "CANCEL") {
        this.$toast({
          message: "已取消支付！",
          duration: 1000,
        })
        this.$router.go(-1);
      }
    },
    look(name) {

      ImagePreview({
        images: [name],
        closeable: true,
      });

    },
    getdata() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      this.$post({
        url: "/goods_order/detail",
        data: {
          order_sn: this.id,
        },
        success: (res) => {
          this.$toast.clear();
          this.info = res.data;
        },
        tip: () => { },
      });
    },
    onCopy: function () {
      this.$toast({
        message: "复制成功",
        duration: 400,
      });
    },
    onError: function () {
      this.$toast({
        message: "复制失败",
        duration: 400,
      });
    },
    // 取消
    cancel() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定取消订单吗?",
        })
        .then(() => {
          this.loadinga = true;
          this.$post({
            url: "/goods_order/cancel",
            data: {
              order_sn: this.id,
            },
            success: (res) => {
              this.$toast.success("取消成功");
              setTimeout(() => {
                this.loadinga = false;
                this.$router.go(-1);
              }, 1500);
            },
            tip: () => {
              setTimeout(() => {
                this.loadinga = false;
              }, 1500);
            },
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 收货
    getconfirm() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定收货吗?",
        })
        .then(() => {
          this.loading = true;
          this.$post({
            url: "/goods_order/finish",
            data: {
              order_sn: this.id,
            },
            success: (res) => {
              this.$toast.success("收货成功");
              setTimeout(() => {
                this.loading = false;
                this.getdata();
              }, 1500);
            },
            tip: () => {
              setTimeout(() => {
                this.loading = false;
              }, 1500);
            },
          });
        })
        .catch(() => {
          // on cancel
        });
    },

    lookwuliu() {
      let url = `https://m.kuaidi100.com/result.jsp?nu=${this.info.express_no}`;
      this.$router.push({ name: "wuliu", query: { url } });
    },
  },
};
</script>

<style scoped lang='less'>
.index {
  width: 100%;
  min-height: 100vh;
  padding: 0 0 90px;
  background: #f9f9f9;

  .van-nav-bar {
    background: linear-gradient(263deg, #ea3501 0%, #fe7729 100%);

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      color: #000 !important;
    }

    .van-nav-bar__right {
      color: #000;
    }
  }

  .top {
    width: 100%;
    // background: linear-gradient(263deg, #ea3501 0%, #fe7729 100%);

    .state {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 30px 15px 20px;

      .title_one {
        font-size: 15px;
        font-weight: 550;
        color: #000;
      }

      .title_two {
        font-size: 14px;
        color: #000;
        margin-top: 10px;
      }

      .state_img {
        width: 75px;
        height: 75px;
      }
    }
  }

  .contactway {
    width: 92vw;
    margin: 0 auto;
    border-radius: 2vw;
    margin-bottom: 3vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
    background: #fff;

    .address_img {
      width: 25px;
      height: 25px;
      margin-right: 15px;
    }

    .name {
      font-size: 15px;
      font-weight: 550;
      color: #000000;
      line-height: 2;
    }

    .address {
      font-size: 15px;
      color: #000;
      font-weight: 550;
    }
  }

  .xinxi {
    width: 92vw;
    margin: 0 auto;
    border-radius: 2vw;
    margin-bottom: 3vw;
  }

  .center {
    width: 92vw;
    margin: 0 auto;
    border-radius: 2vw;
    margin-bottom: 3vw;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border-bottom: 1px solid #f5f5f5;

    .shangpin {
      width: 85px;
      height: 85px;
      border-radius: 2vw;
    }

    .right {
      width: 71%;
      min-height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .name {
        width: 100%;
        font-size: 15px;
        font-weight: 550;
        line-height: 1.6;
        color: #000000;
      }

      .down {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .price {
          color: #fe7729;
          font-size: 15px;
          font-weight: 550;

          span {
            font-size: 14px;
          }
        }

        .num {
          font-size: 14px;
          color: #999999;
        }
      }
    }
  }

  .allprice {

    width: 100%;
    font-size: 3.5vw;
    color: #333;
    padding: 0 4vw;
    text-align: right;
    background: #fff;
    font-weight: 600;
  }

  .bottom {
    width: 92vw;
    margin: 0 auto;
    border-radius: 2vw;
    margin-bottom: 3vw;
    padding: 10px 15px;
    background: #fff;

    .title {
      width: 100%;
      font-size: 15px;
      font-weight: 550;
      color: #000;
      line-height: 2;
    }

    >p {
      width: 100%;
      line-height: 2;
      font-size: 13px;
      font-weight: 550;
      color: #000;
    }

    .ordernumber {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        width: 90%;
        line-height: 2;
        font-size: 13px;
        font-weight: 550;
        color: #000;
      }

      .copy {
        width: 10%;
        font-size: 3.4vw;
        color: #fe7729;
        text-align: right;
      }
    }
  }

  .btn {
    width: 100%;
    background: #fff;
    border-top: 5px solid #f9f9f9;
    padding: 2vw 3vw;
    text-align: right;

    /deep/.van-button {
      height: 5vh;
      margin-left: 1vw;
    }
  }
}
</style>
