<template>
  <div class="box">
    <div-navber title="收银台"></div-navber>
    <div class="content">
      <div style="height:10vw"></div>
      <div>
        <!-- 积分商品价格 -->
        <h3>
          <span> <i> 实付金额</i></span>
        </h3>
        <h4><em>¥</em> <i> {{ price }}</i></h4>
        <h5>
          支付剩余时间
          <div class="time">
            <van-count-down :time="time * 1000 | get_time" />
          </div>
        </h5>
        <!-- <h5>{{ info.order_name }}</h5>
        <h5>{{ info.goods_name }}</h5> -->
        <div style="height: 3vw"></div>
        <!-- <div-text>选择支付方式</div-text> -->
        <div-height size="4vw"></div-height>
        <div-padding padding="0 4vw" class="opt_box">
          <div-text color="#333333" size="4vw" style="font-weight: 600">选择支付方式</div-text>
          <div style="height: 3vw"></div>
          <van-radio-group v-model="type" checked-color="#EF834C">
            <van-cell title="余额" :icon="require('@/assets/img/zf03.png')" clickable @click="type = '1'" :border="false">
              <template #right-icon>
                <van-radio name="1" />
              </template>
            </van-cell>
            <van-cell title="股权兑换" :icon="require('@/assets/img/duihuan.png')" clickable @click="type = '2'"
              :border="false">
              <template #right-icon>
                <van-radio name="2" />
              </template>
            </van-cell>
          </van-radio-group>
        </div-padding>
        <div-height size="4vw"></div-height>
        <div-card v-if="type == '2'">
          <div-padding padding="3vw">
            <div style="height: 3vw"></div>
            <van-field :value="value" @click="show = true" right-icon="arrow" readonly>
              <template #label>
                <div-text color="#333333" size="4vw" style="font-weight: 600">选择股权：</div-text>
              </template>
            </van-field>
          </div-padding>
          <van-divider />
        </div-card>
        <div-height size="4vw"></div-height>
        <div-card>
          <div-padding padding="3vw ">
            <div style="height: 3vw"></div>
            <van-field clearable autocomplete v-model="password" type="password" placeholder="请输入交易密码">
              <template #label>
                <div-text color="#333333" size="4vw" style="font-weight: 600">交易密码：</div-text>
              </template>
            </van-field>
          </div-padding>
          <van-divider />
        </div-card>

        <van-popup v-model="show" round position="bottom">
          <van-picker title="选择股权" show-toolbar :columns="columns" value-key="product_name" @confirm="onConfirm"
            @cancel="onCancel" />
        </van-popup>

        <div-height height="15vw"></div-height>
        <div-padding padding="3vw">
          <div-btn ref='btn' @fun='point_pay'>支付</div-btn>
        </div-padding>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: "1",
      radio: "1",
      disabled: false,
      checked: false,
      password: "", //密码
      order_sn: "",//订单号
      price: "",//价格
      time: "",//时间
      pay_down: "",//支付剩余时间
      show: false,
      value: "请选择要兑换的股权", //选择资产
      columns: [],
      product_id: "",
    };
  },
  created() {
    this.setUrlData()
    // this.get_order();
    this.get_info();
  },
  filters: {
    get_time(times) {
      var date = new Date(); //当前时间
      var time = date.getTime();
      var dates = new Date(times); //进行时间
      var timea = dates.getTime();
      let sj = timea - time;
      return sj;
    },
  },
  methods: {
    onConfirm(value, index) {
      console.log(value, index);
      this.value = value.product_name;
      this.product_id = value.product_id;
      this.show = false
    },
    onCancel() {
      this.show = false
    },
    point_pay() {
      if (this.type == "2" && this.product_id == "") {
        this.$toast("请选择股权");
        this.$refs.btn.disabled = false
        return;
      }
      if (this.password == "") {
        this.$toast("请输入交易密码");
        this.$refs.btn.disabled = false
        return;
      }
      this.$toast.loading({
        message: "提交中...",
        forbidClick: true,
      });

      this.$post({
        url: "/goods_order/pay",
        data: {
          order_sn: this.order_sn,
          pay_type: this.type,
          password: this.password,
          product_id: this.product_id,
        },
        success: (res) => {
          setTimeout(() => {
            this.$toast(res.msg);
            // this.qt_back();
            this.router("orderlist");
            // this.$refs.btn.disabled = false
          }, 1000);

          console.log(res);
        },
        tip: (val) => {
          setTimeout(() => {
            this.$refs.btn.disabled = false
          }, 1000);
        },
      });
    },




    get_info() {
      this.$get({
        url: "/goods_order/position_lists",
        success: (res) => {
          this.columns = res.data;
        },
      });
    },

  },
};
</script>

<style lang="less" scoped>
.box {
  background: #fff;
  min-height: 100vh;
  width: 100vw;
}

// 内容
.content {
  width: 100%;
  padding: 0 4vw;

  .img {
    width: 40.6vw;
    height: 40.6vw;
    margin: 12vw auto;
  }

  h3 {
    font-size: 10vw;
    line-height: 12vw;
    color: #333;
    text-align: center;

    em {
      color: #4db0b5;
      font-size: 2.8vw;
    }

    i {
      font-style: initial;
      color: #333;
      font-size: 3.5vw;
    }
  }

  h4 {
    font-size: 10vw;
    line-height: 12vw;
    color: #333;
    text-align: center;

    em {
      color: #333;
      font-size: 7vw;
    }

    i {
      font-style: initial;
      color: #333;
      font-size: 7vw;
    }
  }

  h5 {
    font-size: 3.5vw;
    line-height: 12vw;
    color: #999;
    display: flex;
    color: #999;
    justify-content: center;
    text-align: center;
    align-items: center;

    .time {
      margin-left: 3vw;

      .van-count-down {
        color: #999 !important;
      }
    }
  }

  /deep/.sm {
    width: 72vw !important;
  }
}

.van-cell-group {
  background: transparent;
}

//CELL单元格样式
.opt_box {
  .van-cell {
    align-items: center;
    // width: 92.53vw;
    height: 13.33vw;
    margin-bottom: 3.53vw;
    background: #ffffff;

    border-radius: 2.13vw;

    .van-cell__left-icon {
      // width: 10vw;
      // height: 10vw;
      // border-radius: 50%;
      // line-height: 10vw;
      // background: linear-gradient(280deg, #90db72, #16b068);
      text-align: center;
      color: #fff;
      margin-right: 3vw;
    }

    .van-cell__title {
      font-size: 3.47vw;
      color: #303133;
      line-height: 10vw;
    }

    .van-icon {
      display: flex;
      align-items: center;
    }

    .img {
      width: 5vw;
      height: 5vw;
      margin-right: 3vw;
    }

    .van-cell__value {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    /deep/.van-radio__icon--checked {
      .van-icon {
        background-color: #4db0b5;
        border-color: #4db0b5;
      }
    }
  }

  /deep/.van-field__control {
    text-align: right !important;
  }
}




.cell {
  background: transparent;

  .van-cell {
    height: 10vw;
    background: transparent;
    box-shadow: none;
    padding: 0 4.3vw;

    /deep/.van-cell__title {
      flex: none !important;
      margin-right: 12vw;
    }

    //  /deep/.van-cell__value{
    //    flex:none !important;
    //  }
    .num {
      span {
        color: #4db0b5;
      }
    }
  }

}
</style>
