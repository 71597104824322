<template>
  <div-box>
    <div-navber title="帮助详情"></div-navber>
    <!-- 内容 -->
    <div-padding padding="3vw">
      <div-text size="7vw" color="#000000" bold>{{title}}</div-text>
      <div-height size="2vw" />
      <div-text size="3.5vw" color="#666">{{create_time}}</div-text>
      <div-height size="2vw" />
      <div-html :html="html"></div-html>
    </div-padding>
  </div-box>
</template>

<script>
export default {
  data() {
    return {
      id: "",
      title: "",
      create_time: "",
      html: "123123123",
    }
  },
  created() {
    // 滚动到顶部
    this.setUrlData()
    this.get_info()
  },
  methods: {
    get_info() {
      this.$get({
        url: "/problem/detail",
        data: {
          id: this.id,
        },
        success: (res) => {
          this.title = res.data.title
          this.create_time = res.data.created_at
          this.html = res.data.content
        },
        tip: () => {},
      })
    },
  },
}
</script>

<style scoped lang="scss">
</style>
