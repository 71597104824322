<template>
  <div-box>
    <div-navber title="新手指南" />
    <div-padding padding="3vw">
      <div-html :html="list"></div-html>
    </div-padding>
  </div-box>
</template>
<script>
export default {
  data() {
    return {
      list: "",
    }
  },
  created() {
    this.get_info()
  },
  methods: {
    get_info() {
      this.$get({
        url: "/getGuide",
        success: (res) => {
          this.list = res.data
        },
        tip: () => {},
      })
    },
  },
}
</script>
<style scoped lang="less">
</style>
