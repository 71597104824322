<template>
  <div class="div-center" :style="'width:' + width + ';'" :class="{ center, br, fill }">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'div-center',
  props: {
    center: {
      type: Boolean,
      default: false,
    },
    br: {
      type: Boolean,
      default: false,
    },
    fill: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '',
    },
  },
}
</script>
<style lang='less' scoped>
.div-center {
  display: flex;
  justify-content: center;
}
.div-center.center {
  height: 100%;
  align-items: center;
}
.br {
  flex-wrap: wrap;
}
.fill {
  width: 100%;
}
</style>
