<template>
  <div-box>
    <div-navber title="绑定支付宝"></div-navber>
    <div-height size="3vw"></div-height>
    <div-padding padding="4vw">

      <div-card>
        <div-padding padding="4vw 0">
          <van-field input-align="right" v-model="name" type="text" :placeholder="$t('请输入您的姓名')" clearable border>
            <template #label>
              <div-text size="4vw" color="#19211D">姓名</div-text>
            </template>
          </van-field>
        </div-padding>
        <van-divider />
      </div-card>

      <div-card>
        <div-padding padding="4vw 0">
          <van-field input-align="right" v-model="account_number" type="text" :placeholder="$t('请输入支付宝账号')" clearable
            border>
            <template #label>
              <div-text size="4vw" color="#19211D">支付宝账号</div-text>
            </template>
          </van-field>
        </div-padding>
        <van-divider />
      </div-card>

      <div-card>
        <div-padding padding="4vw 0">
          <van-field input-align="right" v-model="bank" type="text" :placeholder="$t('请确认支付宝账号')" clearable border>
            <template #label>
              <div-text size="4vw" color="#19211D">重复账号</div-text>
            </template>
          </van-field>
        </div-padding>
        <van-divider />
      </div-card>

      <div-card>
        <div-padding padding="4vw 0">
          <van-cell :border="false" center class="up_img_box">
            <template #title>
              <div-text>支付宝收款码</div-text>
            </template>
            <template #label>
              <div-center>
                <uploader :after-read="afterRead">
                  <van-image :src="$img(qrcode)" fit="contain" v-if="qrcode"></van-image>
                  <div class="up_img" v-else>
                    <div class="add">+</div>
                  </div>
                </uploader>
              </div-center>
            </template>
          </van-cell>
        </div-padding>
        <van-divider />
      </div-card>

      <div-card>
        <div-padding padding="4vw 0">
          <van-field clearable v-model="sms_code" type="text" :placeholder="$t('请输入您的验证码')">
            <template #label>
              <div-text size="4vw" color="#19211D">验证码</div-text>
            </template>
            <template #button>
              <send-code plain api="/sendsms" :send-tell="{ phone: phone }" :send-scene="{ scene: 'card' }"
                :no-token="true" color="#EF834C" round>
              </send-code>
            </template>
          </van-field>
        </div-padding>
        <van-divider />
      </div-card>

      <div-height height="20vw"></div-height>
      <div-btn ref="btn" radius="2vw" @fun="save">
        <div-text size="4vw" color="#FFF">确定</div-text>
      </div-btn>
      <div-height height="11vw"></div-height>

    </div-padding>
  </div-box>
</template>

<script>
// import oss from 'ali-oss'
// import ImageCompressor from 'image-compressor.js'

export default {
  data() {
    return {
      type: 1, //类型 1 支付宝 2 支付宝 3 银行卡
      phone: "", // 手机号
      name: "", //姓名
      sms_code: "", //验证码
      account_number: "", //银行卡号
      bank: "", //银行名称
      qrcode: "", //二维码
    }
  },
  created() {
    this.get_info()
    this.get_phone()
  },
  methods: {
    get_info() {
      this.$get({
        url: "/user_pay_collection/query",
        success: (res) => {
          if (res.data.bank.info) {
            this.name = res.data.ali.info.name
            this.bank = res.data.ali.info.bank
            this.account_number = res.data.ali.info.account_number
            this.qrcode = res.data.ali.info.qrcode
          }
        },
        tip: () => { },
      })
    },
    get_phone() {
      this.$post({
        url: "user_profile",
        success: (res) => {
          console.log("获取手机号", res)
          this.phone = res.data.phone
        },
        tip: () => { },
      })
    },

    afterRead(file) {
      // 上传图片
      let formData = new FormData()
      formData.append("file", file.file)
      this.$post({
        url: "/upload",
        data: formData,
        upload: true,
        success: (res) => {
          this.qrcode = res.data
        },
      })
    },

    save() {
      if (!this.name) {
        this.$refs.btn.disabled = false
        this.$toast(this.$t("请输入您的姓名"))
        return
      }
      if (!this.account_number) {
        this.$refs.btn.disabled = false
        this.$toast(this.$t("请输入支付宝账号"))
        return
      }
      if (!this.bank) {
        this.$refs.btn.disabled = false
        this.$toast(this.$t("请确认支付宝账号"))
        return
      }
      if (this.account_number != this.bank) {
        this.$refs.btn.disabled = false
        this.$toast(this.$t("两次账号不一致"))
        return
      }
      if (!this.qrcode) {
        this.$refs.btn.disabled = false
        this.$toast(this.$t("请上传支付宝收款码"))
        return
      }
      if (!this.sms_code) {
        this.$refs.btn.disabled = false
        this.$toast(this.$t("请输入您的验证码"))
        return
      }

      this.$post({
        url: "/user_pay_collection/bind",
        data: {
          type: this.type,
          account_number: this.account_number,
          bank: this.bank,
          name: this.name,
          sms_code: this.sms_code,
          qrcode: this.qrcode,
        },
        success: (res) => {
          this.$toast(res.msg)
          setTimeout(() => {
            this.$refs.btn.disabled = false
            this.goBack()
          }, 1500)
        },
        tip: () => {
          setTimeout(() => {
            this.$refs.btn.disabled = false
          }, 1500)
        },
      })
    },
  },
}
</script>

<style lang="less" scoped="scoped">
.up_img_box {
  background: #fff;
  opacity: 1;
  border-radius: 3vw;
  font-size: 4vw;
  color: #000000;
  padding: 4vw;
  margin-bottom: 4vw;

  .van-cell__label {
    margin-top: 2vw;
  }

  .img {
    display: flex;
    justify-content: center;
    opacity: 1;
    border-radius: 3vw;
    overflow: hidden;
  }

  .up_img {
    width: 30vw;
    height: 30vw;
    position: relative;
    overflow: hidden;

    .add {
      line-height: 30vw;
      text-align: center;
      color: #cdcdcd;
      font-size: 12vw;
    }

    &::before {
      content: "";
      width: 5vw;
      left: -5vw;
      height: 0.5vw;
      display: block;
      position: absolute;
      border-radius: 1vw;
      box-shadow: 5vw 0vw #cdcdcd, 12vw 0vw #cdcdcd, 19vw 0vw #cdcdcd,
        26vw 0vw #cdcdcd, 33vw 0vw #cdcdcd, 40vw 0vw #cdcdcd, 5vw 36.8vw #cdcdcd,
        12vw 36.8vw #cdcdcd, 19vw 36.8vw #cdcdcd, 26vw 36.8vw #cdcdcd,
        33vw 36.8vw #cdcdcd, 40vw 36.8vw #cdcdcd;
      transform: scale(0.8);
    }

    &::after {
      content: "";
      width: 0.5vw;
      left: -5vw;
      top: -5vw;
      height: 5vw;
      display: block;
      position: absolute;
      border-radius: 1vw;
      box-shadow: 6.2vw 5vw #cdcdcd, 6.2vw 12vw #cdcdcd, 6.2vw 19vw #cdcdcd,
        6.2vw 26vw #cdcdcd, 6.2vw 33vw #cdcdcd, 6.2vw 40vw #cdcdcd,
        43vw 5vw #cdcdcd, 43vw 12vw #cdcdcd, 43vw 19vw #cdcdcd,
        43vw 26vw #cdcdcd, 43vw 33vw #cdcdcd, 43vw 40vw #cdcdcd;
      transform: scale(0.8);
    }
  }

  .van-image {
    background: #fff;
    min-height: 40vw;
    min-width: 40vw;
    display: block;

    /deep/.van-icon {
      font-size: 14vw;
    }
  }
}
</style>
