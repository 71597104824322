<template>
  <div-box>
    <div-navber title="详情"></div-navber>
    <div-padding padding="3vw 3vw 4vw">
      <div-img width="94vw" height="69vw" radius="3vw">
        <img :src="$img(info.business_img)" alt="">
      </div-img>
      <div-padding padding="4vw 0 3vw">
        <div-text size="4vw" color="#000000" bold>{{ info.business_name }}</div-text>
      </div-padding>
      <div-text size="3.6vw" color="#000000" bold>{{ info.business_code }}</div-text>
      <div-height height="2vw"></div-height>
      <div-text size="3vw" color="#636363">{{ info.business_describe }}</div-text>
    </div-padding>
    <div-height height="2vw" bg="#F8F8F8"></div-height>
    <div-padding padding="3vw ">
      <div-text size="4vw" color="#000000" bold>知产交易</div-text>
      <div-height height="3vw"></div-height>
      <div-padding padding="0 0 3vw" v-for="val in list" :key="val.product_id">
        <div-both class="item">
          <div-text size="3vw" color="#4991F2" bold>{{ val.product_name }}</div-text>
          <div-text size="3vw" color="#EF834C" bold>{{ val.total_price }}</div-text>
        </div-both>
      </div-padding>
    </div-padding>
  </div-box>
</template>

<script>
export default {
  data() {
    return {
      info: {},
      list: [],
    }
  },
  created() {
    this.setUrlData()
    this.get_info()
  },
  methods: {
    // 更多广告
    get_info() {
      this.$get({
        url: "/business/detail",
        data: {
          id: this.$route.query.id,
        },
        success: (res) => {
          this.info = res.data
          this.list = res.data.data
        },
        tip: () => { },
      })
    },
  },
}
</script>

<style scoped lang="less">
// 明亮
.light {}

//暗黑
.dark {}
</style>
