<template>
  <div-box>
    <div-navber title="公告详情"></div-navber>
    <!-- 内容 -->
    <div-padding padding="3vw">
      <div-text size="7vw" color="#000000" bold>{{ info.title }}</div-text>
      <div-height height="3vw" />
      <div-text size="3.5vw" color="#666">{{ info.created_at }}</div-text>
      <div-height height="3vw" />
      <div class="list" v-for="(item, index) in info.quotation" :key="index">
        <div class="left">
          <div-text size="3vw" color="#4991F2">{{ item.name }}</div-text>
        </div>
        <div class="right">
          <div-text size="3vw" color="#EF834C">{{ item.price }}</div-text>
          <div-text size="3vw" color="#EF834C">{{ item.ratio }}</div-text>
        </div>
      </div>
      <div-height height="3vw" />
      <div-html :html="info.content"></div-html>
    </div-padding>
  </div-box>
</template>

<script>
export default {
  data() {
    return {
      info: {},
    }
  },
  created() {
    // 滚动到顶部
    this.setUrlData()
    this.get_info()
  },
  methods: {
    // 更多广告
    get_info() {
      this.$get({
        url: "/article/detail",
        data: {
          id: this.id,
        },
        success: (res) => {
          this.info = res.data
        },
        tip: () => { },
      })
    },
  },
}
</script>

<style scoped lang="scss"></style>
