<template>
  <div-box tabber bg="#F8F8F8">
    <div-padding padding="3vw">
      <div-text size="4.5vw" color="#000000" center bold>首页</div-text>
    </div-padding>
    <!-- 轮播图 -->
    <div class="banner">
      <van-swipe :autoplay="3000" indicator-color="#fff">
        <van-swipe-item @click="item.link ? href(item.link) : router('image_text', item.id)"
          v-for="(item, index) in banner" :key="index">
          <div class="img">
            <img :src="$img(item.img)" />
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="notice_box">
      <div class="icon" @click="router('new_list')">
        <img src="~@/assets/img/notice.png" alt="" />
      </div>
      <div-width width="20vw">
        <div-text size="4vw" bold color="#000000">
          知产快讯 |
        </div-text>
      </div-width>

      <van-swipe wrapable :autoplay="3000" vertical :show-indicators="false">
        <van-swipe-item v-for="(item, index) in notice_list" :key="index">
          <div-text size="4vw" color="#8A8A8A" one @click.native="router('new', item.id)">
            {{ item.title }}
          </div-text>
        </van-swipe-item>
      </van-swipe>
      <div-right>
        <div-width width="8vw">
          <div-text size="3.5vw" color="#999999" @click.native="router('new_list')">更多</div-text>
        </div-width>
      </div-right>
    </div>
    <!-- 标题 -->
    <!-- <div-padding padding="4vw">
      <div-both center>
        <div-text size="5vw" color="#000000" bold>知产资讯</div-text>
        <div-right center @click.native="router('zixun_list')">
          <van-icon name="wap-nav" :color="$c('#c1c0c5')" />
          <div-width width="2vw"></div-width>
          <div-text size="4vw" color="#666666">更多</div-text>
        </div-right>
      </div-both>
    </div-padding> -->

    <!-- 咨询列表 -->
    <!-- <div class="new_box">
      <div-padding padding="0 4vw">
        <div class="item" @click="router('zixun', item.id)" v-for="(item, index) in new_list" :key="index">
          <div-card>
            <div-left>
              <div-img width="29vw" height="20vw" radius="2vw">
                <img :src="$img(item.cover)" />
              </div-img>
              <div-padding padding="0 3vw">
                <div-both column>
                  <div-text size="4vw" color="#000000" two bold>
                    {{ item.title }}
                  </div-text>
                  <div-left>
                    <div-text size="3vw" color="#999999">官方发布</div-text>
                    <div-text size="3vw" color="#999999">{{ item.date }}</div-text>
                  </div-left>

                </div-both>
              </div-padding>
            </div-left>
          </div-card>
          <div v-if="index * 1 + 1 != new_list.length">
            <div-height height="4vw" />
            <van-divider />
            <div-height height="4vw" />
          </div>
        </div>
      </div-padding>
      <div-height height="4vw" />
    </div> -->

    <div-height height="4vw"></div-height>

    <div-padding padding="0 4vw">

      <van-tabs @click="opt" :ellipsis="false" v-model="active" title-active-color="#FE7729" color="#fff">
        <van-tab>
          <template #title>
            <div-text :color="active == 0 ? '#333' : '#999'" :size="active == 0 ? '5vw' : '4vw'">自选</div-text>
          </template>
        </van-tab>
        <van-tab>
          <template #title>
            <div-text :color="active == 1 ? '#333' : '#999'" :size="active == 1 ? '5vw' : '4vw'">精选</div-text>
          </template>
        </van-tab>
      </van-tabs>

      <div class="list">
        <!-- 标题 -->
        <div class="item">
          <div-padding padding="4vw" bg="#fff">
            <van-row type="flex" justify="space-between" align="center">
              <van-col span="4">
                <div-text size="3vw">名称代码</div-text>
              </van-col>
              <van-col span="4">
                <div-text size="3vw" center>成交价</div-text>
              </van-col>
              <van-col span="4">
                <div-text size="3vw" center>开盘价</div-text>
              </van-col>
              <van-col span="4">
                <div-text size="3vw" center>收盘价</div-text>
              </van-col>
              <van-col span="4">
                <div-text size="3vw" right>成交量</div-text>
              </van-col>
            </van-row>
          </div-padding>
          <div-padding padding="0 4vw" bg="#fff">
            <van-divider />
          </div-padding>
        </div>
        <div v-if="active == 0">
          <div class="item" v-for="(item, index) in featured_lists" :key="index"
            @click="router('kling', item.product_id)" v-show="index < 4">
            <div-padding padding="4vw">
              <van-row type="flex" justify="space-between" align="center">
                <van-col span="4">
                  <div-text size="3vw" one>{{ item.product.name }}</div-text>
                  <div-text size="3vw" one>{{ item.product.code }}</div-text>
                </van-col>
                <van-col span="4">
                  <div-text size="3vw" color="#E45F50" center>{{ item.success_price }}</div-text>
                </van-col>
                <van-col span="4">
                  <div-text size="3vw" center>{{ item.product.open_price }}</div-text>
                </van-col>
                <van-col span="4">
                  <div-text size="3vw" center>{{ item.close_price }}</div-text>
                </van-col>
                <van-col span="4">
                  <div-text size="3vw" right>{{ item.success_num }}</div-text>
                </van-col>
              </van-row>
            </div-padding>
            <div-padding padding="0 4vw" bg="#fff">
              <van-divider />
            </div-padding>
          </div>
        </div>
        <div v-if="active == 1">
          <div class="item" v-for="(item, index) in featured_lists" :key="index" @click="router('kling', item.id)"
            v-show="index < 4">
            <div-padding padding="4vw" bg="#fff">
              <van-row type="flex" justify="space-between" align="center">
                <van-col span="4">
                  <div-text size="3vw" one>{{ item.name }}</div-text>
                  <div-text size="3vw" one>{{ item.code }}</div-text>
                </van-col>
                <van-col span="4">
                  <div-text size="3vw" color="#E45F50" center>{{ item.success_price }}</div-text>
                </van-col>
                <van-col span="4">
                  <div-text size="3vw" center>{{ item.open_price }}</div-text>
                </van-col>
                <van-col span="4">
                  <div-text size="3vw" center>{{ item.close_price }}</div-text>
                </van-col>
                <van-col span="4">
                  <div-text size="3vw" right>{{ item.success_num }}</div-text>
                </van-col>
              </van-row>
            </div-padding>
            <div-padding padding="0 4vw">
              <van-divider />
            </div-padding>
          </div>
        </div>
      </div>
    </div-padding>
    <!-- 标题 -->
    <div-padding padding="4vw">
      <div-both center>
        <div-text size="4.5vw" color="#000000" bold>申购</div-text>
        <div-right center @click.native="router('apply_list')">
          <van-icon name="wap-nav" :color="$c('#c1c0c5')" />
          <div-width width="2vw"></div-width>
          <div-text size="4vw" color="#666666">更多</div-text>
        </div-right>
      </div-both>
    </div-padding>
    <!-- 申购列表 -->

    <div-padding padding="0 4vw">
      <div class="item" v-for="item in apply_lists_three" :key="item.id">
        <div-card :bg="$c('#ffffff')" radius="2vw" shadow="0px 1vw 2vw 0px rgba(0,0,0,0.16)">
          <div-padding padding="4vw">
            <div-both center>
              <div-text size="4vw" color="#000000" bold>
                {{ item.code }}{{ item.name }}
              </div-text>
              <div-width width="15vw">
                <div-btn :ref="'btn_' + item.id" @fun="add('btn_' + item.id, item)" radius="12vw" height="7vw"
                  bg="#D05843" color="#fff">
                  <div-text size="3vw" color="#FFF">申购</div-text>
                </div-btn>
              </div-width>
            </div-both>
          </div-padding>
          <div-padding padding="0 4vw 4vw 4vw">
            <div-both center>
              <div>
                <div-both center>
                  <div-width width="40vw">
                    <div-left>
                      <div-text size="3.5vw" color="#999999">最小申购份额</div-text>
                      <div-width width="3vw"></div-width>
                      <div-text size="3.5vw" color="#D05843">{{ item.apply_min_num }}</div-text>
                    </div-left>
                  </div-width>
                  <div-left>
                    <div-text size="3.5vw" color="#999999">招股价</div-text>
                    <div-width width="3vw"></div-width>
                    <div-text size="3.5vw" color="#D05843">{{ item.apply_price }}</div-text>
                  </div-left>
                </div-both>
                <div-height height="3vw"></div-height>

                <div-img v-if="item.status == 2" height="8vw" width="80vw"
                  :src="require('@/assets/img/jianbianjuxing@2x.png')">
                  <div-left center>
                    <div-width width="2vw"></div-width>
                    <div-img width="3vw" height="3vw">
                      <img src="~@/assets/img/sucai1@2x.png" alt="">
                    </div-img>
                    <div-width width="1vw"></div-width>
                    <div-text size="3.5vw" color="#999999">申购截止日期</div-text>
                    <div-width width="3vw"></div-width>
                    <div-text size="3.5vw" color="#000000">{{ item.apply_end_at.split(" ")[0] }}</div-text>

                    <div-text size="3.5vw" color="#000000">剩余</div-text>
                    <div-text size="3.5vw" color="#EF834C">{{ item.surplus_hour }}</div-text>
                    <div-text size="3.5vw" color="#000000">小时</div-text>
                  </div-left>
                </div-img>

                <div-left v-if="item.status == 1">
                  <div-text size="4vw" color="#999999">未开始</div-text>
                </div-left>
                <div-left v-if="item.status == 3">
                  <div-text size="4vw" color="#999999">已结束</div-text>
                </div-left>
              </div>
            </div-both>
          </div-padding>
        </div-card>
        <div-height height="4vw" />
      </div>
    </div-padding>

    <van-dialog v-model="show" :beforeClose="beforeClose" show-cancel-button :confirmButtonColor="$c('#000000')"
      :cancelButtonColor="$c('#000000')">
      <div-card>
        <div-padding padding="6vw 6vw 0 6vw">
          <div-text size="5vw" bold>申购</div-text>
          <div-height height="4vw" />

          <!-- <div-card :bg="$c('#F8F8F8')" radius="1vw">
            <div-padding padding="3vw 4vw">
              <div-both>
                <div-left center>
                  <div-img width="4vw" height="4vw">
                    <img src="~@/assets/img/icon01.png" v-if="theme == 'light'" />
                    <img src="~@/assets/img/icon01-.png" v-else />
                  </div-img>
                  <div-width width="3vw"></div-width>
                  <div-text>价格</div-text>
                </div-left>

                <van-stepper style="display: flex" disabled v-model="price" theme="round" button-size="22" disable-input />
              </div-both>
            </div-padding>
          </div-card> -->

          <div-height height="4vw" />

          <div-card :bg="$c('#F8F8F8')" radius="1vw">
            <div-padding padding="3vw 4vw">
              <div-both>
                <div-left center>
                  <div-img width="4vw" height="4vw">
                    <img src="~@/assets/img/icon02.png" v-if="theme == 'light'" />
                    <img src="~@/assets/img/icon02-.png" v-else />
                  </div-img>
                  <div-width width="3vw"></div-width>
                  <div-text>数量</div-text>
                </div-left>
                <van-stepper style="display: flex" :min="min" v-model="num" theme="round" button-size="22"
                  disable-input />
              </div-both>
            </div-padding>
          </div-card>

          <div-height height="4vw" />

          <div-card :bg="$c('#F8F8F8')" radius="1vw">
            <div-padding padding="3vw 4vw">
              <van-field clearable :border="false" v-model="password" type="password" :placeholder="$t('请输入交易密码')">
                <template #label>
                  <div-img width="4vw" height="4vw">
                    <img src="~@/assets/img/icon03.png" v-if="theme == 'light'" />
                    <img src="~@/assets/img/icon03-.png" v-else />
                  </div-img>
                </template>
              </van-field>
            </div-padding>
          </div-card>
          <div-height height="4vw" />
          <van-divider />
        </div-padding>
      </div-card>
    </van-dialog>

    <van-dialog class="new_pop" v-model="show_rules" :showConfirmButton="false" v-if="img.switch == 1">
      <div-height height="8vw"></div-height>
      <div-center>
        <!-- <div-img @click.native="href(img.link)">
          <img :src="$img(img.img)">
        </div-img> -->
        <img :src="$img(img.img)" @click="href(img.link)">
      </div-center>
      <div-height height="4vw"></div-height>
      <div-center>
        <van-icon name="close" size="8vw" color="#FFF" @click="close_show_rules()" />
      </div-center>
    </van-dialog>
  </div-box>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      show_rules: true,
      price: "", //价格
      num: "", //数量
      min: "",
      id: "",
      img: "",
      password: "",
      show: false,
      active: 0,
      banner: [],
      notice_list: [],
      new_list: [],
      featured_lists: [], //自选/精选列表
      apply_lists_three: [], //申购三条
    }
  },
  created() {
    const arr = [
      {
        name: "img", //广告img
        url: "/getAdvSetting",
        res: "res.data",
      },
      {
        name: "banner", //轮播图
        url: "/banner/lists",
        res: "res.data",
      },
      {
        name: "new_list", //知产三条
        url: "/article/new_three",
        data: { classify_id: 2 },
        res: "res.data",
      },
      {
        name: "notice_list", //公告
        url: "/article/lists",
        data: { classify_id: 1 },
        res: "res.data.data",
      },
      {
        name: "featured_lists", //自选
        url: "/user_collection/lists",
        res: "res.data.data",
      },
      {
        name: "apply_lists_three", //申购三个 单独拿出来把
        url: "/product/apply_lists_three",
        res: "res.data",
      },
    ]
    arr.map((item, index) => {
      this.get_every(item)
    })
    this.show_rules = !this.session.get("show_rules")
  },
  methods: {
    close_show_rules() {
      this.session.set("show_rules", "1")
      this.show_rules = false
    },
    get_every(item) {
      this.$get({
        url: item.url,
        data: item.data,
        success: (res) => {
          this[item.name] = eval(item.res)
        },
        tip: () => { },
      })
    },
    opt(name) {
      this.featured_lists = []
      let obj = [
        {
          name: "featured_lists", //自选
          url: "/user_collection/lists",
          res: "res.data.data",
        },
        {
          name: "featured_lists", //精选
          url: "/product/featured_lists",
          res: "res.data",
        },
      ]

      this.get_every(obj[name * 1])
      console.log(this.featured_lists)
    },
    add(btn, item) {
      this.show = true
      console.log("222", btn, item)
      this.min = item.apply_min_num
      this.id = item.id
      this.price = item.apply_price
      this.$refs[btn][0].disabled = false
    },
    beforeClose(action, done) {
      if (action === "confirm") {
        this.$post({
          url: "/apply_order/submit",
          data: {
            product_id: this.id,
            num: this.num,
            password: this.password,
          },
          success: (res) => {
            this.$toast(res.msg)
            setTimeout(done, 1000)
          },
          tip: () => {
            setTimeout(done, 1000)
          },
        })
      } else {
        done()
      }
    },
  },
}
</script>
<style scoped lang="less">
.new_pop {
  background: transparent !important;
}

// 明亮
.light {
  /deep/[class*="van-hairline"]::after {
    border: none !important;
  }
}

//暗黑
.dark {
  /deep/.van-dialog {
    background: #666;
  }

  /deep/.van-button--default {
    background: #666;
  }

  /deep/[class*="van-hairline"]::after {
    border: none !important;
  }

  /deep/.van-stepper__input {
    color: #fff;
  }
}

//轮播图
.banner {
  position: relative;
  height: 45vw;

  .van-swipe {
    width: 100%;

    .van-swipe-item {
      .img {
        margin: 0 auto;
        width: 100vw;
        height: 45vw;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
        }
      }
    }
  }
}

//公告
.notice_box {
  margin: 0 auto;
  width: 100vw;
  height: 14vw;
  padding: 0 4vw;
  border-radius: 2vw;
  display: flex;
  align-items: center;
  background: #fff;

  .icon {
    flex-shrink: 0;
    width: 5vw;
    height: 5vw;
    margin-right: 2vw;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }

  .van-swipe {
    height: 12vw !important;
    width: 100%;

    /deep/.van-swipe-item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;

      .div-text {
        line-height: 5.5vw;
        width: 100%;
      }
    }
  }
}

/deep/.van-tabs {
  background: #fff;
  background-image: url("~@/assets/img/top@2x.png") !important;
  background-size: 100% 100% !important;


}

//自选精选
/deep/.van-tabs__wrap {
  .van-tab {
    flex: none !important;
  }

  // width: 40% !important;
  .van-tabs__line {
    height: 0vw !important;
    width: 5vw !important;
  }
}

//推荐
.tuijian {
  position: absolute;
  top: -2vw;
  left: 35vw;
  padding: 0.5vw 1vw;
  border-radius: 3vw 3vw 3vw 0;
  background: linear-gradient(93deg, #ffa55b 0%, #ff7637 100%);
  background-size: 100% 100%;
}

.radius_box {
  padding: 0.5vw 2vw;
  min-width: 12vw;
  background: linear-gradient(93deg, #ffa55b 0%, #ff7637 100%);
  border-radius: 26vw;
}
</style>
