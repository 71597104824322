<template>
  <div class="div-avatar">
    <div class="img" :style="`background:${color};width: ${size};height: ${size}; `">
      <img v-if="src" :src="src" :style="` border: ${border} ${color} solid;} `" />
      <img v-else src="~@/assets/img/header.png" :style="` border: ${border} ${color} solid;} `" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'div-avatar',
  props: {
    icon: Boolean,
    src: String,
    color: String,
    size: String,
    border: {
      type: [String, Boolean],
      default: '0.5vw',
    },
  },
  data() {
    return {}
  },
  created() { },
  methods: {},
}
</script>
<style lang="less" scoped="scoped">
.div-avatar {
  position: relative;

  .img {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    width: 10vw;
    height: 10vw;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}
</style>