<template>
  <div-box bg="#F9FAFB">
    <nav-bar :title="$t('我的订单')" left-arrow :bg-color="$c('#fff')" :leftColor="$c('#333')" :titleColor="$c('#333')" />
    <div-height height="12vw">
      <van-tabs v-model="active" @click="opt" title-active-color="#000" color="#EF834C">
        <van-tab>
          <template #title>
            <div-text class="#333">投资中</div-text>
          </template>
        </van-tab>
        <van-tab>
          <template #title>
            <div-text class="#333">已结束</div-text>
          </template>
        </van-tab>
      </van-tabs>
    </div-height>
    <div-padding padding="0 4vw">
      <vant-up-down ref="list" @list="(val) => (list = val)" res="res.data.data" url="/product/financial/my"
        :data="{ status: Number(active) + 1 }">
        <div-card :bg="$c('#ffffff')" radius="2vw" shadow="0px 1vw 2vw 0px rgba(0,0,0,0.16)" v-for="item in list"
          :key="item.id">
          <div-padding padding=" 4vw 3vw">
            <div-both center>
              <div-left center>
                <div-text size="3vw" color="#A4A4A4">投资时间</div-text>
                <div-text size="3vw" color="#A4A4A4">：{{ item.created_at }}</div-text>
              </div-left>
              <div-right center>
                <div-text size="3vw" color="#EF834C" v-if="item.status == 1">投资中</div-text>
                <div-text size="3vw" color="#EF834C" v-if="item.status == 2">已结束</div-text>
                <div-text size="3vw" color="#EF834C" v-if="item.status == 3">已结束</div-text>
              </div-right>
            </div-both>
          </div-padding>
          <van-divider />
          <div-padding padding=" 4vw 3vw">
            <div-both center>
              <!-- <div-left center>
                <img src="../../../assets/img/aac.png" alt="" class="imgs">
              </div-left> -->
              <div-right class="rightbox">
                <div-text size="4vw" color="#000000" bold>{{ item.financial_name }}</div-text>
                <div-both br>
                  <div-padding padding="0 1vw">
                    <div-text size="4vw" color="#000000" bold>{{ item.rate }}%</div-text>
                    <div-text size="3vw" color="#969696">收益率</div-text>
                  </div-padding>
                  <span></span>
                  <div-padding padding="0 1vw">
                    <div-text size="4vw" color="#000000" bold>{{ item.price }}</div-text>
                    <div-text size="3vw" color="#969696">投资金额</div-text>
                  </div-padding>
                  <span></span>
                  <div-padding padding="0 1vw">
                    <div-text size="4vw" color="#EF834C" bold>{{ item.cycle }}天</div-text>
                    <div-text size="3vw" color="#969696">周期</div-text>
                  </div-padding>
                  <span></span>
                  <div-padding padding="0 1vw">
                    <div-text size="4vw" color="#EF834C" bold>{{ item.income }}</div-text>
                    <div-text size="3vw" color="#969696">合计</div-text>
                  </div-padding>
                </div-both>
              </div-right>
            </div-both>
          </div-padding>
          <van-divider />
          <div-padding padding=" 4vw 3vw">
            <div-both center>
              <div-left center>
                <div-text size="3vw" color="#A4A4A4">结束时间</div-text>
                <div-text size="3vw" color="#A4A4A4">：{{ item.end_time }}</div-text>
              </div-left>
              <div-right center v-if="item.status == 1">
                <div-btn :ref="`btn_${item.id}`" @fun="cancel(`btn_${item.id}`, item.id)" height="8vw" bg="#EF834C"
                  color="#fff">
                  <div-text size="3vw" color="#FFF">提前赎回</div-text>
                </div-btn>
              </div-right>
            </div-both>
          </div-padding>
        </div-card>
      </vant-up-down>
    </div-padding>
  </div-box>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      list: [],
    }
  },
  created() {
    // 滚动到顶部
    this.vant_list()
  },
  methods: {
    opt() {
      this.list = []
      this.vant_list()
    },
    cancel(btn, id) {
      this.$dialog
        .confirm({
          message: this.$t("确认要提前取消套餐?"),
        })
        .then(() => {
          this.$post({
            loading: true,
            url: "/product/financial/cancel",
            data: {
              order_id: id,
            },
            success: (res) => {
              this.$toast(res.msg)
              this.vant_list()
              setTimeout(() => {
                this.$refs[btn][0].disabled = false
              }, 1500)
            },
            tip: (val) => {
              this.$toast(val.msg)
              this.vant_list()
              setTimeout(() => {
                this.$refs[btn][0].disabled = false
              }, 1500)
            },
          })
        })
        .catch(() => {
          setTimeout(() => {
            this.$refs[btn][0].disabled = false
          }, 1500)
        })
    },
  },
}
</script>

<style lang="less" scoped="scoped">
.reder {
  width: 1vw;
  height: 4vw;
  background: #EF834C;
  position: absolute;
  top: 4vw;
  left: 0;
}

/deep/.btn_box {
  width: 20vw;
}

.imgs {
  width: 17vw;
  height: 17vw;
}

.rightbox {
  width: 100%;
  min-height: 20vw;
  flex-direction: column;
  justify-content: space-between !important;
  align-items: flex-start;

  .div-text {
    width: 100%;
  }

  >.div-both {
    width: 100%;
    align-items: center;

    span {
      width: 1px;
      height: 5vw;
      background: #EDEDED;
    }
  }
}
</style>
