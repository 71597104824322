<template>
  <div-box>
    <!-- <van-nav-bar title="选择地址" right-text="添加地址" left-arrow fixed :border="false" @click-left="qt_back" @click-right="qt_go('add_addres')" /> -->
    <div-navber title="选择地址"></div-navber>
    <!-- 封装上拉下拉组件 坐标 vant-extend 文件 -->
    <vant-up-down ref="child" @list="(val) => (list = val)" res="res.data.data" url="/app/useraddress/index" data="">
      <div class="list">
        <div class="oneaddress" v-for="item in list" :key="item.id">
          <div class="info van-hairline--bottom">
            <div class="line">
              <div class="number">
                {{ item.is_default == 2 ? "默认" : "" }}
              </div>
              <div class="name">{{ item.username }}</div>
              <span>{{ item.mobile }}</span>
            </div>
            <div class="detail">
              {{ item.area }}
              {{ item.address }}
            </div>
          </div>
          <div class="foot">
            <div class="left"></div>
            <div class="right">
              <div class="opt" @click="opt(item)">选择这个地址</div>
            </div>
          </div>
        </div>
      </div>
    </vant-up-down>
    <div-foot>
      <div-padding padding="3vw">
        <div-btn ref="btn" @click.native="go('add_addres')">新增地址</div-btn>
      </div-padding>
    </div-foot>
  </div-box>
</template>

<script type="text/javascript">
export default {
  name: "app",
  data() {
    return {
      list: null,
    };
  },
  created() {
    // 滚动到顶部
  },
  mounted() { },
  methods: {
    opt(item) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定要选择这个地址吗？",
          confirmButtonColor: "#333",
        })
        .then(() => {
          sessionStorage.setItem("address_item", JSON.stringify(item));
          this.back();
          // this.$get({
          //   url: "/app/useraddress/setdefault",
          //   data: {
          //     id: id,
          //   },
          //   success: (res) => {
          //     this.$toast({ message: "选择成功" });
          //     setTimeout(() => {
          //       this.back();
          //     }, 250);
          //   },
          //   tip: (val) => {
          //     setTimeout(() => {}, 1000);
          //   },
          // });
        })
        .catch(() => {
          this.$refs.child.list_init();
        });
    },
  },
};
</script>

<style scoped lang="less">
//地址列表样式
.list {
  width: 100%;

  .oneaddress {
    width: 92.53vw;
    box-shadow: 0vw 1.33vw 2.67vw 0vw rgba(0, 0, 0, 0.1);
    border-radius: 2.67vw;
    margin: 3.73vw auto;

    .foot {
      border-radius: 0 0 2vw 2vw;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 2vw 4vw;
    }

    .opt {
      font-size: 2.67vw;
      color: #fff;
      border-radius: 2vw;
      padding: 1vw 4vw;
      background: linear-gradient(to right, #ffd01e, #ff8917);
    }

    .edit {
      font-size: 2.67vw;
      color: #fff;
      border-radius: 2vw 0 0 2vw;

      padding: 1vw 4vw;
      background: linear-gradient(to right, #ffd01e, #ff8917);
    }

    .del {
      font-size: 2.67vw;
      color: #fff;
      border-radius: 0 2vw 2vw 0;
      padding: 1vw 4vw;
      background: linear-gradient(to right, #ff6034, #ee0a24);
    }

    .info {
      flex: 1;
      padding: 0.5vw 4.28vw;

      .line {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 2.13vw 0;

        .name {
          font-size: 4vw;
          font-weight: bold;
          color: #333;
          padding-right: 3vw;
        }

        .number {
          position: absolute;
          border-radius: 0 0 2vw 2vw;
          padding: 0 2vw;
          background: #ff5e00;
          right: 2vw;
          top: 0vw;
          font-size: 3.2vw;
          font-weight: 400;
          color: #fff;
        }

        span {
          font-size: 3.5vw;
          font-weight: 400;
          color: #666;
        }
      }

      .detail {
        font-size: 3.5vw;
        font-weight: 400;
        color: #333;
        // padding-bottom: 4.35vw;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        margin-bottom: 2vw;
      }
    }
  }
}
</style>
