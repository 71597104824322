<template>
  <div-box>
    <div-navber v-if="business == 1" title="我是机构" />
    <div-navber v-if="business != 1" title="绑定机构" />
    <div-height height="7vw"></div-height>

    <div-padding padding="0 6vw">
      <!-- 标题 -->
      <div-text size="6vw" color="#131529" bold v-if="business == 1">我的机构码</div-text>
      <div-text size="6vw" color="#131529" bold v-if="business != 1 && is_bind == 1">已绑定机构码</div-text>
      <div-text size="6vw" color="#131529" bold v-if="business != 1 && is_bind == 0">请输入机构码</div-text>
      <div-height height="12vw"></div-height>
      <!-- //顶部 -->

      <div-card>
        <div-padding padding="3vw 0">
          <van-field clearable v-model="business_code" v-if="business == 1" :readonly="true">
            <template #label>
              <div-text size="4vw" color="#666">机构码</div-text>
            </template>
            <template #button>
              <div-text size="4vw" color="#666" @click.native="copyData(business_code)">一键复制</div-text>
            </template>
          </van-field>
          <van-field clearable v-model="code" :placeholder="$t('填写机构码')" v-if="business != 1 && is_bind == 0">
            <template #label>
              <div-text size="4vw" color="#666">机构码</div-text>
            </template>
          </van-field>
          <van-field clearable v-model="bind_code" v-if="business != 1 && is_bind == 1" :readonly="true">
            <template #label>
              <div-text size="4vw" color="#666">机构码</div-text>
            </template>
            <template #button>
              <div-text size="4vw" color="#666" @click.native="copyData(bind_code)">一键复制</div-text>
            </template>
          </van-field>
        </div-padding>
        <van-divider />
      </div-card>
      <div-height height="40vw"></div-height>

      <div-btn ref="btn" radius="2vw" @fun="goBack()" v-if="business == 1">
        <div-text size="4vw" color="#FFF">确定</div-text>
      </div-btn>
      <div-btn ref="btn" radius="2vw" @fun="del" v-if="business != 1 && is_bind == 1">
        <div-text size=" 4vw" color="#FFF">解绑</div-text>
      </div-btn>
      <div-btn ref="btn" radius="2vw" @fun="save" v-if="business != 1 && is_bind == 0">
        <div-text size="4vw" color="#FFF">立即绑定</div-text>
      </div-btn>

      <div style="height: 4vw"></div>
    </div-padding>
  </div-box>
</template>
<script>
export default {
  data() {
    return {
      business: "",
      business_code: "",
      is_bind: "",
      bind_code: "",
      code: "",
    }
  },
  created() {
    this.get_info()
  },
  methods: {
    get_info() {
      // post 请求
      this.$post({
        url: "user_profile",
        success: (res) => {
          this.business = res.data.business
          //不是机构判断是否绑定
          if (this.business != 1) {
            this.get_business()
          }
          this.business_code = res.data.business_code
          console.log(res)
        },
        tip: () => { },
      })
    },
    get_business() {
      // post 请求
      this.$get({
        url: "business/bind_info",
        success: (res) => {
          this.is_bind = res.data.is_bind
          this.bind_code = res.data.bind_code
          console.log(res)
        },
        tip: () => { },
      })
    },
    del() {
      this.$post({
        url: "/business/unbinding",
        data: {},
        success: (res) => {
          this.$toast(res.msg)
          setTimeout(() => {
            this.goBack()
          }, 1500)
        },
        tip: (val) => {
          this.$toast(val.msg)
          setTimeout(() => {
            this.$refs.btn.disabled = false
          }, 1500)
        },
      })
    },
    save() {
      if (!this.code) {
        this.$refs.btn.disabled = false
        return this.$toast(this.$t("填写机构码"))
      }
      this.$post({
        url: "/business/bind",
        data: {
          business_code: this.code,
        },
        success: (res) => {
          this.$toast(res.msg)
          setTimeout(() => {
            this.goBack()
            this.$refs.btn.disabled = false
          }, 1500)
        },
        tip: (val) => {
          setTimeout(() => {
            this.$refs.btn.disabled = false
          }, 1500)
        },
      })
    },
  },
}
</script>
<style lang='less' scoped></style>
