<template>
  <div-box tabber bg="#F8F8F8">
    <div-img height="40vw" width="100vw" :src="require('@/assets/img/dingbu@2x.png')">
      <div-padding padding="3vw">
        <div-text size="4.5vw" color="#000000" center bold>交易</div-text>
        <div-both center>
          <div>
            <div-text size="6vw" bold color="#EF834C">交易指数</div-text>
            <div-text size="4vw" bold color="#333333">实时更新·实时查看</div-text>
          </div>

          <div-img width="28vw" height="28vw">
            <img src="~@/assets/img/yuansu3@2x.png" alt="">
          </div-img>
        </div-both>
      </div-padding>
    </div-img>
    <vant-up-down ref="list" @list="(val) => (list = val)" res="res.data.data" url="/product/lists" data="">
      <div-padding padding="0 3vw">
        <!-- 标题 -->
        <div class="item">
          <div-padding padding="4vw">
            <van-row type="flex" justify="space-between" align="center">
              <van-col span="4">
                <div-text size="3.5vw" color="#999999">名称代码</div-text>
              </van-col>
              <van-col span="4">
                <div-text size="3.5vw" color="#999999" center>成交价</div-text>
              </van-col>
              <van-col span="4">
                <div-text size="3.5vw" color="#999999" center>开盘价</div-text>
              </van-col>
              <van-col span="4">
                <div-text size="3.5vw" color="#999999" center>收盘价</div-text>
              </van-col>
              <van-col span="4">
                <div-text size="3.5vw" color="#999999" right>成交量</div-text>
              </van-col>
            </van-row>
          </div-padding>
          <div-padding padding="0 4vw">
            <van-divider />
          </div-padding>
        </div>
        <div class="list">
          <div class="item" v-for="(item, index) in list" :key="index" @click="router('kling', item.id)">
            <div-padding padding="4vw">
              <van-row type="flex" justify="space-between" align="center">
                <van-col span="4">
                  <div-text size="3.5vw">{{ item.name }}</div-text>
                  <div-text size="3vw3.5vw" one>{{ item.code }}</div-text>
                </van-col>
                <van-col span="4">
                  <div-text size="3.5vw" color="#E45F50" center>{{ item.success_price }}</div-text>
                </van-col>
                <van-col span="4">
                  <div-text size="3.5vw" center>{{ item.open_price }}</div-text>
                </van-col>
                <van-col span="4">
                  <div-text size="3.5vw" center>{{ item.close_price }}</div-text>
                </van-col>
                <van-col span="4">
                  <div-text size="3.5vw" right>{{ item.success_num }}</div-text>
                </van-col>
              </van-row>
            </div-padding>
            <div-padding padding="0 4vw">
              <van-divider />
            </div-padding>
          </div>
        </div>
      </div-padding>
    </vant-up-down>

  </div-box>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      list: [],
    }
  },
  created() {
    this.vant_list()
  },
  methods: {},
}
</script>
<style scoped lang="less">
// 明亮
.light {}

//暗黑
.dark {}

.list {
  background: #fff;
}

.item {
  background: #fff;
}
</style>
