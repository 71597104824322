<template>
  <div-box class="gradient">
    <nav-bar left-arrow :title="$t('帮助中心')" fixed :bgColor="'#EF834C'" leftColor="#fff" titleColor="#fff" />
    <div-height size="3vw"></div-height>
    <div-padding padding="0 4vw">
      <div-both center>
        <div>
          <div-text color="#FFF">您好！</div-text>
          <div-text color="#FFF">欢迎来到帮助中心！</div-text>
        </div>
        <div-img width="27vw" height="30vw" :src="require('@/assets/img/u4.png')"></div-img>
      </div-both>
    </div-padding>
    <div-card class="card" :bg="$c('#fff')" radius="3vw 3vw 0 0">
      <div-padding padding="5vw">
        <div-text bold>常见问题</div-text>
        <div-height height="3vw" />

        <van-divider />

        <div class="item" v-for="(item, index) in list" :key="index">
          <div-height height="3vw" />
          <div-left center>
            <div-img width="4vw" height="4vw">
              <img :src="$img(item.cover)" />
            </div-img>
            <div-width width="3vw"></div-width>
            <div-text center>{{ item.name }}</div-text>
          </div-left>
          <div-height height="3vw" />
          <van-divider />
          <div v-for="(val, i) in item.problems" :key="i" @click="router('help_item', { id: val.id })">
            <div-height height="3vw"></div-height>
            <div-text one>{{ val.title }}</div-text>
            <div-height height="3vw"></div-height>
            <van-divider />
          </div>
        </div>
      </div-padding>
    </div-card>
  </div-box>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    }
  },
  created() {
    this.get_info()
  },
  methods: {
    get_info() {
      this.$get({
        url: "/problem/lists",
        success: (res) => {
          console.log(res)
          this.list = res.data
        },
        tip: () => { },
      })
    },
  },
}
</script>

<style lang="less" scoped="scoped">
// 明亮
.light {
  .gradient {
    background: linear-gradient(to bottom,
        #EF834C 0%,
        #EF834C 53vw,
        #fff 53vw) !important;
  }
}

//暗黑
.dark {
  .gradient {
    background: linear-gradient(to bottom,
        #EF834C 0%,
        #EF834C 53vw,
        #1c1c1c 53vw) !important;
  }
}
</style>
