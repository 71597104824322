module.exports = {
  "未登录": "未登錄",
  "昨天": "昨天",
  "登录失败": "登入失敗",
  "k": "k線",
  "open": "開盤",
  "close": "關盤",
  "low": "最低",
  "hig": "最高",
  "银行名称": "銀行名稱",
  "高": "高",
  "收益列表": "收益清單",
  "交易金额": "交易金額",
  "收益金额": "收益金額",
  "开": "開",
  "商标数字存证中心": "商標數字存證中心",
  "分时": "分時",
  "日线": "日線",
  "周线": "周線",
  "月线": "月線",
  "年线": "年線",
  "1分": "1分",
  "5分": "5分",
  "15分": "15分",
  "30分": "30分",
  "小时": "小時",

  "注册": "注册",
  "理财第⼀选择": "理財第⼀選擇",
  "密码": "密碼",
  "登录": "登入",
  "忘记密码": "忘記密碼",
  "请输入您的登录密码": "請輸入您的登入密碼",
  "《用户服务协议》": "《用戶服務協議》",
  "和": "和",
  "《隐私政策》": "《隱私政策》",

  "首页": "首頁",
  "知产资讯": "知產資訊",
  "更多": "更多",
  "官方推荐": "官方推薦",
  "自选": "自選",
  "精选": "精選",
  "名称代码": "名稱代碼",
  "成交价": "成交價",
  "开盘价": "開盤價",
  "收盘价": "收盤價",
  "成交量": "成交量",
  "申购": "申購",
  "招股价": "招股價",
  "申购": "申購",
  "最小申购金额": "最小申購金額",
  "最小申购份额": "最小申購份額",
  "申购截止日期": "申購截止日期",
  "剩余": "剩餘",
  "小时": "小時",
  "官方发布": "官方發佈",
  "市场": "市場",
  "行情": "行情",
  "券商信息": "券商資訊",
  "详情": "詳情",
  "请输入交易密码": "請輸入交易密碼",
  "资讯详情": "資訊詳情",
  "申购列表": "申購清單",
  "资讯列表": "資訊清單",
  "公告列表": "公告清單",
  "公告详情": "公告詳情",
  "交易": "交易",

  "群聊": "群聊",
  "创建群聊": "創建群聊",
  "消息通知": "消息通知",
  "搜索群名": "搜索群名",
  "搜索": "搜索",
  "申请加入": "申請加入",
  "通知": "通知",
  "拒绝": "拒絕",
  "同意": "同意",
  "已同意": "已同意",
  "已拒绝": "已拒絕",

  "我的": "我的",
  "已实名": "已實名",
  "钱包余额": "錢包餘額",
  "立即查看": "立即查看",
  "系统功能": "系統功能",
  "我的订单": "我的訂單",
  "我的持股": "我的持股",
  "我的知产": "我的知產",
  "邀请好友": "邀請好友",
  "其他功能": "其他功能",
  "新手指南": "新手指南",
  "帮助中心": "幫助中心",
  "联系客服": "聯系客服",
  "切换语言": "切換語言",
  "选择语言": "選擇語言",
  "系统设置": "系統設置",

  "个人资料": "個人資料",
  "头像": "頭像",
  "昵称": "昵稱",
  "手机号": "手機號",
  "实名信息": "實名資訊",
  "个人简介": "個人簡介",
  "注册时间": "註冊時間",

  "修改昵称": "修改昵稱",
  "请输入您的昵称": "請輸入您的昵稱",
  "保存": "保存",
  "实名认证": "實名認證",
  "身份证": "身份證",
  "姓名": "姓名",
  "请输入您的手机号": "請輸入您的手機號",
  "请输入您的身份证": "請輸入您的身份證",
  "请输入个人简介": "請輸入個人簡介",
  "请输入您的姓名": "請輸入您的姓名",
  "修改成功": "修改成功",

  "我的交易": "我的交易",
  "买入委托": "買入委託",
  "卖出委托": "賣出委託",
  "订单号": "訂單號",
  "已报": "已報",
  "已成": "已成",
  "撤单": "撤單",
  "委托数量": "委託數量",
  "知产名称": "知產名稱",
  "撤单数量": "撤單數量",
  "成交数量": "成交數量",
  "委托价格": "委託價格",
  "我的买入": "我的買入",
  "我的卖出": "我的賣出",
  "单价": "單價",
  "数量": "數量",
  "价格": "價格",
  "确权": "確權",
  "锁仓": "鎖倉",

  "买入知产": "買入知產",
  "申购知产": "申購知產",
  "名称代码": "名稱代碼",
  "均价/数量": "均價/數量",
  "市值/盈亏": "市值/盈虧",
  "申购价格": "申購價格",
  "申购数量": "申購數量",
  "邀请码": "邀請碼",
  "请截图保存分享给好友": "請截圖保存分享給好友",
  "您好！": "您好！",
  "欢迎来到帮助中心！": "歡迎來到幫助中心！",
  "客服": "客服",

  "收款方式": "收款方式",
  "机构码": "機构碼",
  "修改登录密码": "修改登入密碼",
  "修改支付密码": "修改支付密碼",

  "银行卡": "銀行卡",
  "微信": "微信",
  "支付宝": "支付寶",
  "未绑定": "未綁定",

  "开启夜间模式": "開啟夜間模式",
  "关闭夜间模式": "關閉夜間模式",

  "⼿机号": "⼿機號",
  "验证码": "驗證碼",
  "重复密码": "重複密碼",
  "交易密码": "交易密碼",
  "确认密码": "確認密碼",
  "发送验证码": "發送驗證碼",
  "请输入您的验证码": "請輸入您的驗證碼",
  "请输入6～12位数字+字母密码": "請輸入6～12位數字+字母密碼",
  "请输入二级密码(6位纯数字)": "請輸入二級密碼(6位純數字)",
  "请确认6～12位数字+字母密码": "請確認6～12位數字+字母密碼",
  "请确认二级密码(6位纯数字)": "請確認二級密碼(6位純數字)",
  "请仔细阅读并勾选《用户协议》和《隐私政策》": "請仔細閱讀並勾選《用戶協議》和《隱私政策》",

  "⽤户注册": "⽤戶註冊",
  "返回登录": "返回登入",

  "请输入手机号": "請輸入手機號",
  "请输入正确格式的手机号": "請輸入正確格式的手機號",
  "加载中...": "載入中...",
  "发送成功": "發送成功",
  "验证中...": "驗證中...",
  "请输入登录密码": "請輸入登入密碼",

  "请输入验证码": "請輸入驗證碼",
  "请确认登录密码": "請確認登入密碼",
  "确定": "確定",
  "已绑定": "已綁定",

  "银行卡号": "銀行卡號",
  "开户行": "開戶行",
  "绑定银行卡": "綁定銀行卡",
  "请输入持卡人姓名": "請輸入持卡人姓名",
  "请输入银行卡卡号": "請輸入銀行卡卡號",
  "请输入银行名称": "請輸入銀行名稱",

  "绑定微信": "綁定微信",
  "请输入微信账号": "請輸入微信帳號",
  "请确认微信账号": "請確認微信帳號",
  "微信账号": "微信帳號",
  "重复账号": "重複帳號",
  "微信收款码": "微信收款碼",
  "请上传微信收款码": "請上傳微信收款碼",
  "两次账号不一致": "兩次帳號不一致",

  "绑定支付宝": "綁定支付寶",
  "支付宝账号": "支付寶帳號",
  "请输入支付宝账号": "請輸入支付寶帳號",
  "请确认支付宝账号": "請確認支付寶帳號",
  "支付宝收款码": "支付寶收款碼",

  "请上传支付宝收款码": "請上傳支付寶收款碼",

  "未认证": "未認證",


  "请选择您要联系的客服": "請選擇您要聯繫的客服",
  "我们根据选择为您提供服务": "我們根據選擇為您提供服務",
  "电话客服": "電話客服",
  "一键拨打": "一鍵撥打",
  "微信客服": "微信客服",
  "一键复制": "一鍵複製",

  "常见问题": "常見問題",

  "用户": "用戶",
  "这个家伙很懒,什么都没有写": "這個傢伙很懶，什麼都沒有寫",
  "已绑定机构码": "已綁定機构碼",
  "绑定机构": "綁定機构",
  "我是机构": "我是機构",
  "我的机构码": "我的機构碼",
  "请输入机构码": "請輸入機构碼",
  "填写机构码": "填寫機构碼",
  "解绑": "解綁",
  "退出登录": "登出",
  "立即绑定": "立即綁定",
  "钱包": "錢包",
  "充值": "充值",
  "提现": "提現",
  "可用余额(元)": "可用餘額(元)",
  "余额变动明细": "餘額變動明細",
  "全部": "全部",

  "提现记录": "提現記錄",
  "提款金额": "提款金額",
  "请输入您的提现金额": "請輸入您的提現金額",
  "选择提现方式": "選擇提現管道",
  "申请提现": "申請提現",

  "充值记录": "充值記錄",
  "充值金额": "充值金額",
  "请输入您的充值金额": "請輸入您的充值金額",
  "选择充值方式": "選擇充值管道",
  "上传凭证": "上傳憑證",
  "申请充值": "申請充值",

  "报价": "報價",
  "简况": "簡況",
  "卖出": "賣出",
  "买入": "買入",
  "自选": "自選",
  "是否": "是否",
  "取消": "取消",
  "限价模式": "限價模式",
  "价格范围": "價格範圍",
  "之间": "之間",
  "买盘": "買盤",
  "卖盘": "賣盤",
  "最高": "最高",
  "今开": "今開",
  "最低": "最低",
  "昨收": "昨收",
  "换手率": "換手率",
  "委比": "委比",
  "成交额": "成交額",
  "公司简介": "公司簡介",
  "主席": "主席",
  "上市时间": "上市時間",
  "发行价格": "發行價格",
  "发行数量": "發行數量",
  "是否撤单": "是否撤單",

  "创建您的群聊天": "創建您的群聊天",
  "填写群名称（2-10个字）": "填寫群名稱（2-10個字）",
  "群名称": "群名稱",
  "立即创建": "立即創建",
  "请输入群名称": "請輸入群名稱",
  "群头像": "群頭像",
  "请上传群头像": "請上傳群頭像",
  "没有更多了": "沒有更多了",
  "群号": "群號",
  "选择照片": "選擇照片",
  "群聊创建成功": "群聊創建成功，快開始你們的聊天吧",
  "[群系统通知]": "[群系統通知]",

  "移出群聊": "移出群聊",
  "确定要移出群聊": "確定要移出群聊",
  "邀请列表": "邀請清單",
  "备注": "備註",
  "请输入备注": "請輸入備註",
  "暂无备注": "暫無備註",
  "认证通过": "認證通過",
  "未认证": "未認證",

  "机构钱包": "机构荷包",
  "可用机构余额(元)": "可用机构剩低（蚊）",
  "机构余额变动明细": "机构剩低变动明细",
  "用户协议": "用户协议",
  "隐私政策": "私隐政策！",
  "注销账户": "登出账户",
  "注销账户后，您的账户将无法找回，确定要注销吗？": "登出账户之后，你账户将唔搵翻，肯定要登出吗？",
  "未开始": "未开始",
  "已结束": "已经结束",
  "机构码": "机构码",
  "请输入机构码": "请输入机构码",
  "购买套餐": "帮衬套餐",
  "套餐": "套餐",
  "收益率": "收益率",
  "周期": "周期",
  "立即购买": "即刻帮衬",
  "到期收益": "到期收益",
  "单价": "单价",
  "利率": "利率",
  "时间": "时间",
  "天": "日",
  "合计": "合计",
  "我的套餐": "我套餐",
  "投资中": "投资中",
  "投资金额": "投资金额",
  "提前赎回": "提前赎回",
  "结束时间": "搞掂时间",
  "今日交易额": "今日交易额",
  "知产交易": "知产交易",
  "确认要提前取消套餐?": "确认要提前取消套餐？",
}