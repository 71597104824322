<template>
  <div-box tabber bg="#F8F8F8">
    <div-img height="32vw" width="100vw" :src="require('@/assets/img/dingbu@2x.png')">
      <div-padding padding="3vw">
        <div-text size="4.5vw" color="#000000" center bold>我的</div-text>
      </div-padding>
      <div-padding padding="0 5vw 0vw 5vw">
        <div-both center @click.native="router('user')">
          <div-left bottom center>
            <div-avatar size="15vw" :src="$img(info.head_img)" color="#fff"></div-avatar>
            <div-width width="3vw"></div-width>
            <div-text size="5vw" bold v-if="info.verify_status == 2">{{ info.real_name }}</div-text>
            <template v-else>
              <div-text size="4vw" bold>{{ info.nickname ? info.nickname : "" }}</div-text>
              <div-text size="4vw" bold v-if="!info.nickname">{{ info.phone | geTel }}</div-text>
            </template>
          </div-left>
          <div-card bg="#fff" radius="2vw">
            <div-padding padding="0.5vw 1vw">
              <div-right center>
                <div-img width="4.5vw" height="4.5vw">
                  <img src="~@/assets/img/yirenzheng@2x.png" />
                </div-img>
                <div-width width="1vw"></div-width>
                <!-- <div-text size="3.5vw" color="#797979">{{ info.profile ? info.profile : $t('这个家伙很懒,什么都没有写') }}</div-text> -->
                <div-text size="3.5vw" color="#EF834C" v-if="info.verify_status == 0">未认证</div-text>
                <div-text size="3.5vw" color="#EF834C" v-if="info.verify_status == 1">待审核</div-text>
                <div-text size="3.5vw" color="#EF834C" v-if="info.verify_status == 2">认证通过</div-text>
              </div-right>
            </div-padding>
          </div-card>
        </div-both>
      </div-padding>
    </div-img>
    <div-padding padding="0 4vw">
      <div-padding padding="4vw">
        <div-both center>
          <div-left center>
            <div>
              <div-text size="3.5vw" color="#333333">钱包余额</div-text>
              <div-height height="1vw" />
              <div-text size="6vw" color="#EF834C" bold>￥{{
      info.money
    }}</div-text>
            </div>
          </div-left>
          <div>
            <div-right center @click.native="router('wallet', { price: info.money })">
              <div-text size="3.5vw" color="#999">钱包明细</div-text>
              <van-icon name="arrow" color="#999" />
            </div-right>
            <div-height height="3vw" />
            <div-right center>
              <div-card @click.native="router('cash_out', { price: info.money })"
                bg="linear-gradient( 180deg, #F2A666 0%, #EE804A 100%)" radius="26vw"
                shadow="0px 0px 4px 0px rgba(255,85,67,0.4)">
                <div-padding padding="0.5vw 5vw">
                  <div-both center>
                    <div-text size="3.5vw" color="#fff">提现</div-text>
                  </div-both>
                </div-padding>
              </div-card>
              <div-width width="3vw" />
              <div-card @click.native="router('recharge', { price: info.money })"
                bg="linear-gradient( 180deg, #F2A666 0%, #EE804A 100%)" radius="26vw"
                shadow="0px 0px 4px 0px rgba(255,85,67,0.4)">
                <div-padding padding="0.5vw 5vw">
                  <div-both center>
                    <div-text size="3.5vw" color="#fff">充值</div-text>
                  </div-both>
                </div-padding>
              </div-card>
            </div-right>
          </div>
        </div-both>
      </div-padding>
    </div-padding>
    <div-padding padding="0 5vw">
      <div-img :src="require('@/assets/img/yaoqing_bj@2x.png')" width="90vw" height="30vw">
        <div-padding padding="3vw">
          <div-both center>
            <div-left bottom center>
              <div-img width="11vw" height="11vw">
                <img src="~@/assets/img/yuansu2@2x.png" alt="">
              </div-img>
              <div-width width="2vw"></div-width>
              <div>
                <div-text size="4.5vw" bold color="#674616">邀请好友</div-text>
                <div-text size="3.5vw" color="#674616">邀请好友赢豪礼</div-text>
              </div>
            </div-left>
            <div-card @click.native="router('share')" bg="rgba(255,255,255,0.2)" border="1px solid #fff" radius="20vw">
              <div-padding padding="1vw 3vw">
                <div-right center>
                  <div-text size="3.5vw" color="#674616">立即邀请</div-text>
                  <van-icon name="arrow" color="#674616" />
                </div-right>
              </div-padding>
            </div-card>
          </div-both>
        </div-padding>
      </div-img>
    </div-padding>
    <div class="mohu">
      <div-padding padding="6vw 9vw 0 9vw">
        <div-both>
          <div class="item" @click="router('my_order')">
            <div-center>
              <div-img width="9vw" height="9vw">
                <img src="~@/assets/img/wddd@2x.png" />
              </div-img>
            </div-center>
            <div-height height="2vw" />
            <div-text color="#333" size="3.5vw" center>我的订单</div-text>
          </div>
          <div class="item" @click="router('hold_list')">
            <div-center>
              <div-img width="9vw" height="9vw">
                <img src="~@/assets/img/wdcg@2x.png" />
              </div-img>
            </div-center>
            <div-height height="2vw" />
            <div-text color="#333" size="3.5vw" center>我的持股</div-text>
          </div>

          <div class="item" @click="router('my_zhichan')">
            <div-center>
              <div-img width="9vw" height="9vw">
                <img src="~@/assets/img/wdzc@2x.png" />
              </div-img>
            </div-center>
            <div-height height="2vw" />
            <div-text color="#333" size="3.5vw" center>我的知产</div-text>
          </div>
          <div class="item" @click="router('mypackage')">
            <div-center>
              <div-img width="9vw" height="9vw">
                <img src="~@/assets/img/wdtc@2x.png" />
              </div-img>
            </div-center>
            <div-height height="2vw" />
            <div-text color="#333" size="3.5vw" center>我的套餐</div-text>
          </div>
        </div-both>
      </div-padding>
    </div>
    <div-padding padding="0 4vw" style="transform: translateY(-12vw);">
      <div-left center>
        <div-text size="4vw" color="#333" bold>其他功能</div-text>
      </div-left>
      <div-height height="5vw"></div-height>
      <div-card @click.native="router('orderlist')" :bg="$c('#ffffff')" radius="2vw">
        <div-padding padding="4vw">
          <div-both center>
            <div-left center>
              <div-img width="6vw" height="6vw">
                <img src="~@/assets/img/scdd@2x.png" alt="">
              </div-img>
              <div-width width="2vw"></div-width>
              <div-text color="#333" size="4vw">商城订单</div-text>
            </div-left>
            <van-icon name="arrow" color="#333" />
          </div-both>
        </div-padding>
      </div-card>
      <div-height height="3vw"></div-height>
      <div-card @click.native="router('address')" :bg="$c('#ffffff')" radius="2vw">
        <div-padding padding="4vw">
          <div-both center>
            <div-left center>
              <div-img width="6vw" height="6vw">
                <img src="~@/assets/img/shdz@2x.png" alt="">
              </div-img>
              <div-width width="2vw"></div-width>
              <div-text color="#333" size="4vw">收货地址</div-text>
            </div-left>
            <van-icon name="arrow" color="#333" />
          </div-both>
        </div-padding>
      </div-card>
      <div-height height="3vw"></div-height>
      <div-card @click.native="router('novice')" :bg="$c('#ffffff')" radius="2vw">
        <div-padding padding="4vw">
          <div-both center>
            <div-left center>
              <div-img width="6vw" height="6vw">
                <img src="~@/assets/img/xszn@2x.png" alt="">
              </div-img>
              <div-width width="2vw"></div-width>
              <div-text color="#333" size="4vw">新手指南</div-text>
            </div-left>
            <van-icon name="arrow" color="#333" />
          </div-both>
        </div-padding>
      </div-card>
      <div-height height="3vw"></div-height>
      <div-card @click.native="router('help')" :bg="$c('#ffffff')" radius="2vw">
        <div-padding padding="4vw">
          <div-both center>
            <div-left center>
              <div-img width="6vw" height="6vw">
                <img src="~@/assets/img/bzzx@2x.png" alt="">
              </div-img>
              <div-width width="2vw"></div-width>
              <div-text color="#333" size="4vw">帮助中心</div-text>
            </div-left>
            <van-icon name="arrow" color="#333" />
          </div-both>
        </div-padding>
      </div-card>
      <div-height height="3vw"></div-height>
      <div-card @click.native="router('call')" :bg="$c('#ffffff')" radius="2vw">
        <div-padding padding="4vw">
          <div-both center>
            <div-left center>
              <div-img width="6vw" height="6vw">
                <img src="~@/assets/img/lxkf@2x.png" alt="">
              </div-img>
              <div-width width="2vw"></div-width>
              <div-text color="#333" size="4vw">联系客服</div-text>
            </div-left>
            <van-icon name="arrow" color="#333" />
          </div-both>
        </div-padding>
      </div-card>
      <div-height height="3vw"></div-height>
      <div-card @click.native="router('set')" :bg="$c('#ffffff')" radius="2vw">
        <div-padding padding="4vw">
          <div-both center>
            <div-left center>
              <div-img width="6vw" height="6vw">
                <img src="~@/assets/img/xtsz@2x.png" alt="">
              </div-img>
              <div-width width="2vw"></div-width>
              <div-text color="#333" size="4vw">系统设置</div-text>
            </div-left>
            <van-icon name="arrow" color="#333" />
          </div-both>
        </div-padding>
      </div-card>
      <div-height height="3vw"></div-height>
      <div-card v-if="info.business == 1" @click.native="router('income_list')" :bg="$c('#ffffff')" radius="2vw">
        <div-padding padding="4vw">
          <div-both center>
            <div-left center>
              <div-img width="6vw" height="6vw">
                <img src="~@/assets/img/shouyi.png" alt="">
              </div-img>
              <div-width width="2vw"></div-width>
              <div-text color="#333" size="4vw">机构钱包</div-text>
            </div-left>
            <van-icon name="arrow" color="#333" />
          </div-both>
        </div-padding>
      </div-card>
    </div-padding>
    <div-height height="5vw" />


  </div-box>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      info: {},
    }
  },
  created() {
    this.get_info()
  },
  filters: {
    geTel(tel) {
      if (tel) {
        return tel.substring(0, 3) + "****" + tel.substr(tel.length - 4)
      }
      return ''
    }
  },
  methods: {
    get_info() {
      this.$post({
        url: "/user_profile",
        success: (res) => {
          this.info = res.data
        },
        tip: () => { },
      })
    },
  },
}
</script>
<style scoped lang="less">
// 明亮
.light {}

//暗黑
.dark {}

.menitem {
  width: 25%;
}

.mohu {
  width: 100%;
  height: 28vw;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(12px);
  transform: translateY(-12vw);
  border-top: 1px solid #FFFFFF;
}
</style>
