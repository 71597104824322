<template>
  <div class="div-text" :style="'font-size:' + size + ';color:' + $c(color) + ';'"
    :class="{ center, left, right, one, two, three, bold }">
    {{ $t($slots.default[0].text ? $slots.default[0].text : '--') }}
  </div>
</template>
<script>
export default {
  name: "div-text",
  props: {
    size: {
      type: String,
      default: "4vw",
    },
    center: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "#333",
    },
    line: {
      type: String,
      default: "1.5",
    },
    one: {
      type: Boolean,
      default: false,
    },
    two: {
      type: Boolean,
      default: false,
    },
    three: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  created() { },
}
</script>
<style lang='less' scoped>
.div-text {
  color: #333;
}

.bold {
  font-weight: bold !important;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.one {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.two {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-weight: normal;
  // word-wrap: break-word;
  word-break: break-all;
}

.three {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  font-weight: normal;
  // word-wrap: break-word;
  word-break: break-all;
}
</style>
