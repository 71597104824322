<template>
  <div-box class="gradient">
    <nav-bar left-arrow :title="$t('钱包')" fixed bgColor="#EF834C" leftColor="#fff" titleColor="#fff" />

    <div-padding padding="4vw">
      <div-card :bg="$c('#ffffff')" radius="2vw" shadow="0px 1vw 2vw 0px rgba(0,0,0,0.16)">
        <div-padding padding="5vw 3vw">
          <div-height height="2vw"></div-height>
          <div-text center>可用余额(元)</div-text>
          <div-height height="3vw"></div-height>
          <div-text size="10vw" center>{{ money }}</div-text>
          <div-height height="8vw"></div-height>
          <div-both>
            <div-width width="42vw">
              <div-btn ref="btn" radius="2vw" @fun="router('cash_out')" border="1px solid #E6E6E6" bg="#fff"
                color="#000000">

                <div-text color="#555">提现</div-text>
              </div-btn>
            </div-width>
            <div-width width="42vw">
              <div-btn ref="btn" radius="2vw" @fun="router('recharge')">

                <div-text color="#FFF">充值</div-text>
              </div-btn>
            </div-width>
          </div-both>
        </div-padding>
      </div-card>
    </div-padding>
    <div-height height="4vw"></div-height>
    <div-padding padding="0 4vw">
      <vant-up-down ref="list" @list="(val) => (list = val)" res="res.data.data" url="/user/money_record">
        <div-card :bg="$c('#ffffff')" radius="2vw" shadow="0px 1vw 2vw 0px rgba(0,0,0,0.16)">
          <div-padding padding="4vw ">
            <van-cell is-link @click="router('wallet_list')">
              <template #title>
                <div-text size="4vw" color="#000000" bold>余额变动明细</div-text>
              </template>
              <template>
                <div-text color="#999999">全部</div-text>
              </template>
            </van-cell>
          </div-padding>
          <van-divider />

          <div class="item" v-for="(item, index) in list" :key="index">
            <div-padding padding="3vw 4vw">
              <div-both center>
                <div-left center>
                  <div>
                    <div-left center>
                      <div-text color="#333">{{ item.type_desc }}</div-text>
                      <div-width width="1vw"></div-width>
                    </div-left>
                    <div-height height="2vw"></div-height>
                    <div-text color="#8A8A8A">{{ item.created_at }}</div-text>
                  </div>
                </div-left>
                <div-right center>
                  <div-text color="#EF834C" bold v-if="item.type == 1">+{{ item.num }}</div-text>
                  <div-text color="#39AD44" bold v-if="item.type == 2">-{{ item.num }}</div-text>
                </div-right>
              </div-both>
            </div-padding>
          </div>
        </div-card>
      </vant-up-down>
    </div-padding>
  </div-box>
</template>

<script>
export default {
  data() {
    return {
      money: "",
      list: [], //
      disabled: false, //按钮禁用
      checked: true, //是否默认
    }
  },
  created() {
    this.get_info()
    this.vant_list()
  },
  methods: {
    get_info() {
      this.$post({
        url: "/user_profile",
        success: (res) => {
          this.money = res.data.money
        },
        tip: () => { },
      })
    },
    getdata() {
      this.$toast.loading({
        message: "加载中...",
      })
      this.$get({
        url: "/user/money_record",
        success: (res) => {
          this.$toast.clear()
          this.list = res.data.data
        },
        tip: () => { },
      })
    },
  },
}
</script>

<style lang="less" scoped="scoped">
// 明亮
.light {
  .gradient {
    background: linear-gradient(to bottom,
        #EF834C 0%,
        #EF834C 53vw,
        #fff 53vw) !important;
  }
}

//暗黑
.dark {
  .gradient {
    background: linear-gradient(to bottom,
        #EF834C 0%,
        #EF834C 53vw,
        #1c1c1c 53vw) !important;
  }
}
</style>
