<template>
    <div-box top="0" tabber :src="require('@/assets/img/dingbu.png')">

        <vant-up-down @list="(val) => (list = val)" res="res.data.data" url="/goods/lists" :data="{ is_home: 1 }"
            ref="child" :init="true">

            <div-padding padding="0 3vw ">
                <div-height height="5vw" />
                <!-- 搜索 -->
                <div class="search" @click="router('search')">
                    <div-card bg="#fff" radius="5vw">
                        <div-both center>
                            <div-padding padding="2vw 4vw">
                                <div-left center>
                                    <div-img height="4vw" width="4vw">
                                        <img :src="require('@/assets/img/sousuo_icon.png')" />
                                    </div-img>
                                    <div-width width="4vw" />
                                    <div-text color="#B7B6B9" size="4vw">请输入商品名称</div-text>
                                </div-left>
                            </div-padding>
                            <div-card bg="#EF834C" radius="5vw">
                                <div-padding padding="2vw 4vw">
                                    <div-text color="#fff" size="4vw">搜索</div-text>
                                </div-padding>
                            </div-card>
                        </div-both>
                    </div-card>
                </div>
                <div-height height="2vw" />
            </div-padding>

            <!-- 分类 -->
            <div class="classify">
                <div-both center>
                    <div-left center style="width: 80%; overflow: hidden">
                        <van-tabs v-model="category_id" @click="router('classify', { type: category_id })"
                            background="none" color="#ffffff" title-active-color="#333" title-inactive-color="#666"
                            :ellipsis="false">
                            <van-tab :title="item.name" v-for="(item, index) in tabs" :key="index"
                                :name="index"></van-tab>
                        </van-tabs>
                    </div-left>
                    <div-right center @click.native="router('classify')">
                        <div-padding padding="4vw">
                            <div-img height="4vw" width="4vw">
                                <img :src="require('@/assets/img/fenlei_icon.png')" />
                            </div-img>
                        </div-padding>
                    </div-right>
                </div-both>
            </div>

            <!-- 轮播图 -->
            <div class="banner">
                <van-swipe class="swipebox" :autoplay="3000" indicator-color="#fff">
                    <van-swipe-item v-for="(item, index) in banner" :key="index">
                        <div class="banner_img">
                            <img :src="httpPath + item.img" />
                        </div>
                    </van-swipe-item>
                </van-swipe>
            </div>

            <!-- 九宫格开始 -->
            <div class="xz_grid">
                <van-grid :column-num="5">
                    <van-grid-item @click="router('classify', { type: index })" v-for="(item, index) in classif"
                        :key="index" v-show="index < 9">
                        <div v-if="item.img" class="van-image">
                            <img :src="httpPath + item.img" />
                        </div>
                        <span class="van-grid-item__text">{{ item.name }}</span>
                    </van-grid-item>

                    <van-grid-item @click.native="router('classify')">
                        <div class="van-image">
                            <img :src="require('@/assets/img/xicon10.png')" />
                        </div>
                        <span class="van-grid-item__text">更多分类</span>
                    </van-grid-item>
                </van-grid>
            </div>

            <div-height height="5vw" />
            <!-- 商品列表框 -->
            <div class="list_box">
                <div-padding padding="0 4vw">
                    <div-both br>
                        <div class="item" @click="router('good', { id: item.id })" v-for="(item, index) in list"
                            :key="index">
                            <div-width size="44vw">
                                <div-card bg="#FBFBFB" radius="2vw">
                                    <div-img width="44vw" height="44vw" radius="2vw">
                                        <img :src="httpPath + item.cover" />
                                    </div-img>
                                    <div-padding padding="3vw">
                                        <div-text size="3.5vw" color="#000000" one>{{ item.title }}</div-text>
                                        <div-height size="2vw" />
                                        <div-both>
                                            <div-text size="3.5vw" color="#FA7A26">￥{{ item.price }}</div-text>
                                        </div-both>
                                    </div-padding>
                                </div-card>
                                <div-height size="3vw" />
                            </div-width>
                        </div>
                    </div-both>
                </div-padding>
            </div>
        </vant-up-down>
    </div-box>
</template>

<script>
export default {
    data() {
        return {
            banner: [],
            tabs: [],
            classif: [
                // { id: 1, name: '食品生鲜', icon: require('@/assets/img/xicon01.png') },
                // { id: 2, name: '丽人美容', icon: require('@/assets/img/xicon02.png') },
                // { id: 3, name: '家政服务', icon: require('@/assets/img/xicon03.png') },
                // { id: 4, name: '啤酒饮料', icon: require('@/assets/img/xicon04.png') },
                // { id: 5, name: '鲜花礼品', icon: require('@/assets/img/xicon05.png') },
                // { id: 6, name: '礼物推荐', icon: require('@/assets/img/xicon06.png') },
                // { id: 7, name: '汉堡披萨', icon: require('@/assets/img/xicon07.png') },
                // { id: 8, name: '奶茶小吃', icon: require('@/assets/img/xicon08.png') },
                // { id: 9, name: '宠物天地', icon: require('@/assets/img/xicon09.png') },
                // { id: 10, name: '更多', icon: require('@/assets/img/xicon10.png') }
            ],
            name: "", //关键词
            category_id: "", //分类id
            list: [],
        };
    },
    created() {
        this.get_banner();
        this.get_classify();

        setTimeout(() => {
            this.$refs.child.list_init();
        });
    },
    methods: {
        //获取商品分类
        get_classify() {
            this.$get({
                url: "/goods_cate/oneLists",
                data: {},
                success: (res) => {
                    this.tabs = res.data;
                },
                tip: () => { },
            });
            this.$get({
                url: "/goods_cate/homeLists",
                data: {},
                success: (res) => {
                    this.classif = res.data;
                },
                tip: () => { },
            });
        },
        // 获取banner图
        get_banner() {
            this.$get({
                url: "/banner/lists",
                data: { type: 1 },
                success: (res) => {
                    this.banner = res.data;
                },
                tip: () => { },
            });
        },

        tabchange(id) {
            // sessionStorage.setItem("category_id", id);
            // this.go("classif");
            // // setTimeout(() => {
            // //   this.$refs.child.list_init();
            // // }, 0);
        },
    },
};
</script>

<style scoped lang='less'>
.my-swipe {
    padding: 0 4vw;
    flex: 1;
    height: 8vw;

    .van-swipe-item {
        width: 100% !important;
        color: #fff;
        font-size: 3.47vw;
        line-height: 8vw;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.img_box {
    width: 100%;
    height: 43vw;
    display: flex;
    justify-content: center;

    // border: 1px solid #fff;
    // background: #fff;
    .img {
        overflow: hidden;
        border-radius: 2vw;
        width: 92vw;
        height: 43vw;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.swiper-slide {
    -webkit-transition: transform 1s;
    -moz-transition: transform 1s;
    -ms-transition: transform 1s;
    -o-transition: transform 1s;
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
}

.swiper-slide-active,
.swiper-slide-duplicate-active {
    -webkit-transform: scale(1);
    transform: scale(1);
}



.search {
    width: 100%;

    /deep/.van-search {
        padding: 0;

        .van-field__control:disabled {
            color: #000 !important;
        }
    }
}

/deep/.van-empty {
    padding: 0 !important;
}

//九宫格
.xz_grid {
    width: 100%;
    // height: 40vw;
    background: #ffffff;
    // box-shadow: 0rem 0.53vw 5.33vw -2.93vw rgba(109, 109, 109, 0.5);
    border-radius: 2.13vw;

    // margin: 2.67vw auto;
    /deep/.van-grid-item__content {
        padding: 4vw 2vw 0 2vw;
    }

    .van-image {
        width: 11vw;
        height: 11vw;
        margin-bottom: 3vw;

        img {
            width: 100%;
            height: 100%;
        }
    }

    /deep/[class*="van-hairline"]::after {
        border: 0;
    }

    .van-grid-item__text {
        font-size: 3.2vw;
        font-weight: 400;
        color: #666;
    }
}

//轮播图
.banner {
    position: relative;
    z-index: 3;
    height: 37vw;

    .swipebox {
        width: 100%;

        // height: 44vw;
        .van-swipe-item {
            .banner_img {
                margin: 0 auto;
                width: 92vw;
                height: 37vw;
                border-radius: 3vw;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    display: block;
                }
            }
        }
    }
}
</style>