<template>
  <div-box>
    <div-navber title="联系客服"></div-navber>
    <!-- <div-height size="3vw" /> -->

    <div>
      <div-padding padding="4vw 5vw">
        <div-height height="10vw"></div-height>
        <div-text color="#000000" size="5vw" center bold>请选择您要联系的客服</div-text>
        <div-text color="#636363" size="3vw" center>我们根据选择为您提供服务</div-text>
        <div-height height="10vw"></div-height>
        <div class="list">
          <div class="item">
            <div-card radius="2vw" :bg="$c('#ffffff')" shadow="0px 1vw 2vw 0px rgba(0,0,0,0.16)">
              <div-padding padding="7vw 4vw">
                <div-both center>
                  <div-left center>
                    <div-img width="10vw" height="10vw">
                      <img src="~@/assets/img/s01.png" />
                    </div-img>
                    <div-width width="4vw"></div-width>
                    <div>
                      <div-text color="#333333" size="4vw" bold>电话客服</div-text>
                      <div-height height="1vw" />
                      <div-text color="#999999" size="3vw">{{ list.phone }}</div-text>
                    </div>
                    <!-- <div-text color="#333" size="4vw" >{{
                      list.service_mobile1
                    }}</div-text> -->
                  </div-left>
                  <div-right center>
                    <div @click="callTel(list.phone)">
                      <div-card bg="#EF834C" radius="2vw">
                        <div-padding padding="1vw 2vw">
                          <div-text color="#fff" size="3vw">一键拨打</div-text>
                        </div-padding>
                      </div-card>
                    </div>
                  </div-right>
                </div-both>
              </div-padding>
            </div-card>
            <div-height height="3vw" />
          </div>
          <div class="item">
            <div-card radius="2vw" :bg="$c('#ffffff')" shadow="0px 1vw 2vw 0px rgba(0,0,0,0.16)">
              <div-padding padding="7vw 4vw">
                <div-both center>
                  <div-left center>
                    <div-img width="10vw" height="10vw">
                      <img src="~@/assets/img/s02.png" />
                    </div-img>
                    <div-width width="4vw"></div-width>
                    <div>
                      <div-text color="#333333" size="4vw" bold>微信客服</div-text>
                      <div-height height="1vw" />
                      <div-text color="#999999" size="3vw">{{ list.wx }}</div-text>
                    </div>
                    <!-- <div-text color="#333" size="4vw">{{
                      list.service_mobile2
                    }}</div-text> -->
                  </div-left>
                  <div-right center>
                    <div @click="copyData(list.wx)">
                      <div-card bg="#EF834C" radius="2vw">
                        <div-padding padding="1vw 2vw">
                          <div-text color="#fff" size="3vw">一键复制</div-text>
                        </div-padding>
                      </div-card>
                    </div>
                  </div-right>
                </div-both>
              </div-padding>
            </div-card>
            <div-height height="3vw" />
          </div>
        </div>
        <div-height height="25vw" />

        <div-btn ref="btn" radius="2vw" @fun="save">
          <div-text size="4vw" color="#FFF">确定</div-text>
        </div-btn>
      </div-padding>
      <!-- 按钮 -->

    </div>
  </div-box>
</template>

<script>
import { ImagePreview } from "vant"
export default {
  data() {
    return {
      list: {},
    }
  },
  created() {
    // 滚动到顶部
    this.get_info()
  },
  mounted() {
    //回滚到顶部
  },
  methods: {
    save() {
      this.goBack()
    },
    // 获取网页配置
    get_info() {
      this.$get({
        url: "/getKefu",
        success: (res) => {
          console.log("电话列表", res)
          this.list = res.data
        },
      })
    },
  },
}
</script>

<style lang="less" scoped="scoped">
// 大盒子
.content {
  width: 100%;

  // QQ
  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #666;
    font-size: 3.8vw;
    margin: 4vw 4vw;
    padding: 2vw 3vw;
    background: #fff;
    border-radius: 2vw;
    line-height: 8.33vw;

    .left {
      display: flex;
      flex-direction: row;
      align-items: center;

      .img {
        width: 5vw;
        height: 5vw;
        margin-right: 3vw;

        .van-icon {
          font-size: 5vw;
          display: block;
          line-height: 1;
        }
      }

      .imgfff {
        background: #fff;
        border-radius: 50%;
        overflow: hidden;
        width: 5vw;
        height: 5vw;
        margin-right: 3vw;
      }
    }
  }
}
</style>
