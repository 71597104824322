<template>
  <div-box>
    <div-navber title="切换语言"></div-navber>
    <div-height size="3vw"></div-height>
    <van-radio-group v-model="radio">
      <div-padding padding="4vw">
        <div-card>
          <div-padding padding="4vw 0">
            <van-cell @click="opt('zh')" clickable>
              <template #title>
                <div-text>简体中文</div-text>
              </template>
              <template #right-icon>
                <van-radio name="1" checked-color="#EF834C"></van-radio>
              </template>
            </van-cell>
          </div-padding>
          <van-divider />
        </div-card>

        <div-card>
          <div-padding padding="4vw 0">
            <van-cell @click="opt('hk')" clickable>
              <template #title>
                <div-text>繁体中文</div-text>
              </template>
              <template #right-icon>
                <van-radio name="2" checked-color="#EF834C"></van-radio>
              </template>
            </van-cell>
          </div-padding>
          <van-divider />
        </div-card>

        <div-card>
          <div-padding padding="4vw 0">
            <van-cell @click="opt('en')" clickable>
              <template #title>
                <div-text>English</div-text>
              </template>
              <template #right-icon>
                <van-radio name="3" checked-color="#EF834C"></van-radio>
              </template>
            </van-cell>
          </div-padding>
          <van-divider />
        </div-card>

      </div-padding>
    </van-radio-group>
  </div-box>
</template>
<script>
export default {
  data() {
    return {
      lang_obj: { zh: "1", hk: "2", en: "3" },
      radio: "1",
    }
  },
  created() {
    this.radio = this.lang_obj[this.getLang()]
  },
  methods: {
    opt(lang) {
      this.radio = this.lang_obj[lang]
      this.changeLang(lang)
      this.goBack()
    },
  },
}
</script>
<style lang='less' scoped></style>
