import Vue from 'vue'
import App from './App'
import router from './router/index'
import 'vant/lib/index.css';
import './assets/css/reset.css';
import http from './request/http'
Vue.use(http)
import { i18n } from './utils/lang'
Vue.config.productionTip = false;
new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')
