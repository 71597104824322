<template>
  <nav-bar left-arrow :title="$t(title)" fixed :bgColor="$c('#fff')" :leftColor="$c('#333')" :titleColor="$c('#333')" />
</template>
<script>
export default {
  name: 'div-navber',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  created() {},

  methods: {},
}
</script>
<style lang='less' scoped>
</style>
