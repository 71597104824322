<template>
  <div class="index">
    <!-- <div-navber title="商城订单" /> -->
    <nav-bar title="商城订单" left-arrow fixed :bg-color="$c('#fff')" :leftColor="$c('#333')" :titleColor="$c('#333')" />

    <van-tabs @change="onClick" v-model="active" background="#fff" :broder="false" line-height="2" sticky
      color="#EF834C" title-active-color="#EF834C">
      <van-tab :title="item.tit" v-for="item in titlist" :key="item.ind">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :offset="0">
          <div class="listbox" v-for="(val, valIndex) in list" :key="valIndex" @click="
      $router.push({ name: 'orderDetails', query: { id: val.order_sn } })
      ">
            <div class="top">
              <p>订单编号：{{ val.order_sn }}</p>
              <p class="state">
                {{ ["待支付", "待发货", "待收货", "已完成"][val.status] }}
              </p>
            </div>
            <div class="center">
              <img v-lazy="httpPath + val.goods_cover" class="shangpin" />
              <div class="right">
                <p class="name two">{{ val.goods_name }}</p>
                <div class="down">
                  <p class="price">
                    <template><span>￥</span>{{ val.price }}</template>
                  </p>
                  <p class="num">X{{ val.num }}</p>
                </div>
              </div>
            </div>

            <div class="bottom">
              <van-button round type="info" color="#999999" plain v-if="val.status == 0">取消订单</van-button>
              <van-button round type="info" color="linear-gradient(263deg, #EA3501 0%, #FE7729 100%)"
                v-if="val.status == 0">立即付款</van-button>
              <!-- <van-button round type="info" color="#fe7729" plain v-if="val.status == 2">申请退款</van-button> -->
              <!-- <van-button round type="info" color="#fe7729" plain v-if="val.status == 3">申请退货</van-button> -->
              <van-button round type="info" color="linear-gradient(263deg, #EA3501 0%, #FE7729 100%)"
                v-if="val.status == 2">确认收货</van-button>
              <!-- <van-button round type="info" color="#fe7729" plain v-if="val.status == 6">寄回发货</van-button> -->

              <!-- <van-button round type="info" color="#EA3501">查看详情</van-button> -->
            </div>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      active: 0,
      status: 5,
      titlist: [
        { tit: "全部", ind: 5 },
        { tit: "待付款", ind: 0 },
        { tit: "待发货", ind: 1 },
        { tit: "待收货", ind: 2 },
        { tit: "已完成 ", ind: 3 },
      ],
      list: [],
      page: 1,
      finished: false,
      loading: false,
    };
  },
  created() {
    if (sessionStorage.getItem("orderlist")) {
      this.active = Number(sessionStorage.getItem("orderlist"));
      this.status = [5, 0, 1, 2, 3][this.active]
    }
  },
  methods: {
    the_back() {
      window.scrollTo(0, 0);
      this.$router.isBack = true;
      this.$router.replace("/mine");
      console.log("返回个人中心");
    },
    onClick(index) {
      console.log(index)
      sessionStorage.setItem("orderlist", index);
      this.page = 1;
      this.status = [5, 0, 1, 2, 3][index]
      this.list = [];

      this.onLoad();
    },
    onLoad() {
      var page = this.page++;
      this.$get({
        url: "/goods_order/lists",
        data: {
          status: this.status,
          page,
        },
        success: (res) => {
          if (!res.data) {
            this.finished = true;
            return;
          }
          if (res.data.data.length == 0) {
            this.finished = true;
          } else {
            var ret = res.data.data;
            if (page == 1) {
              this.list = ret;
            } else {
              for (var x in ret) {
                this.list.push(ret[x]);
              }
            }
          }
          this.loading = false;
        },
        tip: () => {
          this.finished = true;
        },
      });
    },
  },
};
</script>

<style scoped lang='less'>
.index {
  width: 100%;
  min-height: 100vh;
  height: auto;
  background: #f8f8f8;

  .van-nav-bar {
    .van-icon {
      color: #000;
    }
  }

  .van-tabs__content {
    padding: 15px;
  }

  .listbox {
    width: 92vw;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 15px;
    padding: 15px;
    margin-top: 15px;

    .top {
      width: 100%;
      padding: 0 0 10px;
      display: flex;
      justify-content: space-between;
      color: #000000;
      font-size: 13px;
      font-weight: 550;
      border-bottom: 1px solid #f5f5f5;

      .state {
        font-size: 14px;
        color: #fe7729;
      }
    }

    .center {
      width: 100%;
      padding: 15px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f5f5f5;

      .shangpin {
        width: 85px;
        height: 85px;
      }

      .right {
        width: 69%;
        min-height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .name {
          width: 100%;
          font-size: 15px;
          font-weight: 550;
          color: #000000;
        }

        .down {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .price {
            color: #fe7729;
            font-size: 15px;
            font-weight: 550;

            span {
              font-size: 13px;
            }
          }

          .num {
            font-size: 12px;
            color: #999999;
          }
        }
      }
    }

    .bottom {
      width: 100%;
      padding-top: 3vw;
      text-align: right;

      .van-button {
        min-width: 23vw;
        height: 8vw;
        margin-left: 1vw;
      }
    }
  }
}
</style>
