<template>
  <div-box>
    <nav-bar left-arrow :title="$t('登录密码')" :bgColor="$c('#fff')" :leftColor="$c('#333')" :titleColor="$c('#333')" />
    <div-height height="7vw"></div-height>

    <div-padding padding="0 6vw">
      <!-- //顶部 -->

      <div-card>
        <div-padding padding="3vw 0">
          <van-field clearable v-model="phone" :readonly="true" type="tell" maxlength="11"
            :placeholder="$t('请输入您的手机号')">
            <template #label>
              <div-text size="4vw" color="#666">⼿机号</div-text>
            </template>
          </van-field>
        </div-padding>
        <van-divider />
      </div-card>
      <div-height height="7vw"></div-height>

      <div-card>
        <div-padding padding="3vw 0">
          <van-field clearable v-model="code" type="text" :placeholder="$t('请输入您的验证码')">
            <template #label>
              <div-text size="4vw" color="#666">验证码</div-text>
            </template>
            <template #button>
              <send-code plain api="/sendsms" :send-tell="{ phone: phone }" :send-scene="{ scene: 'edit_password' }"
                :no-token="false" color="#EF834C" round>
              </send-code>
            </template>
          </van-field>
        </div-padding>
        <van-divider />
      </div-card>
      <div-height height="7vw"></div-height>
      <form>
        <div-card>
          <div-padding padding="3vw 0">
            <van-field autocomplete clearable v-model="pass_01" :type="['text', 'password'][pass_type1 * 1]"
              label="登录密码" :readonly="!phone" :placeholder="$t('请输入6～12位数字+字母密码')"
              :right-icon="pass_type1 ? 'closed-eye' : 'eye'" @click-right-icon="pass_type1 = !pass_type1">
              <template #label>
                <div-text size="4vw" color="#666">密码</div-text>
              </template>
            </van-field>
          </div-padding>
          <van-divider />
        </div-card>
      </form>
      <div-height height="7vw"></div-height>
      <form>
        <div-card>
          <div-padding padding="3vw 0">
            <van-field autocomplete clearable v-model="pass_02" :type="['text', 'password'][pass_type2 * 1]"
              label="确认密码" :readonly="!phone" :placeholder="$t('请确认6～12位数字+字母密码')"
              :right-icon="pass_type2 ? 'closed-eye' : 'eye'" @click-right-icon="pass_type2 = !pass_type2">
              <template #label>
                <div-text size="4vw" color="#666">重复密码</div-text>
              </template>
            </van-field>
          </div-padding>
          <van-divider />
        </div-card>
      </form>
      <div-height height="7vw"></div-height>
      <div style="height: 12vw"></div>

      <div-btn ref="btn" radius="2vw" @fun="save">
        <div-text size="4vw" color="#FFF">确定</div-text>
      </div-btn>

      <div style="height: 4vw"></div>
    </div-padding>
  </div-box>
</template>
<script>
export default {
  data() {
    return {
      phone: "", //手机号
      code: "", //验证码
      pass_01: "", //密码
      pass_02: "",
      pass_type1: true,
      pass_type2: true,
    }
  },
  created() {
    this.get_info()
  },
  methods: {
    get_info() {
      // post 请求
      this.$post({
        url: "user_profile",
        success: (res) => {
          console.log(res)
          this.phone = res.data.phone
        },
        tip: () => { },
      })

      //
    },
    save() {
      if (!this.phone) {
        this.$refs.btn.disabled = false
        return this.$toast(this.$t("请输入您的手机号"))
      }
      if (!this.code) {
        this.$refs.btn.disabled = false
        return this.$toast(this.$t("请输入您的验证码"))
      }
      if (!this.pass_01) {
        this.$refs.btn.disabled = false
        return this.$toast(this.$t("请输入6～12位数字+字母密码"))
      }
      if (!this.pass_02) {
        this.$refs.btn.disabled = false
        return this.$toast(this.$t("请确认二级密码(6位纯数字)"))
      }

      // 修改密码
      this.$post({
        url: "/user/fix_password",
        data: {
          sms_code: this.code,
          password: this.pass_01,
          password_com: this.pass_02,
        },
        success: (res) => {
          console.log("============", res)
          this.$toast(res.msg)
          setTimeout(() => {
            this.$router.replace("login")
            this.$refs.btn.disabled = false
          }, 1500)
        },
        tip: (val) => {
          setTimeout(() => {
            this.$refs.btn.disabled = false
          }, 1500)
        },
      })
    },
  },
}
</script>
<style lang='less' scoped></style>
