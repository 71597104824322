<template>
  <div>
    <div-padding padding="0 4vw">
      <div-both>
        <div-width width="50%">
          <div-text size="3vw">买盘</div-text>
        </div-width>
        <div-width width="50%">
          <div-text size="3vw" left>卖盘</div-text>
        </div-width>
      </div-both>
      <div-height height="2vw"></div-height>
      <div-both center v-if="buy_sale.buy_ratio && buy_sale.sale_ratio">
        <div-text size="3vw" color="#EF834C">{{ buy_sale.buy_ratio || '0' }}% </div-text>
        <div style="flex: 0.9;">
          <van-progress :percentage="info.buy_sale.buy_ratio" color="#EF834C" track-color='#39AD44' />
        </div>
        <div-text size="3vw" color="#39AD44">{{ buy_sale.sale_ratio || '0' }}% </div-text>
      </div-both>
      <div-height height="8vw"></div-height>
    </div-padding>

    <div-padding padding="0 4vw">
      <div-both>
        <div-width width="45vw">
          <div v-for="(item, index) in buy_orders" :key="index" @click="opt_num(item)">
            <div-card :bg="$c('#FEF8F8')" radius="0">
              <div-padding padding="2vw 1vw">
                <div-both center>
                  <div-left center>
                    <div-card bg="#EF834C" radius="0">
                      <div-padding padding="0 1vw">
                        <div-text size="3vw" color="#FFF">{{ index * 1 + 1 }}</div-text>
                      </div-padding>
                    </div-card>
                    <div-width width="2vw"></div-width>
                    <div-text size="3vw">{{ item.price }}</div-text>
                  </div-left>
                  <div-text size="3vw">{{ item.num_ }}</div-text>
                </div-both>
              </div-padding>
            </div-card>
          </div>
          <div v-for="(item, index) in Number(10 - buy_orders.length)" :key="`index_${index}`">
            <div-card :bg="$c('#FEF8F8')" radius="0">
              <div-padding padding="2vw 1vw">
                <div-both center>
                  <div-left center>
                    <div-card bg="#EF834C" radius="0">
                      <div-padding padding="0 1vw">
                        <div-text size="3vw" color="#FFF">{{ index * 1 + 1 }}</div-text>
                      </div-padding>
                    </div-card>
                    <div-width width="2vw"></div-width>
                    <div-text size="3vw">---</div-text>
                  </div-left>
                  <div-text size="3vw">---</div-text>
                </div-both>
              </div-padding>
            </div-card>
          </div>
        </div-width>
        <div-width width="45vw">
          <div v-for="(item, index) in sale_orders" :key="index" @click="opt_right(item)">
            <div-card :bg="$c('#F7FCF8')" radius="0">
              <div-padding padding="2vw 1vw">
                <div-both center>
                  <div-left center>
                    <div-card bg="#39AD44" radius="0">
                      <div-padding padding="0 1vw">
                        <div-text size="3vw" color="#FFF">{{ index * 1 + 1 }}</div-text>
                      </div-padding>
                    </div-card>
                    <div-width width="2vw"></div-width>
                    <div-text size="3vw">{{ item.price }}</div-text>
                  </div-left>
                  <div-text size="3vw">{{ item.num_ }}</div-text>
                </div-both>
              </div-padding>
            </div-card>
          </div>
          <div v-for="(item, index) in Number(10 - sale_orders.length)" :key="`index_${index}`">
            <div-card :bg="$c('#F7FCF8')" radius="0">
              <div-padding padding="2vw 1vw">
                <div-both center>
                  <div-left center>
                    <div-card bg="#39AD44" radius="0">
                      <div-padding padding="0 1vw">
                        <div-text size="3vw" color="#FFF">{{ index * 1 + 1 }}</div-text>
                      </div-padding>
                    </div-card>
                    <div-width width="2vw"></div-width>
                    <div-text size="3vw">--</div-text>
                  </div-left>
                  <div-text size="3vw">--</div-text>
                </div-both>
              </div-padding>
            </div-card>
          </div>
        </div-width>
      </div-both>
    </div-padding>
  </div>
</template>

<script>
export default {
  name: "sblist",
  props: {
    sell_out: {
      type: Function,
      default: null,
    },
    product_id: {
      type: [String, Number],
      default: "",
    },
    info: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      buy_sale: {},
      buy_orders: [],
      sale_orders: [],
      list_socket: null,
      sned_time: null,
      checked: true,
    }
  },
  destroyed() {
    this.sned_time && clearInterval(this.sned_time)
    this.list_socket && this.list_socket.close()
  },
  watch: {
    info: {
      deep: true,
      handler: function (newdata, olddata) {
        // this.set_list()
      },
    },
  },
  created() {
    this.list_socket && this.list_socket.close()
    this.sned_time && clearInterval(this.sned_time)
    this.list_init()
  },
  methods: {
    //红
    opt_num(item) {
      this.sell_out(2, "卖出", item.price)
    },
    opt_right(item) {
      this.sell_out(1, "买入", item.price)
    },
    set_list() {
      let buyLength = this.info.buy_orders
      let saleLength = this.info.sale_orders
      this.buy_orders = this.info.buy_orders
      this.sale_orders = this.info.sale_orders
      this.buy_sale = this.info.buy_sale
      for (let i = 10; i > buyLength; i--) {
        this.buy_orders.push(i)
      }
      for (let i = 10; i > saleLength; i--) {
        this.sale_orders.push(i)
      }
    },
    //list列表 ws init
    list_init() {
      if (typeof WebSocket === "undefined") {
        this.$toast("您的浏览器不支持socket")
      } else {
        this.list_socket = new WebSocket(this.wsPath)
        this.list_socket.onopen = this.list_open
        this.list_socket.onmessage = this.list_message
        this.list_socket.onerror = this.list_error
        this.list_socket.onclose = this.list_close
      }
    },
    //list列表 ws
    list_open() {
      // console.log("list连接成功")
      this.list_send()
      this.sned_time = setInterval(() => {
        this.list_send()
      }, 3000)
    },
    //list列表 ws
    list_send() {
      // console.log("list发送")
      const params = JSON.stringify({
        type: "buy_sale",
        product_id: this.product_id,
      })
      this.list_socket.send(params)
    },
    //list列表 ws
    list_message(msg) {
      if (!msg?.data) return
      let data = JSON.parse(msg.data)
      if (data.type == "buy_sale") {
        this.buy_orders = data.data.buy_orders
        this.sale_orders = data.data.sale_orders
        this.buy_sale = data.data.buy_sale
      }
    },
    //list列表 ws
    list_error() {
      console.log("list连接错误")
    },
    //list列表 ws
    list_close() {
      console.log("list关闭")
    },
  },
}
</script>

<style></style>