<template>
  <div class="div-left" :class="{ bottom, center, br, fill, baseline }">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'div-left',
  props: {
    baseline: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
    br: {
      type: Boolean,
      default: false,
    },
    fill: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang='less' scoped>
.div-left {
  display: flex;
  justify-content: flex-start;
}

.div-left.baseline {
  height: 100%;
  align-items: baseline !important;
}

.div-left.bottom {
  height: 100%;
  align-items: flex-end;
}

.div-left.center {
  height: 100%;
  align-items: center;
}

.br {
  flex-wrap: wrap;
}

.fill {
  width: 100%;
}
</style>
