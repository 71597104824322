<template>
  <div class="VantUpDown">
    <van-pull-refresh v-model="Down" @refresh="get_down">
      <van-list v-model="up" :finished="over" :finished-text="$t('没有更多了')" @load="get_up" :immediate-check="false">
        <slot></slot>
        <van-empty v-if="list && list.length == 0 && !empty" />
      </van-list>
    </van-pull-refresh>
    <go-top round />
  </div>
</template>

<script>
export default {
  name: "VantUpDown",
  props: ["url", "data", "res", "empty", "init"],
  data() {
    return {
      list: null,
      page: 1,
      loading: false,
      Down: false,
      up: false,
      over: false,
    }
  },
  created() {
    // console.log("加载上拉加载下拉刷新组件")
    window.scrollTo(0, 0)
    //是否开始就加载
    // if (!this.init) {
    //   this.list_init()
    // }
  },
  methods: {
    //初始化数据
    list_init() {
      // console.log("初始化")
      this.page = 1
      this.over = false
      this.get_list("down") // 加载数据
      console.log(this.list)
    },
    //下拉刷新 下拉的这个 调了getlistla
    get_down() {
      console.log("下拉")
      setTimeout(() => {
        this.page = 1
        this.over = false
        this.get_list("down") // 加载数据
      }, 1000)
    },
    // 上拉加载 上拉这个 调用getlist
    get_up() {
      if (this.list && this.list.length > 0) {
        console.log("上拉")
        this.page++
        this.get_list("up") // 加载数据
      }
    },
    // 更多广告
    get_list(type) {
      let data = Object.assign({ page: this.page }, this.data)
      this.$get({
        url: this.url,
        data: data,
        success: (res) => {
          let data = eval(this.res)
          console.log("返回的数据", data)
          if (type == "down") {
            this.list = data
            this.$emit("list", this.list)
          } else if (type == "up") {
            if (this.list) {
              this.list = [...this.list, ...data]
              this.$emit("list", this.list)
            }
          }
          if (data.length == 0) {
            this.over = true
          }

          this.Down = false
          this.up = false
        },
        tip: (val) => {
          this.over = true
        },
      })
    },
  },
}
</script>

<style scoped lang="less">
// 空空如也
.van-empty {
  padding-top: calc((100vh - 70vw) / 2);
  padding-bottom: calc((100vh - 70vw) / 2);
}

//这段样式是改了bug 和修改了下拉样式。
//下拉刷新样式
.van-pull-refresh {
  min-height: calc(100vh - 12.3vw);
  // background: #ebebeb;
}

/deep/.van-pull-refresh__head {
  // height: 16vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;

  .van-loading {
    background: #fff;
  }

  .van-pull-refresh__text {
    margin-top: 2vw;
    animation: loader-animation 0.8s ease-out infinite;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    width: 40vw;
    height: 10vw;
    border-radius: 5vw;
    margin: 0 auto;
  }

  @keyframes preloader_5 {
    0% {
      transform: rotate(0deg);
    }

    50% {
      transform: rotate(180deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .van-pull-refresh__text::before {
    display: inline-block;
    margin-right: 2vw;
    width: 3vw;
    height: 3vw;
    border-top: 1vw solid #31b0d5;
    border-bottom: 1vw solid#00ad5d;
    border-left: 1vw solid transparent;
    border-right: 1vw solid transparent;
    border-radius: 5vw;
    content: "";
    animation: preloader_5 1.5s infinite linear;
  }

  @keyframes loader-animation {
    0% {
      transform: scale(1, 1);
    }

    35% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.2, 0.8);
    }

    75% {
      transform: scale(0.8, 1.2);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .van-loading--circular {
    margin-top: 2vw;
    animation: loader-animation 0.8s ease-out infinite;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    width: 40vw;
    height: 10vw;
    border-radius: 5vw;
    margin: 0 auto;

    span:first-child {
      display: none;
    }

    span {
      color: #666;
    }
  }

  .van-loading--circular::before {
    display: inline-block;
    margin-right: 2vw;
    width: 3vw;
    height: 3vw;
    border-top: 1vw solid #31b0d5;
    border-bottom: 1vw solid#00ad5d;
    border-left: 1vw solid transparent;
    border-right: 1vw solid transparent;
    border-radius: 5vw;
    content: "";
    animation: preloader_5 1.5s infinite linear;
  }
}
</style>
