<template>
  <div-box bg="#FF6A4C">
    <!-- //顶部bar -->
    <nav-bar left-arrow :title="$t('邀请好友')" fixed :bgColor="'#fff'" leftColor="#000" titleColor="#000"
      :rightColor="$c('#000')" @click-right="router('share_list')" :right-text="$t('邀请列表')" />
    <div-img width="100vw" height="193vw" :src="require('@/assets/img/friends_bg.png')">
      <div-padding padding="5vw">
        <div-text size="7vw" color="#fff" bold>华数通数字中心</div-text>
        <div-text size="4vw" color="#fff">Huashutong Digital Center</div-text>
      </div-padding>
      <div-height height="111vw" />
      <div class="dibu">
        <div-padding padding="5vw">
          <div-both center>
            <div-left center>
              <div-avatar border="0.5vw" size="13vw" :src="$img(info.head_img)" color="#fff"></div-avatar>
              <div-width width="3vw"> </div-width>
              <div>


                <div @click="copyData(info.invite_code)">
                  <div-left center>
                    <div-text size="3.5vw" color="#fff">邀请码：</div-text>
                    <div-text size="4vw" bold color="#fff">
                      {{ info.invite_code }}
                    </div-text>
                  </div-left>
                </div>
                <div @click="copyData(info.invite_code)">
                  <div-left center>
                    <div-text size="3.5vw" color="#fff">机构码：</div-text>
                    <div-text size="4vw" bold color="#fff">
                      {{ info.business_code }}
                    </div-text>
                    <!-- <div-width width="2vw"></div-width>
                    <div-img width="4vw" height="4vw">
                      <img src="~@/assets/img/copy.png" />
                    </div-img> -->
                  </div-left>
                </div>
              </div>
            </div-left>
            <div class="qrcode">
              <img :src="info.img" alt="" />
            </div>
          </div-both>
          <div-height height="10vw" />
          <div-text center color="#fff">请截图保存分享给好友</div-text>
        </div-padding>
      </div>
    </div-img>


  </div-box>
</template>

<script>
// import QRCode from "qrcodejs2"
export default {
  props: {},
  data() {
    return {
      info: {},
      disabled: false,
      img: "", //二维码
      invite_code: "123456", //邀请码
      url: "", //邀请链接
    }
  },
  created() {
    // 滚动到顶部
    this.get_me()
    // this.$nextTick(() => {
    //   this.creatQrCode(this.invite_code)
    // })
  },
  methods: {
    // 获取二维码信息
    get_me() {
      this.$get({
        url: "/user/share",
        success: (res) => {
          console.log("邀请好友", res)
          this.info = res.data
          // this.img = this.res.data.qrcode;
          // this.invite_code = res.data.referral_code

          // if (this.invite_code) {
          //   this.$nextTick(() => {
          //     this.creatQrCode(this.invite_code)
          //   })
          // }
          console.log("个人信息", this.info)
        },
      })
    },
    // creatQrCode(invite_code) {
    //   console.log(invite_code, "邀请码")
    //   console.log(
    //     "this.invite_code",
    //     `${this.httpPath}/#/register_html?invite=${invite_code || ""}`
    //   )
    //   new QRCode(this.$refs.qrCodebox, {
    //     text: `${this.httpPath}/#/register_html?invite=${invite_code || ""}`,
    //     width: 160,
    //     height: 160,
    //     colorDark: "#333333", //二维码颜色
    //     colorLight: "#ffffff", //二维码背景色
    //     correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
    //   })
    // },
  },
}
</script>

<style scoped lang="less">
.container_box {
  position: absolute;
  top: 55vh;
  width: 100vw;

  //   display: flex;
  // 二维码
  .container {

    // margin-left: 5vw;
    .img_outer {
      // margin: 0 auto;
      // background-color: rgb(255, 255, 255);
      width: 37vw;
      height: 37vw;
      //padding: 3vw;
      // margin-top: 5vw;
      // text-align: center;
      //    border: 2vw solid rgba(0, 0, 0, 0);
      border-radius: 2vw;
      margin: 0 auto;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        background: #fff;
      }

      p {
        padding-top: 2vw;

        img {
          background: rgba(0, 0, 0, 0);
        }
      }
    }
  }

  // 邀请码.
  .invitation_code {
    display: block;
    background: rgba(0, 0, 0, 0.5);
    /* display: -webkit-box; */
    display: -ms-flexbox;
    /* display: flex; */
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 4vw;
    color: #fff;
    width: 40vw;
    text-align: center;
    margin: 0 auto;
    border-radius: 5vw;
  }

  // 复制按钮
  .btn_box {
    width: 77vw;
    margin: 0 auto;

    .anBtn {
      background: #3b2ba9;
      border-radius: 10vw;
    }
  }

  .btn_box[data-v-fe4dd9de] .van-button {
    background: -webkit-gradient(linear,
        0 50%,
        100% 50%,
        from(rgb(73, 117, 238)),
        to(rgb(235, 8, 223)));
    /*Old gradient for webkit*/
  }
}

/deep/#qrcode {
  width: 100% !important;
  height: 100% !important;
  //   border: 2px solid green;
  //   margin-top: 15px;
}

/deep/#qrcode canvas+img {
  width: 100% !important;
  height: 100% !important;
}

/deep/.qrcode {
  width: 30vw !important;
  height: 30vw !important;

  img {
    width: 100% !important;
    height: 100% !important;
  }
}

.dibu {
  width: 100%;
  background: #FF6A4C;
}
</style>
