<template>
  <div-box>
    <div-navber title="图文详情"></div-navber>
    <!-- 内容 -->
    <div-padding padding="3vw">

      <div-html :html="info.content"></div-html>
      <van-empty image="error" v-if="!info.content" description="404" />
    </div-padding>
  </div-box>
</template>

<script>
export default {
  data() {
    return {
      info: {},
    }
  },
  created() {
    // 滚动到顶部
    this.setUrlData()
    this.get_info()
  },
  methods: {
    // 更多广告
    get_info() {
      this.$post({
        url: "/banner/detail",
        data: {
          id: this.id,
        },
        success: (res) => {
          this.info = res.data
        },
        tip: () => {},
      })
    },
  },
}
</script>

<style scoped lang="scss">
</style>
