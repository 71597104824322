import { Toast, Lazyload, ImagePreview } from 'vant'
import VueClipboard from 'vue-clipboard2'
import dayjs from 'dayjs'
import router from '../router/index';
// 国际化
import { i18n, vantLocales } from '../utils/lang'

import theme from './theme.js';
export default {
  install(Vue, option) {
    Vue.use(theme);
    Vue.prototype.$img = function (url) {
      return this.httpPath + url
    }

    Vue.prototype.vant_list = function () {
      setTimeout(() => {
        this.$refs.list.list_init()
      }, 0)
    }

    // 退出登录
    Vue.prototype.dialog = function (data) {
      let that = this;

      this.$dialog
        .confirm({
          title: "提示",
          message: "确认要退出登录吗?",
          //确认按钮颜色
          confirmButtonColor: "#333",
        })
        .then(() => {
          that.$toast.loading({
            message: '正在退出中...',
            forbidClick: true,
            duration: 0,
          })
        })
        .catch(() => {
          // on cancel
        });
    },

      vantLocales(i18n.locale)
    Vue.use(Lazyload, {
      lazyComponent: true,
    });


    Vue.use(VueClipboard)
    // toast 提示
    Vue.prototype.toast = function (data) {
      Toast(data)
    }
    // 图片预览
    Vue.prototype.lookImg = function (images, index = 0,) {
      ImagePreview({
        images: [images],
        startPosition: index,
        closeable: true,
      });
    }
    // 拨打电话
    Vue.prototype.callTel = function (tell) {
      window.location.href = `tel://${tell}`
    }
    // 保留小数 num=>数字  n=>保留几位小数 is=>是否四舍五入
    Vue.prototype.toFixed = function (num, n, is = true) {
      if (is) return parseFloat(num.toFixed(n))
      if (!is) return Number(num).toFixed(n + 1).slice(0, -1);
    }
    //复制文本方法
    Vue.prototype.copyData = function (data) {
      Vue.prototype.$copyText(data.toString()).then(function (e) {
        Toast.success();
      }, function (e) {
        Toast.fail();
      })
    }
    //多少时间之前
    Vue.prototype.timeago = function (stringTime) { //dateTimeStamp是一个时间毫秒，注意时间戳是秒的形式，在这个毫秒的基础上除以1000，就是十位数的时间戳。13位数的都是时间毫秒。
      var minute = 1000 * 60;
      var hour = minute * 60;
      var day = hour * 24;
      var week = day * 7;
      var month = day * 30;
      var time1 = new Date().getTime();//当前的时间戳
      console.log(time1);
      var time2 = Date.parse(new Date(stringTime));//指定时间的时间戳
      console.log(time2);
      var time = time1 - time2;

      var result = null;
      if (time < 0) {
        alert("设置的时间不能早于当前时间！");
      } else if (time / month >= 1) {
        result = "" + parseInt(time / month) + "月前";
      } else if (time / week >= 1) {
        result = "" + parseInt(time / week) + "周前";
      } else if (time / day >= 1) {
        result = "" + parseInt(time / day) + "天前";
      } else if (time / hour >= 1) {
        result = "" + parseInt(time / hour) + "小时前";
      } else if (time / minute >= 1) {
        result = "" + parseInt(time / minute) + "分钟前";
      } else {
        result = "刚刚发布！";
      }
      return result;
    }

    //日期 dayjs
    Vue.prototype.$dayjs = dayjs
    //路由跳转
    Vue.prototype.router = function (name, data) {
      window.scrollTo(0, 0);
      if (typeof data == 'object') {
        router.push({ path: name, query: data });
      } else if (typeof data == 'number' || typeof data == 'string') {
        router.push({ path: name, query: { id: data } });
      } else {
        router.push(name);
      }
    }
    //获取URL参数
    Vue.prototype.urlQuery = function (name) {
      if (name) {
        return router.app._route.query[name];
      } else {
        return router.app._route.query;
      }
    }
    // 获取URL参数 自动映射data里面
    Vue.prototype.setUrlData = function () {
      let obj = this.$route.query
      Object.keys(obj).map((item) => (this[item] = obj[item]))
    }
    //关闭页面跳转路由
    Vue.prototype.goReplace = function (path) {
      router.replace({ path });
    }
    //返回上一页
    Vue.prototype.goBack = function () {
      window.scrollTo(0, 0);
      this.$router.isBack = true;
      router.go(-1);
    }
    // 退出登录
    Vue.prototype.loginOut = function () {
      localStorage.clear()
      sessionStorage.clear()
      router.replace('login')
    }
    //操作 localStorage
    Vue.prototype.local = {
      set(key, value) {
        localStorage.setItem(`${process.env.VUE_APP_NAME}_${key}`, value);
      },
      get(key) {
        return localStorage.getItem(`${process.env.VUE_APP_NAME}_${key}`);
      },
      del(key) {
        localStorage.removeItem(`${process.env.VUE_APP_NAME}_${key}`);
      }
    }
    //操作 sessionStorage
    Vue.prototype.session = {
      set(key, value) {
        sessionStorage.setItem(`${process.env.VUE_APP_NAME}_${key}`, value);
      },
      get(key) {
        return sessionStorage.getItem(`${process.env.VUE_APP_NAME}_${key}`);
      },
      del(key) {
        sessionStorage.removeItem(`${process.env.VUE_APP_NAME}_${key}`);
      }
    }
    // 倒计时 time 结束日期(YYYY-MM-DD HH:mm:ss)
    Vue.prototype.timeDown = function (time) {
      let date = new Date()
      let newtime = date.getTime()
      let endtime = new Date(time.replace(/-/g, "/")).getTime()
      return Number(endtime - newtime)
    }
    // 国际化 获取语言
    Vue.prototype.getLang = function () {
      return i18n.locale
    }
    // 国际化 切换语言
    Vue.prototype.changeLang = function (lang, reload = false) {
      i18n.locale = lang
      vantLocales(lang)
      reload && window.location.reload()
    }
    // 浏览器页面跳转
    Vue.prototype.href = function (url) {
      window.location.href = url
    }
    // h5支付 后端返回form格式数据
    Vue.prototype.h5pay = function (data) {
      const div = document.createElement('paydata');
      div.innerHTML = data;
      document.body.appendChild(div);
      document.forms[0].setAttribute('target', '_self');
      document.forms[0].submit();
      div.remove();
    }
    // 是不是pc端
    Vue.prototype.isPc = function () {
      var userAgentInfo = navigator.userAgent;
      var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    }
    // 安卓还是ios true=>安卓  false=>ios
    Vue.prototype.an_or_ios = function () {
      if (navigator.userAgent.match(/(Android)/i)) {
        return true
      } else if (navigator.userAgent.match(/(iPhone|iPad|iPod|ios)/i)) {
        return false
      }
    }

    /**
      *  以下是调用安卓 ios的方法
      */
    // 下载(保存)单张图片(url完整链接格式)
    Vue.prototype.downloadImg = function (url) {
      if (this.an_or_ios()) {
        if (window.android) window.android.callAndroidDownloadImg(url);
        else this.toast('请在APP中打开尝试调用原生方法')
      } else if (!this.an_or_ios()) {
        if (window.webkit) window.webkit.messageHandlers.callIOSDownloadImg.postMessage(url)
        else this.toast('请在APP中打开尝试调用原生方法')
      }
    }
    // 下载(保存)单个图片(Base64格式)
    Vue.prototype.downloadBase64Img = function (img) {
      if (this.an_or_ios()) {
        if (window.android) window.android.callAndroidDownloadBase64Img(img);
        else this.toast('请在APP中打开尝试调用原生方法')
      } else if (!this.an_or_ios()) {
        if (window.webkit) window.webkit.messageHandlers.saveIOSBase64Image.postMessage(img)
        else this.toast('请在APP中打开尝试调用原生方法')
      }
    }
    // 分享单图图片(url完整链接格式)
    Vue.prototype.shareImg = function (url) {
      if (this.an_or_ios()) {
        if (window.android) window.android.sharingForAndroid(url);
        else this.toast('请在APP中打开尝试调用原生方法')
      } else if (!this.an_or_ios()) {
        if (window.webkit) window.webkit.sharingForAppleOs.postMessage.postMessage(url)
        else this.toast('请在APP中打开尝试调用原生方法')
      }
    }
    // 分享多图给微信好友(url完整链接格式)
    Vue.prototype.shareImgs = function (urls) {
      let imgs = JSON.stringify(urls);
      if (this.an_or_ios()) {
        if (window.android) window.android.sharingForAndroid(imgs);
        else this.toast('请在APP中打开尝试调用原生方法')
      } else if (!this.an_or_ios()) {
        if (window.webkit) window.webkit.sharingForAppleOs.postMessage.postMessage(imgs)
        else this.toast('请在APP中打开尝试调用原生方法')
      }
    }
    // 使用 SDK 分享链接
    Vue.prototype.shareSDK = function ({ title, link, image, content }) {
      let data = JSON.stringify({ title, link, image, content })
      if (this.an_or_ios()) {
        if (window.android) window.android.sharingForAndroidBySDK(data);
        else this.toast('请在APP中打开尝试调用原生方法')
      } else if (!this.an_or_ios()) {
        if (window.webkit) window.webkit.sharingWeixinForAppleOs.postMessage.postMessage(data)
        else this.toast('请在APP中打开尝试调用原生方法')
      }
    }
  }
}