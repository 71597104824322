<template>
  <div-box foot top="0" bg="#F8F8F8">
    <van-nav-bar title=" " fixed left-arrow @click-left="goBack()" z-index="1000" :border="false" />
    <van-swipe class="swipebox" :autoplay="3000" indicator-color="#ffffff">
      <van-swipe-item v-for="(item, index) in info.banner" :key="index">
        <img :src="httpPath + item" />
      </van-swipe-item>
    </van-swipe>
    <!-- 标题  -->

    <div-padding padding="4vw">
      <div-card bg="#fff" radius="2vw">
        <div-padding padding="4vw">
          <div-both center>
            <div-left center baseline>
              <div-text size="3vw" color="#FE7729" bold>￥</div-text>
              <div-text size="5vw" color="#FE7729" bold>{{ info.price }}</div-text>
            </div-left>

            <div-right center>
              <div-text size="3vw" color="#999999">销量：{{ info.sales }}</div-text>
              <div-width width="4vw" />
              <div-text size="3vw" color="#999999">库存：{{ info.stock }}</div-text>
            </div-right>

          </div-both>
          <div-height size="3vw"></div-height>
          <div-text size="4.5vw" color="#0A0A0A" two bold>
            {{ info.title }}
          </div-text>
        </div-padding>
      </div-card>
    </div-padding>

    <!-- 详情  -->

    <div-center center>
      <div-card radius="1.5vw" bg="linear-gradient(278deg, #EA3501 0%, #FE7729 100%)">
        <div-padding padding="0.3vw 2vw"></div-padding>
      </div-card>
      <div-width width="1vw" />
      <div-text color="#FE7729" size="4vw" bold>商品详情</div-text>
      <div-width width="1vw" />
      <div-card radius="1.5vw" bg="linear-gradient(278deg, #EA3501 0%, #FE7729 100%)">
        <div-padding padding="0.3vw 2vw"></div-padding>
      </div-card>
    </div-center>

    <div-height height="4vw" />

    <div-html :html="info.content"></div-html>

    <van-goods-action>
      <van-goods-action-button @click="show_pop()" color="#EA3501" type="danger" text="立即购买" />
    </van-goods-action>

    <van-popup v-model="show" title="" round :closeable="true" position="bottom" close-icon="close"
      :safe-area-inset-bottom="true">
      <div class="popup">
        <p class="tit"></p>
        <div class="listbox">
          <van-swipe-cell>
            <van-card :price="info.price" :title="info.title" :thumb="httpPath + info.cover">
              <template #price>
                <div class="price">￥{{ info.price }}</div>
                <!-- <div-text v-if="info.type == 1" size="3.5vw" color="#FE7729">+{{ site.one_cny_to_hashrate_price * info.price }}盈利宝</div-text> -->
              </template>
              <!-- 步进器-->
              <template #num>
                <van-stepper v-model="cart_amount" button-size="22" disable-input />
              </template>
            </van-card>
          </van-swipe-cell>
        </div>
        <div class="btnbox2">
          <van-button round type="primary" class="anBtn" :loading="disabled" size="large"
            @click="order">立即购买</van-button>
        </div>
      </div>
    </van-popup>
  </div-box>
</template>

<script>
export default {
  data() {
    return {
      id: "",
      classify: "",
      info: {},
      site: "",
      current: 0,
      show: false,
      type: "加入购物车", //加入购物车或者直接购买
      cart_amount: 1, //步进器数量
      disabled: false,
      have_address: true, //是否有默认地址
      loading: false,
    };
  },
  created() {
    this.setUrlData()
    //获取详情页面数据
    this.get_info();

  },
  methods: {

    //初始化数据
    get_info() {
      this.$get({
        url: "/goods/detail",
        data: { id: this.id },
        success: (res) => {
          this.info = res.data;
        },
        tip: (val) => { },
      });
    },
    get_address() {
      this.$get({
        url: "/app/useraddress/index",
        success: (res) => {
          console.log("地址", res.data.data.length > 0);
          if (res.data.data.length > 0) {
            this.have_address = true;
          } else {
            this.have_address = false;
          }
        },
        tip: () => { },
      });
    },
    // 点击支付前往支付订单
    order(info) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定购买该商品？",
          confirmButtonColor: "#333",
        })
        .then(() => {
          this.disabled = true;

          this.$router.push({
            name: "order_good",
            query: {
              goods_id: this.id,
              goods_number: this.cart_amount,
            },
          });
          let arr = [];
          info.classify = this.classify; //商品类型
          info.cart_amount = this.cart_amount; //数量
          arr.push(info); //商品信息
          this.show = false;
          setTimeout(() => {
            this.disabled = false;
          }, 250);
        })
        .catch(() => {
          this.show = false;
          setTimeout(() => {
            this.disabled = false;
          }, 1000);
        });
    },
    show_pop() {
      //如果有地址
      if (this.have_address) {
        this.show = true;
      } else {
        this.$dialog
          .confirm({
            title: "提示",
            message: "请先绑定收货地址",
            confirmButtonColor: "#333",
          })
          .then(() => {
            this.show = false;
            setTimeout(() => {
              this.router("bind_addres");
            }, 250);
          })
          .catch(() => {
            this.show = false;
          });
      }
    },
    addcar() {
      this.loading = true;
      this.$post({
        url: "/app/cart/addtocart",
        data: { goods_id: this.id, number: 1 },
        success: (res) => {
          this.$toast(res.data);
          setTimeout(() => {
            this.loading = false;
          }, 1500);
        },
        tip: () => {
          setTimeout(() => {
            this.loading = false;
          }, 1300);
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
//顶部栏
/deep/.van-nav-bar {
  background: none !important;
  box-shadow: none !important;
  height: 12vw;

  .van-icon {
    font-size: 4.5vw;
    color: #fff !important;
  }

  .van-nav-bar__title {
    color: #fff !important;
    font-size: 3.5vw;
  }

  .van-nav-bar__content {
    .van-nav-bar__left {
      padding: 0 1.8vw;
      top: 3vw;
      left: 3vw;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 50vw 50vw 50vw 50vw;
    }
  }
}

//标题栏
.title_box {
  padding: 3vw;

  .tag {
    display: flex;
    justify-content: space-between;
    font-size: 3vw;
    color: #a3a3a3;
  }

  .price {
    .total {
      font-size: 6vw;
      font-weight: 800;
      color: #fe7729;

      em {
        color: #fe7729;
        font-size: 2.8vw;
        padding-left: 1vw;
      }
    }
  }

  .name {
    font-size: 5vw;
    color: #333;
    font-weight: 800;
    overflow: hidden;
    line-height: 7.47vw;
  }

  .text {
    font-size: 4vw;
    color: #666;
  }
}

//轮播图样式
.swipebox {
  width: 100vw;
  height: 100vw;
  position: relative;

  .van-swipe-item {
    >img {
      width: 100%;
      height: 100%;
    }
  }

  .custom {
    padding: 0 8px;
    background: rgba(0, 0, 0, 0.36);
    border-radius: 3vw;
    color: #ffffff;
    font-size: 3.4vw;
    position: absolute;
    bottom: 3vw;
    right: 3vw;
  }
}

//底部弹出窗样式
.popup {
  p {
    padding: 2vw 0;
    width: 100%;
    text-align: center;
    font-size: 4.5vw;
    color: #000;
  }

  .listbox {
    padding: 3vw;

    //商品卡片
    .van-card {
      background: #ffffff;
      padding: 2vw 2vw 1;

      // 信息样式
      /deep/.van-card__title {
        font-size: 4vw;
        max-height: 12vw;
        font-weight: bold;
        color: #303133;
        line-height: 6vw;
      }

      .van-card__content {
        min-height: auto;
      }

      .van-card__price {
        color: #fe7729;
        font-weight: bold;
        font-size: 4vw;
      }

      //步进器样式
      /deep/.van-card__num {
        .van-stepper {
          display: flex;
        }

        .van-stepper__minus--disabled {
          background: #fff;
        }

        .van-stepper__plus--disabled {
          background: #fff;
        }

        .van-stepper__input {
          background: #fff;
        }

        .van-stepper__minus {
          background: #fff;
          border: 1px solid #d1d1d6;
        }

        .van-stepper__plus {
          background: #fff;
          border: 1px solid #d1d1d6;
        }

        .van-stepper__input {
          margin: 0;
          border-top: 1px solid #d1d1d6;
          border-bottom: 1px solid #d1d1d6;
        }
      }
    }
  }

  .van-button--large {
    height: 12vw;
    font-size: 3.5vw;
  }

  .btnbox {
    width: 100%;
    margin: 3vw 0;
    padding: 0 3vw;
    display: flex;
    justify-content: center;
    align-items: center;

    .van-button--primary {
      background: linear-gradient(135deg, #ffd671 0%, #f27f00 100%);
      border: 0;
    }
  }

  .btnbox2 {
    width: 100%;
    margin: 3vw 0;
    padding: 0 3vw;
    display: flex;
    justify-content: center;
    align-items: center;

    .van-button--primary {
      border: 0;
      background: linear-gradient(135deg, #ff4929 0%, #f42600 100%);
    }
  }
}

.index {
  width: 100vw;
  min-height: 100vh;
  height: auto;
  box-sizing: border-box;
  background: #ffffff;
  padding: 0 0 10vh;

  //详情标题
  .details_title {
    position: relative;
    height: 10vw;
    width: 50vw;
    margin: 0 auto;

    .title {
      text-align: center;
      margin: 0 auto;
      font-size: 4.8vw;
      font-weight: 600;
      color: #333;
    }

    .shadow::before {
      width: 6vw;
      height: 1.5vw;
      display: block;
      content: "";
      position: absolute;
      border-radius: 1vw;
      box-shadow: 10vw -8vw #f54a4a, 8vw -5vw #f54a4a, 45vw -8vw #f54a4a,
        47vw -5vw #f54a4a;
      transform: scale(0.8);
    }
  }

  //详情内容
  .content {
    width: 100%;

    /deep/ img {
      width: 100%;
    }
  }

  //底部弹出窗样式
  .popup {
    p {
      padding: 2vw 0;
      width: 100%;
      text-align: center;
      font-size: 4.5vw;
      color: #000;
    }

    .listbox {
      padding: 3vw;

      //商品卡片
      .van-card {
        background: #ffffff;
        padding: 2vw 2vw 1;

        // 信息样式
        /deep/.van-card__title {
          font-size: 4vw;
          max-height: 12vw;
          font-weight: bold;
          color: #303133;
          line-height: 6vw;
        }

        .van-card__content {
          min-height: auto;
        }

        .van-card__price {
          color: #e63a2a;
          font-size: 4vw;
        }

        //步进器样式
        /deep/.van-card__num {
          .van-stepper {
            display: flex;
          }

          .van-stepper__minus--disabled {
            background: #fff;
          }

          .van-stepper__plus--disabled {
            background: #fff;
          }

          .van-stepper__input {
            background: #fff;
          }

          .van-stepper__minus {
            background: #fff;
            border: 1px solid #d1d1d6;
          }

          .van-stepper__plus {
            background: #fff;
            border: 1px solid #d1d1d6;
          }

          .van-stepper__input {
            margin: 0;
            border-top: 1px solid #d1d1d6;
            border-bottom: 1px solid #d1d1d6;
          }
        }
      }
    }

    .van-button--large {
      height: 12vw;
      font-size: 3.5vw;
    }

    .btnbox {
      width: 100%;
      margin: 3vw 0;
      padding: 0 3vw;
      display: flex;
      justify-content: center;
      align-items: center;

      .van-button--primary {
        background: linear-gradient(135deg, #ffd671 0%, #f27f00 100%);
        border: 0;
      }
    }

    .btnbox2 {
      width: 100%;
      margin: 3vw 0;
      padding: 0 3vw;
      display: flex;
      justify-content: center;
      align-items: center;

      .van-button--primary {
        border: 0;
        background: linear-gradient(135deg, #ff4929 0%, #f42600 100%);
      }
    }
  }
}
</style>
