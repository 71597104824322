<template>
  <div-box tabber>
    <div-padding padding="4vw">
      <div-text size="5vw" color="#000000" bold>市场</div-text>
    </div-padding>
    <!-- 标题 -->
    <div-padding padding="4vw">
      <div-both center>
        <div-text size="5vw" color="#000000" bold>行情</div-text>
        <div-right center>
          <van-icon name="wap-nav" :color="$c('#c1c0c5')" />
          <div-width width="2vw"></div-width>
          <div-text size="4vw" color="#666666" @click.native="router('hangqing_list')">更多</div-text>
        </div-right>
      </div-both>
    </div-padding>
    <!-- 标题 -->
    <div class="item">
      <div-padding padding="4vw">
        <van-row type="flex" justify="space-between" align="center">
          <van-col span="4">
            <div-text size="3vw">名称代码</div-text>
          </van-col>
          <van-col span="4">
            <div-text size="3vw" center>成交价</div-text>
          </van-col>
          <van-col span="4">
            <div-text size="3vw" center>开盘价</div-text>
          </van-col>
          <van-col span="4">
            <div-text size="3vw" center>收盘价</div-text>
          </van-col>
          <van-col span="4">
            <div-text size="3vw" right>成交量</div-text>
          </van-col>
        </van-row>
      </div-padding>
      <div-padding padding="0 4vw">
        <van-divider />
      </div-padding>
    </div>
    <div class="list">
      <div class="item" v-for="(item, index) in list" :key="index" @click="router('kling', item.id)">
        <div-padding padding="4vw">
          <van-row type="flex" justify="space-between" align="center">
            <van-col span="4">
              <div-text size="3vw">{{ item.name }}</div-text>
              <div-text size="3vw" one>{{ item.code }}</div-text>
            </van-col>
            <van-col span="4">
              <div-text size="3vw" color="#E45F50" center>{{ item.success_price }}</div-text>
            </van-col>
            <van-col span="4">
              <div-text size="3vw" center>{{ item.open_price }}</div-text>
            </van-col>
            <van-col span="4">
              <div-text size="3vw" center>{{ item.close_price }}</div-text>
            </van-col>
            <van-col span="4">
              <div-text size="3vw" right>{{ item.success_num }}</div-text>
            </van-col>
          </van-row>
        </div-padding>
        <div-padding padding="0 4vw">
          <van-divider />
        </div-padding>
      </div>
    </div>
    <!-- 标题 -->
    <div-padding padding="4vw 4vw 1vw 4vw">
      <div-both center>
        <div-text size="5vw" color="#000000" bold>券商信息</div-text>
        <div-right center>
          <van-icon name="wap-nav" :color="$c('#c1c0c5')" />
          <div-width width="2vw"></div-width>
          <div-text size="4vw" color="#666666" @click.native="router('company_list')">更多</div-text>
        </div-right>
      </div-both>
    </div-padding>

    <div class="list">
      <div v-for="(item, index) in list2" :key="index" @click="router('company_del', item.id)">
        <div-padding padding="4vw" class="company">
          <img v-lazy="$img(item.business_img)" alt="">
          <div class="right">
            <div-text size="4vw" color="#000000" bold one>{{ item.business_name }}</div-text>
            <div-text size="3vw" color="#636363" two>{{ item.business_describe }}</div-text>
            <div class="btn">
              <div-text size="3vw" color="#999999">今日交易额</div-text>
              <div-text size="3vw" color="#000000" bold>:{{ item.today_price }}</div-text>
            </div>
          </div>
        </div-padding>
        <div-padding padding="0 4vw">
          <van-divider />
        </div-padding>
      </div>
    </div>
  </div-box>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      list: [],
      list2: [],
    }
  },
  created() {
    const arr = [
      {
        name: "list", //知产
        url: "/product/lists",
        data: { page: 1 },
        res: "res.data.data",
      },
      {
        name: "list2", //公司
        url: "/business/lists",
        data: { page: 1 },
        res: "res.data.data",
      },
    ]
    arr.map((item, index) => {
      this.get_every(item)
    })
  },
  methods: {
    get_every(item) {
      this.$get({
        url: item.url,
        data: item.data,
        success: (res) => {
          this[item.name] = eval(item.res)
        },
        tip: () => { },
      })
    },
  },
}
</script>
<style scoped lang="less">
// 明亮
.light {}
//暗黑
.dark {}
.company {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 27vw;
    height: 27vw;
    border-radius: 3vw;
  }
  .right {
    width: 68%;
    min-height: 27vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    .btn {
      display: flex;
    }
  }
}
</style>
