<template>
  <div-box>
    <div-navber title="我的知产"></div-navber>

    <div-height height="12vw">
      <van-tabs v-model="active" @click="opt" title-active-color="#000" color="#EF834C">
        <van-tab>
          <template #title>
            <div-text class="#333">买入知产</div-text>
          </template>
        </van-tab>
        <van-tab>
          <template #title>
            <div-text class="#333">申购知产</div-text>
          </template>
        </van-tab>
      </van-tabs>
    </div-height>

    <div-height height="3vw"></div-height>
    <vant-up-down ref="list" @list="(val) => (list = val)" res="res.data.data" :url="url[active]" data="">
      <div-padding padding="0 4vw">
        <div class="list" v-if="active == 0">
          <div class="item">
            <div-padding padding="4vw">
              <van-row type="flex" justify="space-between" align="center">
                <van-col span="8">
                  <div-text size="3vw">名称代码</div-text>
                </van-col>
                <van-col span="8">
                  <div-text size="3vw" center>均价/数量</div-text>
                </van-col>
                <van-col span="8">
                  <div-text size="3vw" right>市值/盈亏</div-text>
                </van-col>
              </van-row>
            </div-padding>
            <div-padding padding="0 4vw">
              <van-divider />
            </div-padding>
          </div>
          <div class="item" v-for="(item, index) in list" :key="index">
            <div-padding padding="4vw">
              <van-row type="flex" justify="space-between" align="center">
                <van-col span="8">
                  <div-text size="3vw">{{ item.product.name }}</div-text>
                  <div-text size="3vw">{{ item.product.code }}</div-text>
                </van-col>
                <van-col span="8">
                  <div-center>
                    <div-text size="3vw" center>{{ item.average_price }}</div-text>
                    <div-text size="3vw" center>/</div-text>
                    <div-text size="3vw" center>{{ item.num }}</div-text>
                  </div-center>
                </van-col>
                <van-col span="8">
                  <div-right>
                    <div-text size="3vw" right>{{ item.market_price }}</div-text>
                    <div-text size="3vw" right>/</div-text>
                    <div-text size="3vw" :color="item.status == 1 ? '#E45F50' : '#31d90b'"
                      right>{{ item.status == 1 ? '+' : '-' }}</div-text>
                    <div-text size="3vw" :color="item.status == 1 ? '#E45F50' : '#31d90b'"
                      right>{{ item.diff_price }}</div-text>

                  </div-right>
                </van-col>
              </van-row>
            </div-padding>
            <div-padding padding="0 4vw">
              <van-divider />
            </div-padding>
          </div>
        </div>
        <div class="list" v-if="active == 1">
          <div class="item">
            <div-padding padding="4vw">
              <van-row type="flex" justify="space-between" align="center">
                <van-col span="8">
                  <div-text size="3vw">名称代码</div-text>
                </van-col>
                <van-col span="8">
                  <div-text size="3vw" center>申购价格</div-text>
                </van-col>
                <van-col span="8">
                  <div-text size="3vw" right>申购数量</div-text>
                </van-col>
              </van-row>
            </div-padding>
            <div-padding padding="0 4vw">
              <van-divider />
            </div-padding>
          </div>
          <div class="item" v-for="(item, index) in list" :key="index">
            <div-padding padding="4vw">
              <van-row type="flex" justify="space-between" align="center">
                <van-col span="8">
                  <div-text size="3vw">{{ item.product.name }}</div-text>
                  <div-text size="3vw">{{ item.product.code }}</div-text>
                </van-col>
                <van-col span="8">
                  <div-text size="3vw" color="#E45F50" center>{{ item.price }}</div-text>
                </van-col>
                <van-col span="8">
                  <div-text size="3vw" right>{{ item.num }}</div-text>
                </van-col>
              </van-row>
            </div-padding>
            <div-padding padding="0 4vw">
              <van-divider />
            </div-padding>
          </div>
        </div>
      </div-padding>
    </vant-up-down>
  </div-box>
</template>
<script>
export default {
  data() {
    return {
      url: ["product_order/buy_order_lists", "apply_order/lists"],
      active: 0,
      list: [],
    }
  },
  created() {
    // 滚动到顶部
    this.vant_list()
  },
  methods: {
    opt(name) {
      console.log("3333", name)
      this.list = []
      this.vant_list()
    },
  },
}
</script>
<style lang="scss" scoped></style>