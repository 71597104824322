<template>
  <div-box bg="#F9FAFB">
    <nav-bar :title="$t('我的订单')" left-arrow fixed :bg-color="$c('#fff')" :right-text="$t('我的交易')"
      :leftColor="$c('#333')" :titleColor="$c('#333')" :rightColor="$c('#333')" @click-right="router('my_deal')" />

    <div-height height="12vw">
      <van-tabs v-model="active" @click="opt" title-active-color="#000" color="#EF834C">
        <van-tab>
          <template #title>
            <div-text class="#333">买入委托</div-text>
          </template>
        </van-tab>
        <van-tab>
          <template #title>
            <div-text class="#333">卖出委托</div-text>
          </template>
        </van-tab>
      </van-tabs>
    </div-height>

    <div-height height="3vw"></div-height>
    <vant-up-down ref="list" @list="(val) => (list = val)" res="res.data.data" url="/product_order/lists"
      :data="{ type: active * 1 + 1 }">
      <div-padding padding="0 4vw">
        <div class="list">
          <div class="item" v-for="item in list" :key="item.id">
            <div>
              <div-card :bg="$c('#ffffff')" radius="1vw" shadow="0px 1vw 2vw 0px rgba(0,0,0,0.02)">
                <div-padding padding=" 4vw 4vw 4vw 0">
                  <div-both center>
                    <div-left center>
                      <div-card bg="#EF834C" radius="0">
                        <div-padding padding="2vw 0.5vw"></div-padding>
                      </div-card>
                      <div-width width="3vw"></div-width>
                      <div-text size="4vw" color="#000000">订单号</div-text>
                      <div-text size="4vw" color="#000000">：{{ item.order_sn }}</div-text>
                    </div-left>
                    <div-right center>
                      <div-text size="4vw" color="#EF834C">{{ item.status_desc }}</div-text>
                    </div-right>
                  </div-both>
                </div-padding>
                <van-divider />
                <div-padding padding="4vw">
                  <div-both center>
                    <div>
                      <div-left center>
                        <div-text color="#999" size="3.5vw">知产名称</div-text>
                        <div-text color="#999" size="3.5vw">：</div-text>
                        <div-text color="#333" size="3.5vw">{{ item.product.name }}</div-text>
                      </div-left>
                      <div-height height="3vw"></div-height>
                      <div-left>
                        <div-text color="#333" size="3.5vw">{{ item.created_at }}</div-text>
                      </div-left>
                    </div>
                    <div-right center v-if="active == 0 && item.status != 3">
                      <!-- <div-card bg="#EF834C" radius="1vw" @click.native="revoke(item.id)">
                        <div-padding padding="1vw 4vw">
                          <div-text color="#FFF" center>撤单</div-text>
                        </div-padding>
                      </div-card> -->
                      <div>
                        <div-width width="22vw">
                          <div-btn :ref="'btn_' + item.id" @fun="revoke('btn_' + item.id, item.id)" radius="12vw"
                            height="8vw" bg="#EF834C" color="#fff">
                            <div-text size="3vw" color="#FFF">撤单</div-text>
                          </div-btn>
                        </div-width>
                      </div>
                    </div-right>
                  </div-both>
                </div-padding>
                <van-divider />

                <div-padding padding="4vw">
                  <div-both>
                    <div>
                      <div-text center bold>{{ item.num }}</div-text>
                      <div-text color="#666666" center>委托数量</div-text>
                    </div>
                    <div>
                      <div-text center bold>{{ item.revoke_num }}</div-text>
                      <div-text color="#666666" center>撤单数量</div-text>
                    </div>
                    <div>
                      <div-text center bold>{{ item.success_num }}</div-text>
                      <div-text color="#666666" center>成交数量</div-text>
                    </div>
                    <div>
                      <div-text center bold color="#EF834C">{{ item.price }}</div-text>
                      <div-text color="#666666" center>委托价格</div-text>
                    </div>
                  </div-both>
                </div-padding>
              </div-card>
              <div-height height="3vw"></div-height>
            </div>
          </div>
        </div>
      </div-padding>
    </vant-up-down>
  </div-box>
</template>
<script>
export default {
  data() {
    return {
      active: 0,
      list: [],
    }
  },
  created() {
    // 滚动到顶部
    this.vant_list()
  },
  methods: {
    opt() {
      this.list = []
      this.vant_list()
    },
    revoke(btn, id) {
      this.$dialog
        .confirm({
          // title: "标题",
          message: `${this.$t("是否撤单")}`,
        })
        .then(() => {
          this.$post({
            loading: true,
            url: "/product_order/revoke",
            data: {
              order_id: id,
            },
            success: (res) => {
              this.$toast(res.msg)
              this.vant_list()
              setTimeout(() => {
                this.$refs[btn][0].disabled = false
              }, 1500)
            },
            tip: (val) => {
              this.$toast(val.msg)
              this.vant_list()
              setTimeout(() => {
                this.$refs[btn][0].disabled = false
              }, 1500)
            },
          })
        })
        .catch(() => {
          // on cancel
          setTimeout(() => {
            this.$refs[btn][0].disabled = false
          }, 1500)
        })
    },
  },
}
</script>
<style lang="scss" scoped></style>