<template>
  <div-box>
    <div-navber title="备注"></div-navber>
    <div-height size="7vw"></div-height>

    <div-padding padding="0 4vw">
      <!-- 标题 -->
      <div-height height="12vw"></div-height>
      <!-- //顶部 -->
      <!-- <div-text>个人简介</div-text> -->
      <div-card :bg="$c('#ffffff')" radius="2vw" shadow="0px 1vw 2vw 0px rgba(0,0,0,0.04)">
        <div-padding padding="3vw">
          <van-field v-model="message" rows="2" autosize type="textarea" maxlength="50" :placeholder="$t('请输入备注')" show-word-limit>
          </van-field>
        </div-padding>
        <van-divider />
      </div-card>
      <div-height height="50vw"></div-height>
      <div-btn ref="btn" radius="2vw" @fun="save">
        <div-text size="4vw" color="#FFF">保存</div-text>
      </div-btn>

      <div style="height: 4vw"></div>
    </div-padding>
  </div-box>
</template>
<script>
export default {
  data() {
    return {
      message: "", //个人信息
    }
  },
  created() {},
  methods: {
    save() {
      if (!this.message) {
        this.$refs.btn.disabled = false
        return this.$toast("请输入个人简介")
      }

      // 修改密码
      this.$post({
        url: "/user/fix_remark",
        data: {
          remark: this.message,
        },
        success: () => {
          this.$toast("修改成功")
          setTimeout(() => {
            this.goBack()
            this.$refs.btn.disabled = false
          }, 1500)
        },
        tip: (val) => {
          setTimeout(() => {
            this.$refs.btn.disabled = false
          }, 1500)
        },
      })
    },
  },
}
</script>
<style lang='less' scoped>
</style>
