<template>
  <div-box tabber>
    <div-navber title="券商信息"></div-navber>
    <vant-up-down ref="list" @list="(val) => (list = val)" res="res.data.data" url="/business/lists" data="">
      <div class="list">
        <div class="item" v-for="(item, index) in list" :key="index" @click="router('company_del', item.id)">
          <div-padding padding="4vw" class="company">
            <img v-lazy="$img(item.business_img)" alt="">
            <div class="right">
              <div-text size="4vw" color="#000000" bold one>{{ item.business_name }}</div-text>
              <div-text size="3vw" color="#636363" two>{{ item.business_describe }}</div-text>
              <div class="btn">
                <div-text size="3vw" color="#999999">今日交易额：</div-text>
                <div-text size="3vw" color="#000000" bold>:{{ item.today_price }}</div-text>
              </div>
            </div>
          </div-padding>
          <div-padding padding="0 4vw">
            <van-divider />
          </div-padding>
        </div>
      </div>
    </vant-up-down>
  </div-box>
</template>
<script>
export default {
  data() {
    return {
      list: [],
    }
  },
  created() {
    this.vant_list()
  },
  methods: {},
}
</script>
<style scoped lang="less">
// 明亮
.light {}
//暗黑
.dark {}
.company {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 27vw;
    height: 27vw;
    border-radius: 3vw;
  }
  .right {
    width: 68%;
    min-height: 27vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    .btn {
      display: flex;
    }
  }
}
</style>
