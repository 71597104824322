module.exports = {
  "未登录": "未登录",
  "昨天": "昨天",
  "登录失败": "登录失败",
  "k": "k线",
  "open": "开盘",
  "close": "关盘",
  "low": "最低",
  "hig": "最高",
  "收益列表": "收益列表",
  "交易金额": "交易金额",
  "收益金额": "收益金额",
  "开": "开",
  "分时": "分时",
  "商标数字存证中心": "商标数字存证中心",
  "日线": "日线",
  "周线": "周线",
  "月线": "月线",
  "年线": "年线",
  "1分": "1分",
  "5分": "5分",
  "15分": "15分",
  "30分": "30分",
  "小时": "小时",

  "注册": "注册",
  "理财第⼀选择": "理财第⼀选择",
  "密码": "密码",
  "登录": "登录",
  "忘记密码": "忘记密码",
  "请输入您的登录密码": "请输入您的登录密码",
  "《用户服务协议》": "《用户服务协议》",
  "和": "和",
  "《隐私政策》": "《隐私政策》",

  "首页": "首页",
  "知产资讯": "知产资讯",
  "更多": "更多",
  "官方推荐": "官方推荐",
  "自选": "自选",
  "精选": "精选",
  "名称代码": "名称代码",
  "成交价": "成交价",
  "开盘价": "开盘价",
  "收盘价": "收盘价",
  "成交量": "成交量",
  "申购": "申购",
  "招股价": "招股价",
  "申购": "申购",
  "最小申购金额": "最小申购金额",
  "最小申购份额": "最小申购份额",
  "申购截止日期": "申购截止日期",
  "剩余": "剩余",
  "小时": "小时",
  "官方发布": "官方发布",
  "市场": "市场",
  "行情": "行情",
  "券商信息": "券商信息",
  "资讯详情": "资讯详情",
  "详情": "详情",
  "请输入交易密码": "请输入交易密码",
  "资讯列表": "资讯列表",
  "申购列表": "申购列表",
  "公告列表": "公告列表",
  "公告详情": "公告详情",
  "交易": "交易",

  "群聊": "群聊",
  "创建群聊": "创建群聊",
  "消息通知": "消息通知",
  "搜索群名": "搜索群名",
  "搜索": "搜索",
  "申请加入": "申请加入",
  "通知": "通知",
  "拒绝": "拒绝",
  "同意": "同意",
  "已同意": "已同意",
  "已拒绝": "已拒绝",

  "我的": "我的",
  "已实名": "已实名",
  "钱包余额": "钱包余额",
  "立即查看": "立即查看",
  "系统功能": "系统功能",
  "我的订单": "我的订单",
  "我的持股": "我的持股",
  "我的知产": "我的知产",
  "邀请好友": "邀请好友",
  "其他功能": "其他功能",
  "新手指南": "新手指南",
  "帮助中心": "帮助中心",
  "联系客服": "联系客服",
  "切换语言": "切换语言",
  "选择语言": "选择语言",
  "系统设置": "系统设置",

  "个人资料": "个人资料",
  "头像": "头像",
  "昵称": "昵称",
  "手机号": "手机号",
  "实名信息": "实名信息",
  "个人简介": "个人简介",
  "注册时间": "注册时间",

  "修改昵称": "修改昵称",
  "请输入您的昵称": "请输入您的昵称",
  "保存": "保存",
  "实名认证": "实名认证",
  "身份证": "身份证",
  "姓名": "姓名",
  "请输入您的手机号": "请输入您的手机号",
  "请输入您的身份证": "请输入您的身份证",
  "请输入个人简介": "请输入个人简介",
  "请输入您的姓名": "请输入您的姓名",
  "修改成功": "修改成功",

  "我的交易": "我的交易",
  "买入委托": "采购委托",
  "卖出委托": "转让委托",
  "订单号": "订单号",
  "已报": "已报",
  "已成": "已成",
  "撤单": "撤单",
  "委托数量": "委托数量",
  "知产名称": "知产名称",
  "撤单数量": "撤单数量",
  "成交数量": "成交数量",
  "委托价格": "委托价格",
  "我的买入": "我的采购",
  "我的卖出": "我的转让",
  "单价": "单价",
  "数量": "数量",
  "价格": "价格",
  "确权": "确权",
  "锁仓": "锁仓",

  "买入知产": "采购知产",
  "申购知产": "申购知产",
  "名称代码": "名称代码",
  "均价/数量": "均价/数量",
  "市值/盈亏": "市值/盈亏",
  "申购价格": "申购价格",
  "申购数量": "申购数量",
  "邀请码": "邀请码",
  "请截图保存分享给好友": "请截图保存分享给好友",
  "帮助中心": "帮助中心",
  "您好！": "您好！",
  "欢迎来到帮助中心！": "欢迎来到帮助中心！",
  "客服": "客服",

  "收款方式": "收款方式",
  "机构码": "机构码",
  "修改登录密码": "修改登录密码",
  "修改支付密码": "修改支付密码",
  "银行卡": "银行卡",
  "微信": "微信",
  "支付宝": "支付宝",
  "未绑定": "未绑定",
  "开启夜间模式": "开启夜间模式",
  "关闭夜间模式": "关闭夜间模式",
  "⼿机号": "⼿机号",
  "验证码": "验证码",
  "重复密码": "重复密码",
  "交易密码": "交易密码",
  "确认密码": "确认密码",
  "发送验证码": "发送验证码",
  "请输入您的验证码": "请输入您的验证码",
  "请输入6～12位数字+字母密码": "请输入6～12位数字+字母密码",
  "请输入二级密码(6位纯数字)": "请输入二级密码(6位纯数字)",
  "请确认6～12位数字+字母密码": "请确认6～12位数字+字母密码",
  "请确认二级密码(6位纯数字)": "请确认二级密码(6位纯数字)",
  "请仔细阅读并勾选《用户协议》和《隐私政策》": "请仔细阅读并勾选《用户协议》和《隐私政策》",

  "⽤户注册": "⽤户注册",
  "返回登录": "返回登录",
  "请输入手机号": "请输入手机号",
  "请输入正确格式的手机号": "请输入正确格式的手机号",
  "加载中...": "加载中...",
  "发送成功": "发送成功",
  "验证中...": "验证中...",
  "请输入登录密码": "请输入登录密码",
  "请确认登录密码": "请确认登录密码",
  "确定": "确定",
  "已绑定": "已绑定",
  "银行卡号": "银行卡号",
  "开户行": "开户行",
  "绑定银行卡": "绑定银行卡",
  "请输入持卡人姓名": "请输入持卡人姓名",
  "请输入银行卡卡号": "请输入银行卡卡号",
  "请输入银行名称": "请输入银行名称",
  "绑定微信": "绑定微信",
  "请输入微信账号": "请输入微信账号",
  "请确认微信账号": "请确认微信账号",
  "微信账号": "微信账号",
  "重复账号": "重复账号",
  "微信收款码": "微信收款码",
  "请上传微信收款码": "请上传微信收款码",
  "两次账号不一致": "两次账号不一致",
  "绑定支付宝": "绑定支付宝",
  "支付宝账号": "支付宝账号",
  "请输入支付宝账号": "请输入支付宝账号",
  "请确认支付宝账号": "请确认支付宝账号",
  "支付宝收款码": "支付宝收款码",
  "请上传支付宝收款码": "请上传支付宝收款码",
  "未认证": "未认证",
  "请选择您要联系的客服": "请选择您要联系的客服",
  "我们根据选择为您提供服务": "我们根据选择为您提供服务",
  "电话客服": "电话客服",
  "一键拨打": "一键拨打",
  "微信客服": "微信客服",
  "一键复制": "一键复制",
  "常见问题": "常见问题",
  "用户": "用户",
  "这个家伙很懒,什么都没有写": "这个家伙很懒,什么都没有写",
  "已绑定机构码": "已绑定机构码",
  "绑定机构": "绑定机构",
  "我是机构": "我是机构",
  "我的机构码": "我的机构码",
  "请输入机构码": "请输入机构码",
  "填写机构码": "填写机构码",
  "解绑": "解绑",
  "退出登录": "退出登录",
  "立即绑定": "立即绑定",
  "钱包": "钱包",
  "充值": "充值",
  "提现": "提现",
  "可用余额(元)": "可用余额(元)",
  "余额变动明细": "余额变动明细",
  "全部": "全部",

  "提现记录": "提现记录",
  "提款金额": "提款金额",
  "请输入您的提现金额": "请输入您的提现金额",
  "选择提现方式": "选择提现方式",
  "申请提现": "申请提现",
  "充值记录": "充值记录",
  "充值金额": "充值金额",
  "请输入您的充值金额": "请输入您的充值金额",
  "选择充值方式": "选择充值方式",
  "上传凭证": "上传凭证",
  "申请充值": "申请充值",

  "报价": "报价",
  "简况": "简况",
  "卖出": "转让",
  "买入": "采购",
  "自选": "自选",
  "是否": "是否",
  "取消": "取消",
  "限价模式": "限价模式",
  "价格范围": "价格范围",
  "之间": "之间",
  "买盘": "买盘",
  "卖盘": "卖盘",
  "最高": "最高",
  "今开": "今开",
  "最低": "最低",
  "昨收": "昨收",
  "换手率": "换手率",
  "委比": "委比",
  "成交额": "成交额",
  "公司简介": "公司简介",
  "主席": "主席",
  "上市时间": "上市时间",
  "发行价格": "发行价格",
  "发行数量": "发行数量",
  "是否撤单": "是否撤单",

  "创建您的群聊天": "创建您的群聊天",
  "填写群名称（2-10个字）": "填写群名称（2-10个字）",
  "群名称": "群名称",
  "立即创建": "立即创建",
  "请输入群名称": "请输入群名称",
  "群头像": "群头像",
  "请上传群头像": "请上传群头像",
  "没有更多了": "没有更多了",
  "群号": "群号",
  "选择照片": "选择照片",
  "群聊创建成功": "群聊创建成功，快开始你们的聊天吧",
  "[群系统通知]": "[群系统通知]",
  "银行名称": "银行名称",
  "移出群聊": "移出群聊",
  "确定要移出群聊": "确定要移出群聊",
  "邀请列表": "邀请列表",
  "备注": "备注",
  "请输入备注": "请输入备注",
  "暂无备注": "暂无备注",
  "认证通过": "认证通过",
  "未认证": "未认证",

  "机构钱包": "机构钱包",
  "可用机构余额(元)": "可用机构余额(元)",
  "机构余额变动明细": "机构余额变动明细",
  "用户协议": "用户协议",
  "隐私政策": "隐私政策",
  "注销账户": "注销账户",
  "注销账户后，您的账户将无法找回，确定要注销吗？": "注销账户后，您的账户将无法找回，确定要注销吗？",
  "未开始": "未开始",
  "已结束": "已结束",
  "机构码": "机构码",
  "请输入机构码": "请输入机构码",
  "购买套餐": "购买套餐",
  "套餐": "套餐",
  "收益率": "收益率",
  "周期": "周期",
  "立即购买": "立即购买",
  "到期收益": "到期收益",
  "单价": "单价",
  "利率": "利率",
  "时间": "时间",
  "天": "天",
  "合计": "合计",
  "我的套餐": "我的套餐",
  "投资中": "投资中",
  "已结束": "已结束",
  "投资金额": "投资金额",
  "提前赎回": "提前赎回",
  "结束时间": "结束时间",
  "今日交易额": "今日交易额",
  "知产交易": "知产交易",
  "确认要提前取消套餐?": "确认要提前取消套餐?",
}