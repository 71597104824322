<template>
  <div-box>
    <div-navber title="个人资料"></div-navber>
    <div-height size="3vw"></div-height>
    <div-padding padding="4vw">
      <div-card>
        <div-padding padding="4vw 0">
          <van-cell is-link>
            <template #title>
              <div-text>头像</div-text>
            </template>
            <template>
              <div-right>
                <!--  -->
                <uploader :after-read="afterRead">
                  <div-avatar size="8vw" :src="$img(head_img)"></div-avatar>
                </uploader>
              </div-right>
            </template>
          </van-cell>
        </div-padding>
        <van-divider />
      </div-card>
      <div-card v-if="info.verify_status == 2">
        <div-padding padding="4vw 0">
          <van-cell is-link>
            <template #title>
              <div-text>昵称</div-text>
            </template>
            <template>
              <div-right>
                <div-text size="4vw">{{ info.real_name}}</div-text>
              </div-right>
            </template>
          </van-cell>
        </div-padding>
        <van-divider />
      </div-card>

      <div-card @click.native="router('nickname')" v-else>
        <div-padding padding="4vw 0">
          <van-cell is-link>
            <template #title>
              <div-text>昵称</div-text>
            </template>
            <template>
              <div-right>
                <div-text size="4vw">{{ info.nickname?info.nickname:$t('用户')}}</div-text>
                <div-text size="4vw" v-if="!info.nickname">{{info.id}}</div-text>
              </div-right>
            </template>
          </van-cell>
        </div-padding>
        <van-divider />
      </div-card>

      <div-card>
        <div-padding padding="4vw 0">
          <van-cell>
            <template #title>
              <div-text>手机号</div-text>
            </template>
            <template>
              <div-right>
                <div-text>{{ info.phone }}</div-text>
              </div-right>
            </template>
          </van-cell>
        </div-padding>
        <van-divider />
      </div-card>

      <div-card @click.native="info.verify_status == 2?$toast('认证通过'):router('real_name')">
        <div-padding padding="4vw 0">
          <van-cell is-link>
            <template #title>
              <div-text>实名信息</div-text>
            </template>
            <template>
              <div-right>
                <div-text v-if="info.verify_status == 0">未认证</div-text>
                <div-text v-if="info.verify_status == 2">认证通过</div-text>
              </div-right>
            </template>
          </van-cell>
        </div-padding>
        <van-divider />
      </div-card>

      <div-card @click.native="router('my_profile')">
        <div-padding padding="4vw 0">
          <van-cell is-link>
            <template #title>
              <div-text>个人简介</div-text>
            </template>
            <template>
              <div-right>
                <div-text one>{{ info.profile?info.profile:$t('这个家伙很懒,什么都没有写') }}</div-text>
              </div-right>
            </template>
          </van-cell>
        </div-padding>
        <van-divider />
      </div-card>

      <div-card>
        <div-padding padding="4vw 0">
          <van-cell>
            <template #title>
              <div-text>注册时间</div-text>
            </template>
            <template>
              <div-right>
                <div-text one>{{ info.created_at }}</div-text>
              </div-right>
            </template>
          </van-cell>
        </div-padding>
        <van-divider />
      </div-card>
    </div-padding>
  </div-box>
</template>

<script>
export default {
  data() {
    return {
      info: {},
      head_img: "",
      disabled: false, //按钮禁用
      checked: true, //是否默认
    }
  },
  created() {
    this.getdata()
  },
  methods: {
    afterRead(file) {
      // 上传图片
      let formData = new FormData()
      formData.append("file", file.file)
      this.$post({
        url: "/upload",
        data: formData,
        upload: true,
        success: (res) => {
          this.head_img = res.data
          // 修改头像

          this.$post({
            url: "/user/fix_info",
            data: {
              head_img: this.head_img,
            },
            success: (res) => {
              // this.head_img = res.data.avatar;
            },
            tip: () => { },
          })
        },
      })
    },

    //////////////
    getdata() {
      //   this.$toast.loading({
      //     message: '加载中...',
      //   })
      this.$post({
        url: "/user_profile",
        success: (res) => {
          this.$toast.clear()
          this.head_img = res.data.head_img
          this.info = res.data
        },
        tip: () => { },
      })
    },
    save() {
      if (!this.bank_account_name) {
        this.$refs.btn.disabled = false
        this.$toast("请输入持卡人姓名")
        return
      }
      if (!this.bank_no) {
        this.$refs.btn.disabled = false
        this.$toast("请输入银行卡卡号")
        return
      }
      if (!this.bank_name) {
        this.$refs.btn.disabled = false
        this.$toast("请输入银行名称")
        return
      }
      this.$refs.btn.disabled = true
      this.$post({
        url: "/app/user/setInfo",
        data: {
          bank_no: this.bank_no,
          bank_name: this.bank_name,
          real_name: this.bank_account_name,
        },
        success: () => {
          this.$toast("成功")
          setTimeout(() => {
            this.$refs.btn.disabled = false
            // this.getdata();
            this.back()
          }, 1500)
        },
        tip: () => {
          setTimeout(() => {
            this.$refs.btn.disabled = false
          }, 1500)
        },
      })
    },
  },
}
</script>

<style lang="less" scoped="scoped">
</style>
