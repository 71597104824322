<template>
  <div-box bg="#F9FAFB">
    <div-navber title="我的持股" />
    <div-height height="3vw"></div-height>
    <vant-up-down ref="list" @list="(val) => (list = val)" res="res.data.data" url="user_position/lists" data="">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div-padding padding="2vw 4vw">
          <div-card :bg="$c('#ffffff')" radius="1vw">
            <div-padding padding="3vw 4vw">
              <div class="reder"></div>
              <div-both>
                <div>
                  <div-text bold>{{ item.product.name }}</div-text>
                  <div-height height="2vw"></div-height>
                  <div-text size="3vw">{{ item.product.code }}</div-text>
                </div>

                <div-right>
                  <div @click="router('hold_record', { position_type: 1, product_id: item.product.id })">
                    <div-text color="#39AD44" center>{{ item.confirmed }}</div-text>
                    <div-card bg="#E1EFE2" radius="1vw" border="1px solid #39AD44">
                      <div-padding padding="0.5vw 2vw">
                        <div-text color="#39AD44" center>确权</div-text>
                      </div-padding>
                    </div-card>
                  </div>
                  <div-width width="7vw"></div-width>
                  <div @click="router('hold_record', { position_type: 2, product_id: item.product.id })">
                    <div-text color="#EF834C" center>{{ item.locked }}</div-text>
                    <div-card bg="#FFEFED" radius="1vw" border="1px solid #EF834C">
                      <div-padding padding="0.5vw 2vw">
                        <div-text color="#EF834C" center>锁仓</div-text>
                      </div-padding>
                    </div-card>
                  </div>
                </div-right>
              </div-both>
            </div-padding>
          </div-card>
        </div-padding>
      </div>
    </vant-up-down>
  </div-box>
</template>

<script>
export default {
  data() {
    return {
      list: "", //银行名称
    }
  },
  created() {
    // 滚动到顶部
    this.vant_list()
  },
  methods: {
    save() {
      console.log(123)
    },
  },
}
</script>

<style lang="less" scoped="scoped">
.reder {
  width: 1vw;
  height: 4vw;
  background: #EF834C;
  position: absolute;
  top: 4vw;
  left: 0;
}
</style>
