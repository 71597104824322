<template>
  <div-box>

    <div-navber title="编辑地址"></div-navber>

    <div class="formlist">
      <van-field v-model="name" clearable type="text" placeholder="请输入收货人姓名" label="收货人" />

      <van-field v-model="phone" clearable maxlength="11" type="text" placeholder="请输入收货人手机号" label="手机号" />
      <div class="van-cell van-field">
        <div class="oneline block" @click="choseAddress">
          <div class="name">所在地区</div>
          <input type="text" readonly placeholder="请选择地区" v-model="add" />
          <van-icon name="arrow-down" size="18" />
          <!-- <img src="~@/assets/img/mine_in.png" alt="" /> -->
        </div>
        <div class="oneline">
          <!-- <div class="name"></div> -->
          <textarea placeholder="请输入详细地址：如道路、街道、楼号等" v-model="address"></textarea>
        </div>
      </div>

      <div class="van-cell van-field">
        <div class="oneline">
          <div class="moren">设为默认收货地址</div>
          <van-switch v-model="checked" active-color="#F54A4A" size="20" />
        </div>
      </div>
    </div>

    <!-- <div class="btn_box">
      <van-button type="primary" :disabled="disabled" block @click="save()" class="anBtn">保存</van-button>
    </div> -->

    <div-height size="12vw" />

    <div-padding padding="3vw">
      <div-btn ref='btn' @fun='save'>保存</div-btn>
    </div-padding>

    <van-popup v-model="show" position="bottom">
      <van-area :value="addcode" :area-list="areaList" @confirm="confirmchose" @cancel="choseAddress" />
    </van-popup>
  </div-box>
</template>

<script type="text/javascript">
import { areaList } from "@/assets/js/area";

export default {
  name: "app",
  data() {
    return {
      id: '',
      disabled: false,
      phone: "", //收货人手机
      name: "", //收货人
      pro_id: "", //省id
      city_id: "", //市id
      area_id: "", //区id
      address: "", //具体地址
      province_name: "",
      city_name: "",
      district_name: "",
      checked: true,
      show: false,
      areaList: areaList,
      add: "",
      addcode: "370705",
    };
  },
  created() {
    this.setUrlData()
    this.get_info();
  },
  methods: {
    get_info() {
      this.$post({
        url: "/user/user_address/detail",
        data: { address_id: this.id },
        success: (res) => {
          this.phone = res.data.phone; //收货人手机
          this.name = res.data.people; //收货人

          this.pro_id = res.data.province; //省id
          this.city_id = res.data.city; //市id
          this.area_id = res.data.area_id; //区id
          this.address = res.data.detail; //详细地址
          this.add = res.data.desc2;
          this.checked = res.data.status == 1 ? true : false; //1默认 2否
        },
      });
    },

    choseAddress() {
      this.show = !this.show;
    },

    save() {
      if (!this.name) {
        this.$refs.btn.disabled = false;
        return this.$toast("请输入收货人姓名")
      }
      if (!this.phone) {
        this.$refs.btn.disabled = false;
        return this.$toast("请输入收货人手机号")
      }
      if (!this.area_id) {
        this.$refs.btn.disabled = false;
        return this.$toast("请选择地区")
      }
      if (!this.address) {
        this.$refs.btn.disabled = false;
        return this.$toast("请输入详情地址")
      }
      this.disabled = true;
      this.$post({
        url: "/user/user_address/edit",
        data: {
          address_id: this.id,
          phone: this.phone, //收货人手机
          people: this.name, //收货人
          area_id: this.area_id, //省id
          detail: this.address, //详细地址
          status: this.checked ? 1 : 0, //2默认 1否
        },
        success: (res) => {
          this.$toast({ message: "修改地址成功" });
          setTimeout(() => {
            this.$refs.btn.disabled = false;
            this.$router.go(-1);
          }, 1000);
        },
        tip: () => {
          setTimeout(() => {

            this.$refs.btn.disabled = false;
          }, 1000);
        },
      });
    },
    //选择城市后
    confirmchose(area) {
      console.log(area);
      this.add = area[0].name + " " + area[1].name + " " + area[2].name;
      this.province_name = area[0].name;
      this.city_name = area[1].name;
      this.district_name = area[2].name;
      this.pro_id = area[0].code;
      this.city_id = area[1].code;
      this.area_id = area[2].code;
      this.show = false;
    },
  },
};
</script>

<style scoped lang="less">
.formlist {
  width: 100%;

  //单元格样式
  /deep/.van-cell {
    width: 92.53vw;
    display: block;
    box-shadow: 0vw 0vw 4.27vw 0vw rgba(96, 98, 102, 0.08);
    border-radius: 2.13vw;
    padding: 0 16px;
    margin: 3.73vw;
  }

  /deep/.van-field__label {
    font-size: 3.47vw;
    color: #303133;
    line-height: 12.8vw;
  }

  /deep/.van-field__control {
    font-size: 3.47vw;
    color: #919398;
    padding-bottom: 4vw;
  }

  .oneline {
    display: flex;
    line-height: 12.8vw;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    &:last-child {
      border: none;
    }

    .name {
      width: 30%;
      height: 9vw;
      font-size: 3.5vw;
      font-weight: 400;
      color: #303133;
    }

    /deep/.text {
      font-size: 3.5vw;
    }

    .moren {
      font-size: 3.5vw;
      font-weight: 400;
      color: #999;
    }

    input {
      flex: 1;
      font-size: 3.5vw;
      font-weight: 400;
      background: #fff;
      width: 90%;
    }

    input::-webkit-input-placeholder {
      font-size: 3.5vw;
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
    }

    img {
      width: 5.87vw;
      height: 5.87vw;
      float: right;
      margin-top: 3.5vw;
    }

    textarea {
      flex: 1;
      font-size: 3.5vw;
      font-weight: 400;
      background: #fff;
      border: none;
      line-height: 4.5vw;
    }

    textarea::-webkit-input-placeholder {
      font-size: 3.5vw;
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
    }
  }

  .block {
    display: block;
  }
}

// 保存按钮
.btn_box {
  margin-top: 12vw;
}

//城市选择器样色
/deep/ .van-picker__confirm {
  color: #333;
}

/deep/ .van-picker__cancel {
  color: #333333;
}
</style>
