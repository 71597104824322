import axios from 'axios';
import router from '../router'
import qs from 'qs';
import vant from 'vant';
import { Toast } from 'vant';
import JSONBIG from 'json-bigint';
import VConsole from 'vconsole'
import plugins from '../plugins'
import regular from '../utils/regular'
import { i18n } from '../utils/lang'
export default {
  install: Vue => {
    Vue.use(vant)
    Vue.use(plugins)
    if (process.env.VUE_APP_IS_LOG == 1) {
      const vConsole = new VConsole()
      Vue.use(vConsole)
    }

    console.log = function () { }
    Vue.prototype.$rg = regular //部分正则
    Vue.prototype.$axios = axios
    // axios.defaults.withCredentials = true
    // js返回进度丢失
    axios.defaults.transformResponse = [
      function (data) {
        const json = JSONBIG({ storeAsString: true })
        const res = json.parse(data)
        return res
      }
    ]
    axios.defaults.timeout = 30000; //请求超时
    const no_login_code = -1;       //未登录 状态码
    const success_code = 1;         //成功 状态码
    const err_code = 0;             //失败 状态码
    const token_headers = true      // true->token headers 传值
    // 'http://zhichan.php.qwangluo.net:90'
    if (process.env.NODE_ENV == 'development') setpath('http://new_zhichan.php.qwangluo.net:90')
    else if (process.env.NODE_ENV == 'production') setpath('https://api.hst.hrce.com')

    function setpath(api_path = '', web_path = api_path, ws_path = '') {
      axios.defaults.baseURL = api_path + '/api/v1/';
      Vue.prototype.httpPath = web_path;
      // Vue.prototype.wsPath = 'ws://wsnewzhichan.php.qwangluo.net:90';
      Vue.prototype.wsPath = 'wss://hst.hrce.com/wss/';
    }
    Vue.prototype.$get = params => {
      const token = Vue.prototype.local.get("token") || "";

      //多语言传参
      //   en: 'English',
      //   zh: '简体中文',
      //   hk: '繁體中文',
      const locale = localStorage.getItem(`${process.env.VUE_APP_NAME}_lang`) || 'zh'
      const lang = { en: 'en', zh: 'zh_CN', hk: 'zh_HK' }[locale]
      // console.log('lang', locale)

      let data
      params.loading && Toast.loading({
        forbidClick: true,
        duration: 0
      });
      if (token_headers) {
        data = {
          params: Object.assign({}, params.data),
          headers: {
            'authorization': `Bearer ${token}`,
            'selfLange': lang
          }
        }
      } else {
        data = {
          params: Object.assign({}, params.data, token ? { token } : '')
        }
      }
      axios.get(params.url, data).then(res => {
        params.loading && Toast.clear();
        if (res.data.code == no_login_code) {
          if (token) Vue.prototype.local.del("token");
          Toast({
            message: i18n.t('未登录'),
            onClose: () => {
              router.replace('login')
            }
          });
        } else if (res.data.code == success_code) {
          params.success && params.success(res.data);
        } else if (res.data.code == err_code) {
          if (params.tip) {
            params.tip(res.data)
            Toast(res.data.msg);
          } else if (params.tips) {
            // 无轻提示
            params.tips(res.data)
          }
        }
      }).catch(err => {
        console.log('请求失败', err.response)
        Toast(`${err.response.config.url} ${err.response.status}`);
      })
    }

    Vue.prototype.$post = params => {
      const token = Vue.prototype.local.get("token") || "";
      // console.log('===token===', token)
      //多语言传参
      //   en: 'English',
      //   zh: '简体中文',
      //   hk: '繁體中文',
      const locale = localStorage.getItem(`${process.env.VUE_APP_NAME}_lang`) || 'zh'
      const lang = { en: 'en', zh: 'zh_CN', hk: 'zh_HK' }[locale]
      // console.log('lang', locale)

      let data = {}
      let headers = {}
      headers['selfLange'] = lang
      params.loading && Toast.loading({
        forbidClick: true,
        duration: 0
      });
      if (token_headers) {
        headers['Authorization'] = `Bearer ${token}`
        if (params.upload) {
          headers['Content-Type'] = 'multipart/form-data';
          data = params.data
        } else if (params.noToken) {
          headers['Authorization'] = ``
          data = params.data  //有时候java传值方式
          // data = qs.stringify(Object.assign({}, params.data))
        } else if (params.raw) {
          headers['Content-Type'] = 'multipart/form-data';
          data = params.data
        } else {
          // data = params.data //有时候java传值方式
          data = qs.stringify(Object.assign({}, params.data))
        }
      } else {
        if (params.upload) {
          data = params.data.append('token', token ? token : "")
          headers['Content-Type'] = 'multipart/form-data'
        } else if (params.noToken) {
          data = qs.stringify(Object.assign({}, params.data))
        } else {
          data = qs.stringify(Object.assign({}, params.data, token ? { token } : {}))
        }
      }
      axios.post(params.url, data, { headers }).then(res => {
        params.loading && Toast.clear();
        if (res.data.code == no_login_code) {
          if (token) Vue.prototype.local.del("token");
          Toast({
            message: i18n.t('未登录'),
            onClose: () => {
              router.replace('login')
            }
          });
        } else if (res.data.code == success_code) {
          params.success && params.success(res.data);
        } else if (res.data.code == err_code) {
          if (params.tip) {
            params.tip(res.data)
            Toast(res.data.msg);
          } else if (params.tips) {
            // 无轻提示
            params.tips(res.data)
          }

        }
      }).catch(err => {
        console.log('请求失败', err.response)
        Toast(`${err.response.config.url} ${err.response.status}`);
      })
    }

    Vue.prototype.$put = params => {
      const token = Vue.prototype.local.get("token") || "";
      const locale = localStorage.getItem(`${process.env.VUE_APP_NAME}_lang`) || 'zh'
      const lang = { en: 'en', zh: 'zh_CN', hk: 'zh_HK' }[locale]
      // console.log('lang', locale)

      let data = {}
      let headers = {}
      headers['selfLange'] = lang
      params.loading && Toast.loading({
        forbidClick: true,
        duration: 0
      });
      if (token_headers) {
        headers['Authorization'] = `Bearer ${token}`
        if (params.noToken) {
          headers['Authorization'] = ``
          data = params.data  //有时候java传值方式
          // data = qs.stringify(Object.assign({}, params.data))
        } else if (params.raw) {
          headers['Content-Type'] = 'multipart/form-data';
          data = params.data
        } else {
          // data = params.data //有时候java传值方式
          data = qs.stringify(Object.assign({}, params.data))
        }
      } else {
        if (params.upload) {
          data = params.data.append('token', token ? token : "")
          headers['Content-Type'] = 'multipart/form-data'
        } else if (params.noToken) {
          data = qs.stringify(Object.assign({}, params.data))
        } else {
          data = qs.stringify(Object.assign({}, params.data, token ? { token } : {}))
        }
      }
      axios.put(params.url, data, { headers }).then(res => {
        params.loading && Toast.clear();
        if (res.data.code == no_login_code) {
          if (token) Vue.prototype.local.del("token");
          Toast({
            message: '未登录',
            onClose: () => {
              router.replace('login')
            }
          });
        } else if (res.data.code == success_code) {
          params.success && params.success(res.data);
        } else if (res.data.code == err_code) {
          if (params.tip) {
            params.tip(res.data)
            Toast(res.data.msg);
          } else if (params.tips) {
            // 无轻提示
            params.tips(res.data)
          }
        }
      }).catch(err => {
        console.log('请求失败', err.response)
        Toast(`${err.response.config.url} ${err.response.status}`);
      })
    }
  }
}