<template>
  <div-box>
    <div-padding padding="3vw">
      <div-text size="4vw" center color="#000000">登录</div-text>
    </div-padding>
    <div-padding padding="0 10vw">
      <!-- 切换注册 -->
      <!-- <div-right>
        <div-card bg="#ffecea" radius="0">
          <div-padding padding="1vw 5vw">
            <div-text color="#D05843" size="4vw" bold>注册</div-text>
          </div-padding>
        </div-card>
      </div-right> -->
      <div-height height="17vw"></div-height>
      <!-- 标题 -->
      <div-text size="7vw" color="#D05843" bold>华数通数字中心</div-text>
      <div-text size="4vw" color="#333333">Huashutong Digital Center</div-text>
      <div-height height="23vw"></div-height>

      <div-card>
        <div-padding padding="3vw 0">
          <van-field clearable :border="false" v-model="phone" maxlength="11" type="tel" :placeholder="$t('请输入您的手机号')">
            <template #label>
              <div-text size="4vw" color="#A1A1A1">手机号</div-text>
            </template>
          </van-field>
        </div-padding>
        <van-divider />
      </div-card>

      <div-height height="7vw"></div-height>
      <form>
        <div-card>
          <div-padding padding="3vw 0">
            <van-field clearable autocomplete v-model="password" :readonly="!phone"
              :type="['text', 'password'][pass_type * 1]" :placeholder="$t('请输入您的登录密码')"
              :right-icon="pass_type ? 'closed-eye' : 'eye'" @click-right-icon="pass_type = !pass_type">
              <template #label>
                <div-text size="4vw" color="#A1A1A1">密码</div-text>
              </template>
            </van-field>
          </div-padding>
          <van-divider />
        </div-card>
      </form>
      <div-height height="13vw"></div-height>
      <!-- 按钮 -->
      <div-btn ref="btn" bg="#EF834C" radius="2vw" @fun="save">
        <div-text size="4vw" color="#FFF">登录</div-text>
      </div-btn>
      <div-height height="4vw"></div-height>

      <div-card>
        <div-padding padding="1vw 3vw">
          <div-both>
            <div-text size="4vw" color="#A1A1A1" @click.native="router('password')">忘记密码？</div-text>
            <div-text color="#D05843" size="4vw" bold @click.native="router('register')">新用户注册</div-text>
          </div-both>
        </div-padding>
      </div-card>

      <div-height height="43vw"></div-height>
      <div-center br>
        <van-checkbox icon-size="4vw" checked-color="#D05843" v-model="checked"></van-checkbox>
        <div-width width="2vw"></div-width>
        <div-text size="4vw" @click.native="checked = !checked" color="#666">同意</div-text>
        <div-text size="4vw" color="#3E3E3E" @click.native="router('agreement', 1)">《用户服务协议》</div-text>
        <div-text size="4vw" color="#3E3E3E">和</div-text>
        <div-text size="4vw" color="#3E3E3E" @click.native="router('agreement', 2)">《隐私政策》</div-text>
      </div-center>
      <div style="height: 5vw"></div>
    </div-padding>
  </div-box>
</template>
<script>
export default {
  data() {
    return {
      phone: "",
      password: "",
      pass_type: true,
      disabled: false,
      checked: false,
    }
  },
  methods: {
    save() {
      if (!this.phone) {
        this.$refs.btn.disabled = false
        return this.$toast(this.$t("请输入手机号"))
      }
      if (!this.password) {
        this.$refs.btn.disabled = false
        return this.$toast(this.$t("请输入登录密码"))
      }
      if (!this.checked) {
        this.$refs.btn.disabled = false
        return this.$toast(
          this.$t("请仔细阅读并勾选《用户协议》和《隐私政策》")
        )
      }

      this.$toast.loading({
        message: this.$t("验证中..."),
        forbidClick: true,
        duration: 0,
      })
      this.$post({
        url: "/login",
        noToken: true,
        data: {
          phone: this.phone,
          password: this.password,
        },
        success: (res) => {
          this.local.set("token", res.data.access_token)
          this.$toast(this.$t("登录成功"))
          this.$router.replace({
            name: "/",
          })
        },
        tip: () => {
          this.$refs.btn.disabled = false
        },
      })
    }
  },
}
</script>
