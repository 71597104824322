<template>

  <div-box foot top="0" bg="#F8F8F8">
    <div-navber title="提交订单"></div-navber>

    <div-padding padding="4vw">
      <div-card bg="#fff" radius="2vw">
        <div-padding padding="4vw">
          <van-cell class="site_box" is-link>
            <template #title>
              <div class="addresbox">
                <div class="img">
                  <img src="~@/assets/img/good_order11.png" alt="" />
                </div>
                <div class="content" v-if="address" @click="router('address')">
                  <span class="tit">{{ address.people }}</span>
                  <span class="num">{{ address.phone }}</span>
                  <p class="list">{{ address.desc }}</p>
                </div>
                <div class="content" v-else @click="router('address')">
                  <span class="num">请先<i>设置默认收货地址</i></span>
                </div>
              </div>
            </template>
          </van-cell>
        </div-padding>
      </div-card>
    </div-padding>

    <div-height size="3vw"></div-height>

    <div-padding padding="0 4vw 4vw 4vw">
      <div class="center">
        <img :src="httpPath + info.cover" class="shangpin" />
        <div class="right">
          <p class="name two">{{ info.title }}</p>
          <div class="down">
            <p class="price"><span>￥</span>{{ info.price }}</p>
            <p class="num">x{{ goods_number }}</p>
          </div>
        </div>
      </div>
    </div-padding>

    <div-height size="3vw"></div-height>

    <div-padding padding="0 4vw 4vw 4vw">
      <div-card bg="#fff" radius="2vw">
        <div-padding padding="4vw">
          <van-field style="align-items: baseline;" v-model="remark" rows="2" autosize label="备注：" type="textarea"
            maxlength="250" placeholder="请输入备注信息" show-word-limit />
        </div-padding>
      </div-card>
    </div-padding>



    <div-height size="20vw"></div-height>
    <div>
      <van-submit-bar label="合计：" :price="info.price * 100 * goods_number" :loading="loading" button-text="提交"
        @submit="save">
      </van-submit-bar>
    </div>
  </div-box>
</template>

<script>
export default {
  data() {
    return {
      goods_id: "",
      info: {},
      address: {},
      goods_number: "",
      remark: "",
      type: 1, //1消费额，2盈利宝，3支付宝，4微信
      password: "",
      loading: false,
      site: "",
    };
  },
  created() {
    this.setUrlData()
    this.get_info();
    this.get_address();
  },
  mounted() {

  },
  methods: {

    get_info() {
      this.$get({
        url: "/goods/detail",
        data: { id: this.goods_id },
        success: (res) => {
          this.info = res.data;
        },
        tip: (val) => { },
      });
    },

    get_address() {
      this.$post({
        url: "/user/user_address/get_def",
        success: (res) => {
          this.address = res.data;
        },
        tip: () => { },
      });
    },
    //生成订单
    save() {
      if (!this.address) {
        return this.$toast("请选择收货地址")
      }
      this.loading = true;
      this.$post({
        url: "/goods_order/submit",
        data: {
          goods_id: this.goods_id,
          num: this.goods_number,
          address_id: this.address.id,
          remark: this.remark,
        },
        success: (res) => {
          setTimeout(() => {
            this.loading = false;
          }, 1500);
          this.router("order_pay", { order_sn: res.data.order_sn, price: this.info.price * this.goods_number, time: res.data.plan_cancel_at });
        },
        tip: () => {
          setTimeout(() => {
            this.loading = false;
          }, 1500);
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
//大盒子
.index {
  width: 100vw;
  min-height: 100vh;
  height: auto;
  box-sizing: border-box;
  background: #fff;



  //顶部背景图
  .header {
    width: 100%;


  }

  //产品样式
  .orderbox {
    width: 100%;
    padding: 20px 30px;
    box-sizing: border-box;

    /deep/.van-card {
      width: 100%;
      background: #ffffff;
      padding: 0;

      .van-card__thumb {
        width: 170px;
        height: 170px;
        margin-right: 20px;
      }

      .van-card__content {
        flex: 1;

        .van-card__title {
          width: 100%;
          font-size: 30px;
          font-weight: 550;
          color: #000000;
          line-height: 1.5;
          max-height: 12vw !important;
        }

        .van-card__bottom {
          .van-card__price {
            color: #fe7729;
            font-size: 28px;
            font-weight: 550;
          }

          .van-card__num {
            color: #999999;
            font-size: 24px;
          }
        }
      }
    }
  }

  /deep/.van-cell-group {
    .van-cell {
      width: 100%;
      padding: 20px;

      .van-cell__left-icon {
        font-size: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
      }

      .van-cell__title {
        color: #333333;
        font-size: 28px;
      }
    }
  }


}


//地址部分
.site_box {
  width: 100%;
  padding: 3vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .addresbox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: top;

    .img {
      width: 5vw;
      height: 5vw;

      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    .content {
      flex: 1;
      padding: 0 3vw;
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;

      .tit {
        font-size: 4.8vw;
        color: #333333;
        font-weight: bold;
        margin-right: 3vw;
      }

      .num {
        font-size: 4vw;
        color: #919398;

        line-height: 7.6vw;

        i {
          font-style: normal;
          color: #f54a4a;
        }
      }

      .list {
        width: 100%;
        font-size: 3.5vw;
        color: #999999;
        line-height: 6.6vw;
      }
    }
  }
}



//底部提交订单样式
/deep/.van-submit-bar {
  width: 100%;

  .van-submit-bar__bar {
    padding: 0 0 0 3vw;

    .van-submit-bar__text {
      color: #000000;
      font-size: 4vw;

      .van-submit-bar__price {
        font-size: 4vw;
        font-weight: 550;
      }
    }

    .van-button {
      height: 100%;
      border-radius: 0;
      background: linear-gradient(270deg, #ea3501 0%, #fe7729 100%);
    }
  }
}



.center {
  width: 100%;
  padding: 4vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;

  .shangpin {
    width: 23vw;
    height: 23vw;
    border-radius: 2vw;
    margin-right: 2vw;
    flex-shrink: 0;
  }

  .right {
    width: 100%;
    min-height: 23vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .name {
      width: 100%;
      font-size: 4vw;
      font-weight: 550;
      line-height: 1.6;
      color: #000000;
    }

    .down {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .price {
        color: #fe7729;
        font-size: 4vw;
        font-weight: 550;

        span {
          font-size: 4vw;
        }
      }

      .num {
        font-size: 4vw;
        color: #999999;
      }
    }
  }
}
</style>
