<template>
  <div-box>
    <nav-bar :title="$t('充值')" left-arrow fixed :bg-color="$c('#fff')" :right-text="$t('充值记录')" :leftColor="$c('#333')"
      :titleColor="$c('#333')" :rightColor="$c('#333')" @click-right="router('deawal')" />
    <div-height size="3vw"></div-height>
    <div-padding padding="4vw">
      <div-card :bg="$c('linear-gradient(99deg, #EF834C 0%, #F77564 100%)')" radius="2vw">
        <div-padding padding="8vw 6vw">
          <div-text color="#FFF">可用余额(元)</div-text>
          <div-height height="3vw"></div-height>
          <div-text size="10vw" color="#FFF">{{ num }}</div-text>
        </div-padding>
      </div-card>

      <div-padding padding="4vw 0 ">
        <div-both>
          <div-text>充值金额</div-text>
        </div-both>
      </div-padding>
      <div-card :bg="$c('#F7F7F7')" radius="2vw">
        <div-padding padding="3vw">
          <van-field clearable v-model="price" type="digit" :placeholder="$t('请输入您的充值金额')">
            <template #label>
              <div-text size="4vw" color="#EF834C">￥</div-text>
            </template>
          </van-field>
        </div-padding>
        <van-divider />
      </div-card>
      <div-padding padding="4vw 0 ">
        <div-text>选择充值方式</div-text>
      </div-padding>
      <van-radio-group v-model="radio">
        <div-padding padding="0vw 0">
          <!-- <div-card>
            <div-padding padding="4vw 0">
              <van-cell clickable>
                <template #title>
                  <div-left center>
                    <div-img width="5vw" height="5vw" :src="require('@/assets/img/zfb.png')"></div-img>
                    <div-width width="2vw"></div-width>
                    <div-text>支付宝</div-text>
                  </div-left>
                </template>
                <template #right-icon>
                  <van-radio name="1" checked-color="#EF834C"></van-radio>
                </template>
              </van-cell>
            </div-padding>
            <van-divider />
          </div-card>

          <div-card>
            <div-padding padding="4vw 0">
              <van-cell clickable>
                <template #title>
                  <div-left center>
                    <div-img width="5vw" height="5vw" :src="require('@/assets/img/weixi.png')"></div-img>
                    <div-width width="2vw"></div-width>
                    <div-text>微信</div-text>
                  </div-left>
                </template>
                <template #right-icon>
                  <van-radio name="2" checked-color="#EF834C"></van-radio>
                </template>
              </van-cell>
            </div-padding>
            <van-divider />
          </div-card> -->

          <div-card>
            <div-padding padding="4vw 0">
              <van-cell clickable>
                <template #title>
                  <div-left center>
                    <div-img width="5vw" height="5vw" :src="require('@/assets/img/card.png')"></div-img>
                    <div-width width="2vw"></div-width>
                    <div-text>银行卡</div-text>
                  </div-left>
                </template>
                <template #right-icon>
                  <van-radio name="3" checked-color="#EF834C"></van-radio>
                </template>
              </van-cell>
            </div-padding>
            <van-divider />
          </div-card>
        </div-padding>
      </van-radio-group>
      <div-card>
        <div-padding padding="4vw 0">
          <van-cell :border="false" center class="up_img_box">
            <template #title>
              <div-text>上传凭证</div-text>
            </template>
            <template #label>
              <div-center>
                <uploader :after-read="afterRead">
                  <van-image :src="$img(qrcode)" fit="contain" v-if="qrcode"></van-image>
                  <div class="up_img" v-else>
                    <div class="add">+</div>
                  </div>
                </uploader>
              </div-center>
            </template>
          </van-cell>
        </div-padding>
        <van-divider />
      </div-card>

      <div-btn ref="btn" radius="2vw" @fun="save">
        <div-text color="#FFF">申请充值</div-text>
      </div-btn>
    </div-padding>
  </div-box>
</template>
<script>
export default {
  data() {
    return {
      radio: "3",
      price: "",
      num: "",
      qrcode: "",
    }
  },
  created() {
    this.get_info()
  },
  methods: {
    get_info() {
      this.$post({
        url: "/user_profile",
        success: (res) => {
          this.num = res.data.money
        },
        tip: () => { },
      })
    },
    afterRead(file) {
      // 上传图片
      let formData = new FormData()
      formData.append("file", file.file)
      this.$post({
        url: "/upload",
        data: formData,
        upload: true,
        success: (res) => {
          this.qrcode = res.data
        },
      })
    },

    save() {
      if (!this.price) {
        this.$refs.btn.disabled = false
        return this.$toast("请输入充值金额")
      }
      if (!this.qrcode) {
        this.$refs.btn.disabled = false
        this.$toast(this.$t("请上传支付凭证"))
        return
      }
      this.$post({
        url: "/recharge/submit",
        data: {
          type: this.radio,
          money: this.price,
          img: this.qrcode,
        },
        success: (res) => {
          this.$toast(res.msg)
          setTimeout(() => {
            this.$refs.btn.disabled = false
            this.goBack()
          }, 1500)
        },
        tip: () => {
          setTimeout(() => {
            this.$refs.btn.disabled = false
          }, 1500)
        },
      })
    },
  },
}
</script>
<style lang='less' scoped>
.up_img_box {
  background: #fff;
  opacity: 1;
  border-radius: 3vw;
  font-size: 4vw;
  color: #000000;
  padding: 0vw;
  margin-bottom: 4vw;

  .van-cell__label {
    margin-top: 2vw;
  }

  .img {
    display: flex;
    justify-content: center;
    opacity: 1;
    border-radius: 3vw;
    overflow: hidden;
  }

  .up_img {
    width: 30vw;
    height: 30vw;
    position: relative;
    overflow: hidden;

    .add {
      line-height: 30vw;
      text-align: center;
      color: #cdcdcd;
      font-size: 12vw;
    }

    &::before {
      content: "";
      width: 5vw;
      left: -5vw;
      height: 0.5vw;
      display: block;
      position: absolute;
      border-radius: 1vw;
      box-shadow: 5vw 0vw #cdcdcd, 12vw 0vw #cdcdcd, 19vw 0vw #cdcdcd,
        26vw 0vw #cdcdcd, 33vw 0vw #cdcdcd, 40vw 0vw #cdcdcd, 5vw 36.8vw #cdcdcd,
        12vw 36.8vw #cdcdcd, 19vw 36.8vw #cdcdcd, 26vw 36.8vw #cdcdcd,
        33vw 36.8vw #cdcdcd, 40vw 36.8vw #cdcdcd;
      transform: scale(0.8);
    }

    &::after {
      content: "";
      width: 0.5vw;
      left: -5vw;
      top: -5vw;
      height: 5vw;
      display: block;
      position: absolute;
      border-radius: 1vw;
      box-shadow: 6.2vw 5vw #cdcdcd, 6.2vw 12vw #cdcdcd, 6.2vw 19vw #cdcdcd,
        6.2vw 26vw #cdcdcd, 6.2vw 33vw #cdcdcd, 6.2vw 40vw #cdcdcd,
        43vw 5vw #cdcdcd, 43vw 12vw #cdcdcd, 43vw 19vw #cdcdcd,
        43vw 26vw #cdcdcd, 43vw 33vw #cdcdcd, 43vw 40vw #cdcdcd;
      transform: scale(0.8);
    }
  }

  .van-image {
    background: #fff;
    min-height: 40vw;
    min-width: 40vw;
    display: block;

    /deep/.van-icon {
      font-size: 14vw;
    }
  }
}
</style>
