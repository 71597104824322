<template>
  <div class="div-btn">
    <div class="btn_box">
      <button @click="do_fun" class="anBtn" :class="{ auto }" :disabled="disabled" :style="'background: ' +
        bg +
        ';color:' +
        color +
        ';border-radius:' +
        radius +
        ';border:' +
        border +
        ';height:' +
        height +
        ';line-height:' +
        height +
        ';'
        ">
        <slot></slot>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "div-btn",
  props: {
    auto: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "#fff",
    },
    bg: {
      type: String,
      default: "#EF834C",
    },
    radius: {
      type: String,
      default: "12vw",
    },
    height: {
      type: String,
      default: "12vw",
    },
    border: {
      type: String,
      default: "",
    },
    fun: {
      type: Function,
      default: () => {
        return Function
      },
    },
  },
  data() {
    return {
      disabled: false,
    }
  },
  created: function () { },
  methods: {
    do_fun() {
      if (this.disabled) {
        return false
      }
      this.disabled = true
      console.log("内部调用了", this.fun)
      // this.fun()
      this.$emit("fun")
    },
  },
}
</script>
<style lang="less" scoped>
.div-btn {
  .auto {
    width: 100%;
    margin: 0 auto;
  }

  .btn_box {
    box-sizing: border-box;
    text-align: center;
  }

  .anBtn {
    padding: 0;
    color: #fff;
    font-size: 4vw;
    transform: scale(1);
    transition: all 0.25s;
  }

  .anBtn:active {
    transform: scale(0.9);
    opacity: 0.9;
  }

  .anBtn::after {
    border: 0;
  }

  .anBtn[disabled] {
    transform: scale(1);
    opacity: 1;
  }

  .anBtn[disabled]:active {
    transform: scale(0.9);
    opacity: 0.5;
  }

  .anBtn[disabled]:not([type]) {
    /* transform: scale(0.9); */
    opacity: 0.5;
  }
}
</style>
