<template>
  <div-box>
    <div-padding padding="3vw">
      <div-text size="4vw" center color="#000000">登录</div-text>
    </div-padding>
    <div-padding padding="0 10vw">
      <!-- 标题 -->
      <!-- <div-text size="6vw" color="#131529" bold>⽤户注册</div-text> -->
      <div-height height="7vw"></div-height>
      <div-card>
        <div-padding padding="3vw 0">
          <van-field clearable v-model="phone" type="tell" maxlength="11" :placeholder="$t('请输入您的手机号')">
            <template #label>
              <div-text size="4vw" color="#A1A1A1">⼿机号</div-text>
            </template>
          </van-field>
        </div-padding>
        <van-divider />
      </div-card>
      <div-height height="5vw" />
      <div-card>
        <div-padding padding="3vw 0">
          <van-field clearable v-model="code" type="text" :placeholder="$t('请输入您的验证码')">
            <template #label>
              <div-text size="4vw" color="#A1A1A1">验证码</div-text>
            </template>
            <template #button>
              <send-code plain api="/sendsms" :send-tell="{ phone: phone }" :send-scene="{ scene: 'register' }"
                :no-token="true" color="#D05843" round>
              </send-code>
            </template>
          </van-field>
        </div-padding>
        <van-divider />
      </div-card>
      <div-height height="5vw" />
      <div-card>
        <div-padding padding="3vw 0">
          <van-field autocomplete clearable v-model="institution" :placeholder="$t('请输入机构码')">
            <template #label>
              <div-text size="4vw" color="#A1A1A1">机构码</div-text>
            </template>
          </van-field>
        </div-padding>
        <van-divider />
      </div-card>
      <div-height height="5vw" />
      <div-card>
        <div-padding padding="3vw 0">
          <van-field autocomplete clearable v-model="pass_01" :type="['text', 'password'][pass_type1 * 1]"
            :readonly="!phone" :placeholder="$t('请确认6～12位数字+字母密码')" :right-icon="pass_type1 ? 'closed-eye' : 'eye'"
            @click-right-icon="pass_type1 = !pass_type1">
            <template #label>
              <div-text size="4vw" color="#A1A1A1">密码</div-text>
            </template>
          </van-field>
        </div-padding>
        <van-divider />
      </div-card>
      <div-height height="5vw" />
      <div-card>
        <div-padding padding="3vw 0">
          <van-field autocomplete clearable v-model="pass_02" :type="['text', 'password'][pass_type2 * 1]"
            :readonly="!phone" :placeholder="$t('请确认6～12位数字+字母密码')" :right-icon="pass_type2 ? 'closed-eye' : 'eye'"
            @click-right-icon="pass_type2 = !pass_type2">
            <template #label>
              <div-text size="4vw" color="#A1A1A1">重复密码</div-text>
            </template>
          </van-field>
        </div-padding>
        <van-divider />
      </div-card>
      <div-height height="5vw" />
      <div-card>
        <div-padding padding="3vw 0">
          <van-field autocomplete clearable maxlength="6" v-model="pass_03" :type="['text', 'password'][pass_type3 * 1]"
            :readonly="!phone" :placeholder="$t('请输入二级密码(6位纯数字)')" :right-icon="pass_type3 ? 'closed-eye' : 'eye'"
            @click-right-icon="pass_type3 = !pass_type3">
            <template #label>
              <div-text size="4vw" color="#A1A1A1">交易密码</div-text>
            </template>
          </van-field>
        </div-padding>
        <van-divider />
      </div-card>
      <div-height height="5vw" />
      <div-card>
        <div-padding padding="3vw 0">
          <van-field autocomplete clearable maxlength="6" v-model="pass_04" :type="['text', 'password'][pass_type4 * 1]"
            :readonly="!phone" :placeholder="$t('请确认二级密码(6位纯数字)')" :right-icon="pass_type4 ? 'closed-eye' : 'eye'"
            @click-right-icon="pass_type4 = !pass_type4">
            <template #label>
              <div-text size="4vw" color="#A1A1A1">重复密码</div-text>
            </template>
          </van-field>
        </div-padding>
        <van-divider />
      </div-card>
      <div-height height="5vw" />
      <div-card>
        <div-padding padding="3vw 0">
          <van-field autocomplete clearable maxlength="6" v-model="referral_code" type="text" readonly
            placeholder="请输入邀请码">
            <template #label>
              <div-text size="4vw" color="#A1A1A1">邀请码</div-text>
            </template>
          </van-field>
        </div-padding>
        <van-divider />
      </div-card>
      <!-- <div-height height="5vw" />
      <form>
        <div-card>
          <div-padding padding="3vw 0">
            <van-field autocomplete clearable maxlength="6" v-model="pass_05" type="text" :readonly="!phone" placeholder="请输入邀请码">
              <template #label>
                <div-text size="4vw" color="#A1A1A1">邀请码</div-text>
              </template>
            </van-field>
          </div-padding>
          <van-divider />
        </div-card>
      </form> -->

      <div style="height: 12vw"></div>

      <div-btn ref="btn" radius="2vw" @fun="save">
        <div-text size="4vw" color="#FFF">注册</div-text>
      </div-btn>

      <div style="height: 4vw"></div>

      <div-center>
        <div-card @click.native="router('login')">
          <div-padding padding="1vw 3vw">
            <div-text size="4vw" color="#A1A1A1">返回登录</div-text>
          </div-padding>
        </div-card>
      </div-center>

      <div-height height="13vw"></div-height>
      <div-center br>
        <van-checkbox icon-size="4vw" checked-color="#EF834C" v-model="checked"></van-checkbox>
        <div-width width="2vw"></div-width>
        <div-text size="4vw" @click.native="checked = !checked" color="#666">同意</div-text>
        <div-text size="4vw" color="#3E3E3E" @click.native="router('agreement', 1)">《用户服务协议》</div-text>
        <div-text size="4vw" color="#3E3E3E">和</div-text>
        <div-text size="4vw" color="#3E3E3E" @click.native="router('agreement', 2)">《隐私政策》</div-text>
      </div-center>
      <div style="height: 5vw"></div>
    </div-padding>
  </div-box>
</template>
<script>
export default {
  data() {
    return {
      phone: "", //手机号
      pass_01: "", //密码
      pass_02: "",
      pass_03: "", //交易密码
      pass_04: "",
      // pass_05: "",

      pass_type1: true,
      pass_type2: true,
      pass_type3: true,
      pass_type4: true,

      code: "", //验证码
      institution: '',
      referral_code: "", //推荐码
      readonly: false,
      checked: false,
    }
  },
  created() {
    //从url上面获取邀请码
    if (this.$route.query.invite) {
      this.institution = this.$route.query.business_code
      this.referral_code = this.$route.query.invite
      this.readonly = true
    }
  },

  methods: {
    // 注册
    save() {
      if (!this.phone) {
        this.$refs.btn.disabled = false
        return this.$toast(this.$t("请输入您的手机号"))
      }
      if (!this.code) {
        this.$refs.btn.disabled = false
        return this.$toast(this.$t("请输入您的验证码"))
      }
      if (!this.institution) {
        this.$refs.btn.disabled = false
        return this.$toast(this.$t("请输入机构码"))
      }
      if (!this.pass_01) {
        this.$refs.btn.disabled = false
        return this.$toast(this.$t("请输入6～12位数字+字母密码"))
      }
      if (!this.pass_02) {
        this.$refs.btn.disabled = false
        return this.$toast(this.$t("请确认6～12位数字+字母密码"))
      }
      if (!this.pass_03) {
        this.$refs.btn.disabled = false
        return this.$toast(this.$t("请输入二级密码(6位纯数字)"))
      }
      if (!this.pass_04) {
        this.$refs.btn.disabled = false
        return this.$toast(this.$t("请确认二级密码(6位纯数字)"))
      }
      if (!this.referral_code) {
        this.$refs.btn.disabled = false
        return this.$toast("请输入邀请码")
      }

      if (!this.checked) {
        this.$refs.btn.disabled = false
        return this.$toast(
          this.$t("请仔细阅读并勾选《用户协议》和《隐私政策》")
        )
      }

      this.$toast.loading({
        message: this.$t("验证中..."),
        forbidClick: true,
        duration: 0,
      })

      this.$post({
        url: "/register",
        noToken: true,
        data: {
          phone: this.phone, //手机号
          sms_code: this.code, //验证码
          business_code: this.institution,
          password: this.pass_01, //密码
          password_confirmation: this.pass_02, //确认密码
          second_password: this.pass_03, //确认交易密码
          second_password_confirmation: this.pass_04, //交易密码
          invite_code: this.referral_code, //推荐码
        },
        success: (res) => {
          this.local.set("token", res.data.access_token)

          setTimeout(() => {
            //关闭loading
            this.$toast.clear()
            this.$router.replace("login")
          }, 1500)
        },
        tip: () => {
          setTimeout(() => {
            this.$refs.btn.disabled = false
          }, 1500)
        },
      })
    },
  },
}
</script>



<style lang='less' scoped></style>
