import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
import { Locale } from 'vant'
import zhCN from 'vant/lib/locale/lang/zh-CN'; // 中文简体; 
import enUS from 'vant/lib/locale/lang/en-US';//  英文; 
import zhHK from 'vant/lib/locale/lang/zh-HK';// 中文繁体(香港);
import enLocale from './en'
import zhLocale from './zh'
import hkLocale from './hk'
const messages = {
  en: {
    ...enUS,
    ...enLocale
  },
  zh: {
    ...zhCN,
    ...zhLocale
  },
  hk: {
    ...zhHK,
    ...hkLocale
  }
}

const i18n = new VueI18n({
  locale: localStorage.getItem(`${process.env.VUE_APP_NAME}_lang`) || 'zh',//默认中文 l('lang') || 'zh',
  messages,
  silentTranslationWarn: true
})
// 更新vant组件库本身的语言变化，支持国际化
function vantLocales(lang) {
  localStorage.setItem(`${process.env.VUE_APP_NAME}_lang`, lang)
  if (lang == 'en') {
    Locale.use(lang, enUS)
  }
  if (lang == 'zh') {
    Locale.use(lang, zhCN)
  }
  if (lang == 'hk') {
    Locale.use(lang, zhHK)
  }
}

export { i18n, vantLocales }