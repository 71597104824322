<template>
  <div-box>
    <!-- <van-nav-bar title="地址管理" right-text="添加地址" left-arrow fixed :border="false" @click-left="back" @click-right="go('add_addres')" /> -->
    <div-navber title="地址管理"></div-navber>

    <!-- 封装上拉下拉组件 坐标 vant-extend 文件 -->
    <vant-up-down ref="child" @list="(val) => (list = val)" res="res.data.data" url="/user/user_address" data="">
      <div class="list">
        <div class="oneaddress" v-for="item in list" :key="item.id">
          <div class="info van-hairline--bottom" @click="opt(item.id)">
            <div class="line">
              <div class="number">
                {{ item.status == 1 ? "默认" : "" }}
              </div>
              <div class="name">{{ item.people }}</div>
              <span>{{ item.phone }}</span>
            </div>
            <div class="detail">
              {{ item.desc }}
            </div>
          </div>
          <div class="foot">
            <div class="edit" @click="router('edit_addres', item.id)">编辑</div>
            <div class="del" @click="del(item.id)">删除</div>
          </div>
        </div>
      </div>
    </vant-up-down>

    <div-foot>
      <div-padding padding="3vw">
        <div-btn ref="btn" @click.native="router('add_addres')">新增地址</div-btn>
      </div-padding>
    </div-foot>
  </div-box>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    setTimeout(() => {
      this.$refs.child.list_init();
    });
  },
  methods: {
    del(id) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定要删除此地址吗?",
          confirmButtonColor: "#333",
        })
        .then(() => {
          this.$post({
            url: "/user/user_address/delete",
            data: { address_id: id },
            success: (res) => {
              this.$toast({
                message: "删除成功！",
                duration: 1000,
              });
              this.$refs.child.list_init();
            },
            tip: (val) => { },
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    opt(id) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定要选择此地址吗?",
          confirmButtonColor: "#333",
        })
        .then(() => {
          this.$post({
            url: "/user/user_address/set_def",
            data: { address_id: id },
            success: (res) => {
              //返回上一页
              this.$router.go(-1);
            },
            tip: (val) => { },
          });
        })
        .catch(() => {
          // on cancel
        });
    }
  },
};
</script>

<style scoped lang="less">
//地址列表样式
.list {
  width: 100%;

  .oneaddress {
    width: 92.53vw;
    box-shadow: 0vw 1.33vw 2.67vw 0vw rgba(0, 0, 0, 0.1);
    border-radius: 2.67vw;
    margin: 3.73vw auto;

    .foot {
      border-radius: 0 0 2vw 2vw;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 2vw 4vw;

      .edit {
        font-size: 2.67vw;
        color: #fff;
        border-radius: 2vw 0 0 2vw;

        padding: 1vw 4vw;
        background: #ff5e00;
      }

      .del {
        font-size: 2.67vw;
        color: #fff;
        border-radius: 0 2vw 2vw 0;
        padding: 1vw 4vw;
        background: linear-gradient(to right, #ff6034, #ee0a24);
      }
    }

    .info {
      flex: 1;
      padding: 0.5vw 4.28vw;

      .line {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 2.13vw 0;

        .name {
          font-size: 4vw;
          font-weight: bold;
          color: #333;
          padding-right: 3vw;
        }

        .number {
          position: absolute;
          border-radius: 0 0 2vw 2vw;
          padding: 0 2vw;
          background: #ff5e00;
          right: 2vw;
          top: 0vw;
          font-size: 3.2vw;
          font-weight: 400;
          color: #fff;
        }

        span {
          font-size: 3.5vw;
          font-weight: 400;
          color: #666;
        }
      }

      .detail {
        font-size: 3.5vw;
        font-weight: 400;
        color: #333;
        // padding-bottom: 4.35vw;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        margin-bottom: 2vw;
      }
    }
  }
}
</style>
