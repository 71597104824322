import { render, staticRenderFns } from "./div-avatar.vue?vue&type=template&id=12ea6cd6&scoped=true"
import script from "./div-avatar.vue?vue&type=script&lang=js"
export * from "./div-avatar.vue?vue&type=script&lang=js"
import style0 from "./div-avatar.vue?vue&type=style&index=0&id=12ea6cd6&prod&lang=less&scoped=scoped"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12ea6cd6",
  null
  
)

export default component.exports