<template>
  <div class="div-width" :style="'width:'+width" :class="{over}">
    <slot></slot>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    width: {
      type: String,
      default: '',
    },

    over: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  created() {},

  methods: {},
}
</script>
<style lang='less' scoped>
.div-width {
  flex-shrink: 0;
}
.over {
  overflow: hidden;
}
</style>
