<template>
  <div-box>
    <div-navber :title="title" />
    <div-padding padding="3vw">
      <div-html :html="list"></div-html>
    </div-padding>
  </div-box>
</template>
<script>
export default {
  data() {
    return {
      list: "",
      title: "",
    }
  },
  created() {
    this.setUrlData()
    this.getinfo()
  },
  methods: {
    getinfo() {
      let url = ""
      if (this.id == 1) {
        this.title = "用户协议"
        url = "/getProtocol"
      }
      if (this.id == 2) {
        this.title = "隐私政策"
        url = "/getPolicy"
      }
      this.$get({
        loading: true,
        url: url,
        success: (res) => {
          this.list = res.data
        },
        tip: () => {},
      })
    },
  },
}
</script>
<style scoped lang="less">
</style>
