<template>
    <div-box>
        <nav-bar left-arrow :title="$t('分类')" fixed :bgColor="$c('#fff')" :leftColor="$c('#333')"
            :titleColor="$c('#333')" />
        <div-padding padding="3vw">
            <!-- 搜索 -->
            <div class="search" @click="go('search')">
                <div-card bg="#F8F8F8" radius="5vw">
                    <div-both center>
                        <div-padding padding="2vw 4vw">
                            <div-left center>
                                <div-img height="4vw" width="4vw">
                                    <img :src="require('@/assets/img/sousuo_icon.png')" />
                                </div-img>
                                <div-width width="4vw" />
                                <div-text color="#B7B6B9" size="4vw">请输入商品名称</div-text>
                            </div-left>
                        </div-padding>
                        <div-card bg="#EF834C" radius="5vw">
                            <div-padding padding="2vw 4vw">
                                <div-text color="#fff" size="4vw">搜索</div-text>
                            </div-padding>
                        </div-card>
                    </div-both>
                </div-card>
            </div>
        </div-padding>

        <van-tree-select height="86vh" @click-nav="get_id(active)" :items="items" text="name"
            :main-active-index.sync="active">
            <template #content>
                <div-padding padding="3vw">
                    <div-left center br>
                        <div class="item" @click="router('search', { id: item.id })" v-for="(item, index) in list"
                            :key="index">
                            <div-center>
                                <div-img width="22vw" height="22vw">
                                    <img :src="httpPath + item.img" />
                                </div-img>
                            </div-center>
                            <div-height height="2vw" />
                            <div-text color="#333" size="3.5vw" center>{{ item.name }}</div-text>
                        </div>

                    </div-left>
                </div-padding>
            </template>
        </van-tree-select>
    </div-box>
</template>

<script>
export default {
    data() {
        return {
            active: 0,
            items: [],
            list: []
        };
    },
    created() {
        this.get_classify()
        //路由获取到分类id 并且请求数据
        if (this.$route.query.type) {

            this.active = this.$route.query.type
            this.get_classify()
        }
    },
    methods: {
        get_classify() {
            this.$get({
                url: "/goods_cate/oneLists",
                data: {},
                success: (res) => {
                    //把res.data里面的键名name改成text 
                    let arr = res.data
                    arr.forEach(item => {
                        item.text = item.name
                    });
                    this.items = arr
                    this.get_classify2()
                },
                tip: () => { },
            });
        },
        get_id(active) {
            this.active = active
            this.get_classify2()
        },
        get_classify2() {
            this.$post({
                url: "/goods_cate/getTwoLists",
                data: {
                    cate_id: this.items[this.active].id
                },
                success: (res) => {
                    this.list = res.data
                },
                tip: () => { },
            });
        }
    },
    mounted() {
        // Code to run when the component is mounted
    },
};
</script>

<style scoped lang='less'>
::v-deep .van-tree-select__nav {
    width: 22vw;
    flex: none;
}

::v-deep.van-sidebar-item--select::before {
    background-color: #EF834C;
}

.item {
    margin: 0 3vw 4vw 0;
}
</style>