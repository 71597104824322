<template>
  <div id="app" v-cloak :class="theme">
    <div class="wx_page" v-if="weixin">
      <img src="@/assets/img/wx_page.png" />
    </div>
    <transition :name="transitionName">
      <router-view class="router" v-if="show" />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'app',
  data() {
    return {
      show: true,
      transitionName: "slide-right",
      weixin: false,
    }
  },
  created() {
    //判断是否是微信
    let ua = navigator.userAgent.toLowerCase()
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      this.weixin = true
    } else {
      this.weixin = false
    }
  },
  methods: {},
  watch: {
    $route(to, from) {
      let isBack = this.$router.isBack
      if (isBack) {
        this.transitionName = "slide-right"
      } else {
        this.transitionName = "slide-left"
      }
      this.$router.isBack = false
    },
  },
}
</script>

<style lang="less">
//白昼模式
.light {
  background: #fff;

  .van-cell {
    background: #fff;
  }
}

//暗黑模式
.dark {
  background: #111;

  .van-cell {
    background: #111;
  }

  .van-dialog {
    background: #333;

    .van-dialog__footer {
      .van-button {
        background: #333;
      }
    }
  }
}

.wx_page {
  position: fixed;
  z-index: 999999;
  width: 100%;
}

.wx_page img {
  width: 100%;
}

/* //加载中 */
.van-toast--loading {
  box-shadow: 0px 1vw 3vw rgba(0, 0, 0, 0.16);
  background: #fff !important;
  color: #333;
}

.van-toast--loading .van-toast__loading {
  color: #EF834C !important;
}

.van-toast--loading .van-toast__text {
  color: #333;
}

/* //成功后 */
.van-toast.van-toast--success {
  box-shadow: 0px 1vw 3vw rgba(0, 0, 0, 0.16);
  color: #333;
  background-color: #fff !important;
}

.van-toast.van-toast--success i {
  color: #EF834C !important;
}

/* //页面滑动动画 */
.router-view {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  height: 100%;
  will-change: transform;
  transition: all 500ms;
  position: absolute;
  backface-visibility: hidden;
  perspective: 1000;
}

.slide-right-enter,
.slide-right-enter-active {
  animation: bounce-left-in 500ms;
}

.slide-right-leave-active {
  animation: bounce-left-out 500ms;
}

.slide-left-enter,
.slide-left-enter-active {
  animation: bounce-right-in 500ms;
}

.slide-left-leave-active {
  animation: bounce-right-out 500ms;
}

@keyframes bounce-right-in {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0px, 0, 0);
    opacity: 1;
  }
}

@keyframes bounce-right-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }
}

@keyframes bounce-left-in {
  0% {
    transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0px, 0, 0);
    opacity: 1;
  }
}

@keyframes bounce-left-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}

[v-cloak] {
  display: none;
}
</style>