<template>
  <div class="div-padding" :style="'background: ' +
    bg +
    ';padding:' + padding">
    <slot></slot>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    padding: {
      type: String,
      default: '0 3vw',
    },
    bg: {
      type: String,
      default: "",
    },
  },
}
</script>
<style lang='less' scoped>
.div-padding {
  box-sizing: border-box;
}
</style>
