<template>
  <div-box>
    <nav-bar :title="$t('提现')" left-arrow fixed :bg-color="$c('#fff')" :right-text="$t('提现记录')" :leftColor="$c('#333')"
      :titleColor="$c('#333')" :rightColor="$c('#333')" @click-right="router('record_bus')" />
    <div-height size="3vw"></div-height>
    <div-padding padding="4vw">
      <div-card :bg="$c('linear-gradient(99deg, #EF834C 0%, #F77564 100%)')" radius="2vw">
        <div-padding padding="8vw 6vw">
          <div-text color="#FFF">可用机构余额(元)</div-text>
          <div-height height="3vw"></div-height>
          <div-text size="10vw" color="#FFF">{{ business_money }}</div-text>
        </div-padding>
      </div-card>

      <div-padding padding="4vw 0 ">
        <div-both>
          <div-text>提款金额</div-text>
          <div-text @click.native="price = business_money">全部</div-text>
        </div-both>
      </div-padding>
      <div-card :bg="$c('#F7F7F7')" radius="2vw">
        <div-padding padding="3vw">
          <van-field clearable v-model="price" type="digit" :placeholder="$t('请输入您的提现金额')">
            <template #label>
              <div-text size="4vw" color="#EF834C">￥</div-text>
            </template>
          </van-field>
        </div-padding>
        <van-divider />
      </div-card>
      <div-padding padding="4vw 0 ">
        <div-text>选择提现方式</div-text>
      </div-padding>
      <van-radio-group v-model="radio">
        <div-padding padding="4vw 0">
          <div-card>
            <div-padding padding="4vw 0">
              <van-cell clickable>
                <template #title>
                  <div-left center>
                    <div-img width="5vw" height="5vw" :src="require('@/assets/img/card.png')"></div-img>
                    <div-width width="2vw"></div-width>
                    <div-text>银行卡</div-text>
                  </div-left>
                </template>
                <template #right-icon>
                  <van-radio name="3" checked-color="#EF834C"></van-radio>
                </template>
              </van-cell>
            </div-padding>
            <van-divider />
          </div-card>
        </div-padding>
      </van-radio-group>
      <div-height height="13vw"></div-height>

      <div-btn ref="btn" radius="2vw" @fun="save">
        <div-text color="#FFF">申请提现</div-text>
      </div-btn>
    </div-padding>
  </div-box>
</template>
<script>
export default {
  data() {
    return {
      radio: "3",
      price: "",
      business_money: "",
    }
  },
  created() {
    this.get_info()
  },
  methods: {
    get_info() {
      this.$post({
        url: "/user_profile",
        success: (res) => {
          this.business_money = res.data.business_money
        },
        tip: () => { },
      })
    },
    save() {
      this.$post({
        url: "/with_drawal/submit_bus",
        data: {
          type: this.radio,
          money: this.price,
        },
        success: (res) => {
          this.info = res.data
          this.$toast(res.msg)
          setTimeout(() => {
            this.goBack()
            this.$refs.btn.disabled = false
          }, 1500)
        },
        tip: () => {
          setTimeout(() => {
            this.$refs.btn.disabled = false
          }, 1500)
        },
      })
    },
  },
}
</script>
<style lang='less' scoped></style>
