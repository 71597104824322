import { render, staticRenderFns } from "./wallet.vue?vue&type=template&id=33d66cca&scoped=true"
import script from "./wallet.vue?vue&type=script&lang=js"
export * from "./wallet.vue?vue&type=script&lang=js"
import style0 from "./wallet.vue?vue&type=style&index=0&id=33d66cca&prod&lang=less&scoped=scoped"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33d66cca",
  null
  
)

export default component.exports