<template>
  <div-box>
    <div-navber title="套餐"></div-navber>
    <vant-up-down ref="list" @list="(val) => (list = val)" res="res.data.data" url="/product/financial"
      :data="{ product_id: id }">
      <div-padding padding="4vw">
        <div-card :bg="$c('#ffffff')" radius="2vw" v-for="item in list" :key="item.id">
          <div-padding padding="3vw">
            <div-text size="4vw" color="#000000" bold>{{ item.name }}</div-text>
            <div-both>
              <div class="item">
                <div-text size="8vw" color="#DF6361" bold>{{ item.rate }}%</div-text>
                <div-text size="3vw" color="#969696">收益率</div-text>
              </div>
              <span></span>
              <div class="item">
                <div-text class="tit" size="6vw" color="#000000" bold>{{ item.cycle }}天</div-text>
                <div-text size="3vw" color="#969696">周期</div-text>
              </div>
              <van-button class="btn" @click="changeItem(item)">{{ $t('立即购买') }}</van-button>
            </div-both>
            <div class="bot">
              <div>
                <div-text size="3vw" color="#A39080" bold>价格:</div-text>
                <span>{{ item.price }}</span>
                <div-text size="3vw" color="#A39080" bold>元</div-text>
              </div>
              <div>
                <div-text size="3vw" color="#A39080" bold>到期收益:</div-text>
                <span>{{ item.income }}</span>
                <div-text size="3vw" color="#A39080" bold>元</div-text>
              </div>
            </div>
          </div-padding>
        </div-card>
      </div-padding>
    </vant-up-down>

    <van-dialog v-model="show" :beforeClose="beforeClose" show-cancel-button :confirmButtonColor="$c('#000000')"
      :cancelButtonColor="$c('#000000')">
      <div-card>
        <div-padding padding="6vw 6vw 0 6vw">
          <div-text size="5vw" color="#000000" bold>买入</div-text>
          <!-- <div-both>
            <div-padding padding="3vw 0">
              <div-text size="5vw" color="#EF834C" bold>5655</div-text>
              <div-text size="3vw" color="#696F7F">单价</div-text>
            </div-padding>
            <div-padding padding="3vw 0">
              <div-text size="5vw" color="#000000" bold>3%</div-text>
              <div-text size="3vw" color="#696F7F">利率</div-text>
            </div-padding>
            <div-padding padding="3vw 0">
              <div-both class="num">
                60
                <div-text size="3vw" color="#000000" bold>天</div-text>
              </div-both>
              <div-text>时间</div-text>
            </div-padding>
          </div-both> -->
          <div-height height="4vw" />
          <!-- <div-card :bg="$c('#F8F8F8')" radius="1vw">
            <div-padding padding="3vw 4vw">
              <div-both>
                <div-left center>
                  <div-img width="4vw" height="4vw">
                    <img src="~@/assets/img/icon02.png" v-if="theme == 'light'" />
                    <img src="~@/assets/img/icon02-.png" v-else />
                  </div-img>
                  <div-width width="3vw"></div-width>
                  <div-text>数量</div-text>
                </div-left>
                <van-stepper style="display: flex" @change="onChange" :min="info.min_num" v-model="num" theme="round"
                  button-size="22" integer />
              </div-both>
            </div-padding>
          </div-card>
          <div-card :bg="$c('#F8F8F8')" radius="1vw">
            <div-padding padding="3vw 4vw">
              <div-both>
                <div-left center>
                  <div-img width="4vw" height="4vw">
                    <img src="~@/assets/img/icon_6@2x.png" v-if="theme == 'light'" />
                    <img src="~@/assets/img/icon_6.png" v-else />
                  </div-img>
                  <div-width width="3vw"></div-width>
                  <div-text>合计</div-text>
                </div-left>
              </div-both>
            </div-padding>
          </div-card> -->
          <div-card :bg="$c('#F8F8F8')" radius="1vw">
            <div-padding padding="3vw 4vw">
              <van-field clearable :border="false" v-model="password" type="password" max-length="6"
                :placeholder="$t('请输入交易密码')">
                <template #label>
                  <div-img width="4vw" height="4vw">
                    <img src="~@/assets/img/icon03.png" v-if="theme == 'light'" />
                    <img src="~@/assets/img/icon03-.png" v-else />
                  </div-img>
                </template>
              </van-field>
            </div-padding>
          </div-card>
          <van-divider />
        </div-padding>
      </div-card>
    </van-dialog>
  </div-box>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.query.product_id,
      info: {},
      list: [],
      num: '',
      financial_id: '',
      password: '',
      show: false,
    }
  },
  created() {
    this.vant_list()
  },
  methods: {
    onChange(value) {
      if (value <= this.info.min_num) {
        this.num = this.info.min_num
      } else {
        this.num = value
      }
    },
    changeItem(data) {
      this.financial_id = data.id
      this.show = true
    },
    beforeClose(action, done) {
      if (action === "confirm") {
        if (!this.password) {
          this.$toast(this.$t('请输入交易密码'))
          done(false)
          return
        }
        this.$post({
          url: "/product/financial/buy",
          data: {
            financial_id: this.financial_id,
            password: this.password,
          },
          success: (res) => {
            done()
            this.password = ''
            this.financial_id = ''
            this.$toast(res.msg)
          },
          tip: () => {
            done()
          },
        })
      } else {
        done()
      }
    },
  },
}
</script>

<style scoped lang="less">
// 明亮
.light {
  background: #F9FAFB !important;
}

//暗黑
.dark {}

/deep/.div-card {
  margin-bottom: 4vw;
}

/deep/.div-both {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .item {
    width: 35%;

    .tit {
      margin-bottom: 6px;
    }
  }

  >span {
    width: 1px;
    height: 11vw;
    background: #EDEDED;
  }

  .btn {
    width: 25%;
    height: 8vw;
    text-align: center;
    line-height: 8vw;
    background: linear-gradient(125deg, #EF834C 0%, #E0635D 100%);
    border-radius: 50px;

    .van-button__text {
      color: #fff;
      font-size: 12px;
    }
  }
}

.bot {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 3vw 0 1vw;

  >div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #A39080;
    font-size: 3vw;
    background: #EFEFEF;
    border-radius: 1px;
    margin-right: 10px;
    padding: 0 15px;
    font-weight: 550;
  }
}

.num {
  display: flex;
  justify-content: flex-end !important;
  align-items: center !important;
  color: #000000;
  font-size: 5vw;
  font-weight: 550;
}
</style>
