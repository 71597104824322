<template>
  <div-box bg="#F9FAFB">
    <div-navber title="我的交易"> </div-navber>

    <div-height height="12vw">
      <van-tabs v-model="active" @click="opt" title-active-color="#000" color="#EF834C">
        <van-tab>
          <template #title>
            <div-text class="#333">我的买入</div-text>
          </template>
        </van-tab>
        <van-tab>
          <template #title>
            <div-text class="#333">我的卖出</div-text>
          </template>
        </van-tab>
      </van-tabs>
    </div-height>

    <div-height height="3vw"></div-height>
    <vant-up-down ref="list" @list="(val) => (list = val)" res="res.data.data" url="/product_order/item_order_lists"
      :data="{ type: active * 1 + 1 }">
      <div-padding padding="0 4vw">
        <div class="list">
          <div class="item" v-for="item in list" :key="item.id">
            <div>
              <div-card :bg="$c('#ffffff')" radius="1vw" shadow="0px 1vw 2vw 0px rgba(0,0,0,0.02)">
                <div-padding padding="4vw">
                  <div-both center>
                    <div>
                      <div-left center>
                        <div-text color="#999" size="3.5vw">知产名称</div-text>
                        <div-text color="#999" size="3.5vw">：</div-text>
                        <div-text color="#333" size="3.5vw">{{ item.product.name }}</div-text>
                      </div-left>
                      <div-height height="3vw"></div-height>
                      <div-left>
                        <div-text color="#333" size="3.5vw">{{ item.created_at }}</div-text>
                      </div-left>
                    </div>
                  </div-both>
                </div-padding>
                <van-divider />

                <div-padding padding="4vw">
                  <div-both>
                    <div>
                      <div-text center bold>{{ item.price }}</div-text>
                      <div-text color="#666666" center>单价</div-text>
                    </div>
                    <div>
                      <div-text center bold>{{ item.num }}</div-text>
                      <div-text color="#666666" center>数量</div-text>
                    </div>
                    <div>
                      <div-text center bold>{{ item.total_price }}</div-text>
                      <div-text color="#666666" center>价格</div-text>
                    </div>
                  </div-both>
                </div-padding>
              </div-card>
              <div-height height="3vw"></div-height>
            </div>
          </div>
        </div>
      </div-padding>
    </vant-up-down>
  </div-box>
</template>
<script>
export default {
  data() {
    return {
      active: 0,
      list: [],
    }
  },
  created() {
    // 滚动到顶部
    this.vant_list()
  },
  methods: {
    opt() {
      this.vant_list()
    },
  },
}
</script>
<style lang="scss" scoped></style>