<template>
  <div-box>
    <div-navber title="机构余额变动明细" />
    <div-height height="4vw"></div-height>
    <div-padding padding="0 4vw">
      <vant-up-down ref="list" @list="(val) => (list = val)" res="res.data.data" url="/user/money_record"
        :data="{ credit_type: 2 }">
        <div-card :bg="$c('#ffffff')" radius="2vw" shadow="0px 1vw 2vw 0px rgba(0,0,0,0.16)">

          <div class="item" v-for="(item, index) in list" :key="index">
            <div-padding padding="3vw 4vw">
              <div-both center>
                <div-left center>
                  <div>
                    <div-left center>
                      <div-text color="#333">{{ item.type_desc }}</div-text>
                      <div-width width="1vw"></div-width>

                    </div-left>
                    <div-height height="2vw"></div-height>
                    <div-text color="#8A8A8A">{{ item.created_at }}</div-text>
                  </div>
                </div-left>
                <div-right center>
                  <div-text color="#EF834C" bold v-if="item.type == 1">+{{ item.num }}</div-text>
                  <div-text color="#39AD44" bold v-if="item.type == 2">-{{ item.num }}</div-text>

                </div-right>
              </div-both>
            </div-padding>
          </div>
        </div-card>

        <div-height height="4vw"></div-height>
      </vant-up-down>
    </div-padding>
  </div-box>
</template>

<script>
export default {
  data() {
    return {
      list: [], //
    }
  },
  created() {
    this.vant_list()
  },
  methods: {},
}
</script>

<style lang="less" scoped="scoped">
// 明亮
.light {
  .gradient {}
}

//暗黑
.dark {
  .gradient {}
}
</style>
