<template>
  <van-uploader v-model="list" :accept="accept" :previewSize="previewSize" :previewFullImage="previewFullImage" :previewOptions="previewOptions" :multiple="multiple" :disabled="disabled"
    :readonly="readonly" :deletable="deletable" :showUpload="showUpload" :lazyLoad="lazyLoad" :capture="capture" :maxSize="maxSize" :maxCount="maxCount" :resultType="resultType"
    :uploadText="uploadText" :imageFit="imageFit" :before-read="beforeRead" :after-read="afterRead" :before-delete="beforeDelete">
    <slot />
  </van-uploader>
</template>
<script>
// https://vant-contrib.gitee.io/vant/v2/#/zh-CN/uploader#api
export default {
  props: [
    "fileList",
    "accept",
    "previewSize",
    "previewFullImage",
    "previewOptions",
    "multiple",
    "disabled",
    "readonly",
    "deletable",
    "showUpload",
    "lazyLoad",
    "capture",
    "afterRead",
    "beforeRead",
    "beforeDelete",
    "maxSize",
    "maxCount",
    "resultType",
    "uploadText",
    "imageFit",
    "uploadIcon",
  ],
  computed: {
    list: {
      get: function () {
        return this.fileList
      },
      set: function () { },
    },
  },
}
</script>
