<template>
  <div-box>
    <div-navber title="申购列表"></div-navber>
    <div-padding padding="0 4vw">
      <vant-up-down ref="list" @list="(val) => (list = val)" res="res.data.data" url="/product/apply_lists">
        <div class="item" v-for="(item, index) in list" :key="index">
          <div-card :bg="$c('#ffffff')" radius="2vw" shadow="0px 1vw 2vw 0px rgba(0,0,0,0.16)">
            <div-padding padding="4vw">
              <div-both center>
                <div-text size="4vw" color="#000000" bold>
                  {{ item.code }}{{ item.name }}
                </div-text>
                <div-width width="15vw">
                  <div-btn :ref="`btn_${item.id}`" @fun="add(`btn_${item.id}`, item)" radius="12vw" height="7vw"
                    bg="#D05843" color="#fff">
                    <div-text size="3vw" color="#FFF">申购</div-text>
                  </div-btn>
                </div-width>
              </div-both>
            </div-padding>
            <div-padding padding="0 4vw 4vw 4vw">
              <div-both center>
                <div>
                  <div-both center>
                    <div-width width="40vw">
                      <div-left>
                        <div-text size="3.5vw" color="#999999">最小申购份额</div-text>
                        <div-width width="3vw"></div-width>
                        <div-text size="3.5vw" color="#D05843">{{ item.apply_min_num }}</div-text>
                      </div-left>
                    </div-width>
                    <div-left>
                      <div-text size="3.5vw" color="#999999">招股价</div-text>
                      <div-width width="3vw"></div-width>
                      <div-text size="3.5vw" color="#D05843">{{ item.apply_price }}</div-text>
                    </div-left>
                  </div-both>
                  <div-height height="3vw"></div-height>
                  <div-img v-if="item.status == 2" height="8vw" width="80vw"
                    :src="require('@/assets/img/jianbianjuxing@2x.png')">
                    <div-left center>
                      <div-width width="2vw"></div-width>
                      <div-img width="3vw" height="3vw">
                        <img src="~@/assets/img/sucai1@2x.png" alt="">
                      </div-img>
                      <div-width width="1vw"></div-width>
                      <div-text size="3.5vw" color="#999999">申购截止日期</div-text>
                      <div-width width="3vw"></div-width>
                      <div-text size="3.5vw" color="#000000">{{ item.apply_end_at.split(" ")[0] }}</div-text>

                      <div-text size="3.5vw" color="#000000">剩余</div-text>
                      <div-text size="3.5vw" color="#EF834C">{{ item.surplus_hour }}</div-text>
                      <div-text size="3.5vw" color="#000000">小时</div-text>
                    </div-left>
                  </div-img>
                </div>
              </div-both>
            </div-padding>
          </div-card>
          <div-height height="4vw" />
        </div>
      </vant-up-down>
    </div-padding>
    <van-dialog v-model="show" :beforeClose="beforeClose" show-cancel-button :confirmButtonColor="$c('#000000')"
      :cancelButtonColor="$c('#000000')">
      <div-card>
        <div-padding padding="6vw 6vw 0 6vw">
          <div-text size="5vw" bold>申购</div-text>
          <div-height height="4vw" />
          <div-card :bg="$c('#F8F8F8')" radius="1vw">
            <div-padding padding="3vw 4vw">
              <div-both>
                <div-left center>
                  <div-img width="4vw" height="4vw">
                    <img src="~@/assets/img/icon01.png" v-if="theme == 'light'" />
                    <img src="~@/assets/img/icon01-.png" v-else />
                  </div-img>
                  <div-width width="3vw"></div-width>
                  <div-text>价格</div-text>
                </div-left>
                <van-stepper style="display: flex" disabled v-model="price" theme="round" button-size="22"
                  disable-input />
              </div-both>
            </div-padding>
          </div-card>
          <div-height height="4vw" />
          <div-card :bg="$c('#F8F8F8')" radius="1vw">
            <div-padding padding="3vw 4vw">
              <div-both>
                <div-left center>
                  <div-img width="4vw" height="4vw">
                    <img src="~@/assets/img/icon02.png" v-if="theme == 'light'" />
                    <img src="~@/assets/img/icon02-.png" v-else />
                  </div-img>
                  <div-width width="3vw"></div-width>
                  <div-text>数量</div-text>
                </div-left>
                <van-stepper style="display: flex" :min="min" v-model="num" theme="round" button-size="22"
                  disable-input />
              </div-both>
            </div-padding>
          </div-card>

          <div-height height="4vw" />

          <div-card :bg="$c('#F8F8F8')" radius="1vw">
            <div-padding padding="3vw 4vw">
              <van-field clearable :border="false" v-model="password" type="password" :placeholder="$t('请输入交易密码')">
                <template #label>
                  <div-img width="4vw" height="4vw">
                    <img src="~@/assets/img/icon03.png" v-if="theme == 'light'" />
                    <img src="~@/assets/img/icon03-.png" v-else />
                  </div-img>
                </template>
              </van-field>
            </div-padding>
          </div-card>
          <div-height height="4vw" />
          <van-divider />
        </div-padding>
      </div-card>
    </van-dialog>
  </div-box>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      price: "", //价格
      num: "", //数量
      min: "",
      id: "",
      img: "",
      password: "",
      show: false,
    }
  },
  created() {
    // 滚动到顶部
    this.vant_list()
  },
  methods: {
    add(btn, item) {
      this.show = true
      this.min = item.apply_min_num
      this.id = item.id
      this.price = item.apply_price
      this.$refs[btn][0].disabled = false
    },
    beforeClose(action, done) {
      if (action === "confirm") {
        this.$post({
          url: "/apply_order/submit",
          data: {
            product_id: this.id,
            num: this.num,
            password: this.password,
          },
          success: (res) => {
            this.$toast(res.msg)
            setTimeout(done, 1000)
          },
          tip: () => {
            setTimeout(done, 1000)
          },
        })
      } else {
        done()
      }
    },
  },
}
</script>

<style scoped lang='less'>
.radius_box {
  padding: 0.5vw 2vw;
  min-width: 12vw;
  background: linear-gradient(93deg, #ffa55b 0%, #ff7637 100%);
  border-radius: 26vw;
}
</style>
