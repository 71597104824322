
export default {
    install(Vue, option) {
        //定义主题
        Vue.prototype.theme = localStorage.getItem('theme') || 'light'
        if (Vue.prototype.theme == 'light') {
            document.querySelector('body').removeAttribute('style')
        } else {
            document.querySelector('body').setAttribute('style', 'background-color:#1C1C1C')
        }
        Vue.prototype.opt_theme = function () {
            Vue.prototype.theme == 'light' ? Vue.prototype.theme = 'dark' : Vue.prototype.theme = 'light'
            if (Vue.prototype.theme == 'light') {
                document.querySelector('body').removeAttribute('style')
            } else {
                document.querySelector('body').setAttribute('style', 'background-color:#1C1C1C')
            }

            localStorage.setItem('theme', Vue.prototype.theme)
            console.log('切换主题')
        }
        //配置主题色
        Vue.prototype.$c = function (color) {
            //主题色对象
            let theme_obk = {
                '#fff': '#1C1C1C',
                '#ffffff': '#242424',
                '#333': '#fff',
                '#999': '#fff',
                '#131529': '#fff',
                '#131526': '#fff',
                '#ddd': '#fff',
                '#666': '#fff',
                '#A1A1A1': '#fff',
                '#F1F2F6': '#969799',
                '#3E3E3E': '#fff',
                '#F8F8F8': '#282828',
                '#2C2C2C': '#fff',
                '#8A8A8A': '#fff',
                '#c1c0c5': '#fff',
                '#000000': '#fff',
                '#666666': '#fff',
                '#19211D': '#fff',
                '#F7F7F7': '#242424',
                '#F9FAFB': '#1C1C1C',
                '#636363': '#fff',
                '#866C6C': '#ffb2b2',
                '#5B687E': '#d2e3ff',
                '#FDEFEF': '#3d2e2e',
                '#FEF8F8': '#794848',
                '#EAF7EE': '#24432d',
                '#F7FCF8': '#34623d',

            }
            let theme_keys = Object.keys(theme_obk);
            if (theme_keys.includes(color)) {
                if (this.theme == 'light') {
                    return color
                }
                if (this.theme == 'dark') {
                    return theme_obk[color]
                }
            } else {
                return color
            }

        }
    }
}