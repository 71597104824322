module.exports = {
  '未登录': 'Not logged in',
  '昨天': 'yesterday',
  '登录失败': 'login failed',
  'k': 'k-line',
  'open': 'open',
  'close': 'close',
  'low': 'low',
  'hig': 'high',
  '高': 'High',
  '开': 'Open',
  '分时': 'Intraday',
  '日线': '1D',
  '周线': '1W',
  '月线': '1M',
  '年线': '1Y',
  '1分': '1M',
  '5分': '5M',
  '15分': '15M',
  '30分': '30M',
  '小时': '1H',

  '银行名称': 'Bank name',
  '收益列表': 'Profit list',
  '交易金额': 'Transaction amount',
  '收益金额': 'Profit amount',
  '商标数字存证中心': 'Trademark Digital Certificate Center',
  '注册': 'Registration',
  '理财第⼀选择': 'Financial Choice',
  '密码': 'Password',
  '登录': 'Login',
  '忘记密码': ' Forgot your password',
  '请输入您的登录密码': 'Please enter your login password',
  '《用户服务协议》': '《User Service Agreement》',
  '和': 'and',
  '《隐私政策》': 'Privacy Policy',

  '首页': 'Home',
  '知产资讯': 'information',
  '更多': 'More',
  '官方推荐': 'recommendation',
  '自选': 'Optional',
  '精选': 'Featured',
  '名称代码': 'Name',
  '成交价': 'Price',
  '开盘价': 'Open',
  '收盘价': 'Close',
  '成交量': 'Volume',
  '申购': 'Purchase',
  '招股价': 'IPO price',
  '申购': 'Purchase',
  '最小申购金额': 'Minimum',
  '最小申购份额': 'Minimum',
  '申购截止日期': 'Deadline',
  '剩余': 'surplus',
  '小时': 'H',
  '官方发布': "Official release",
  '市场': 'Market',
  '行情': 'Quotation',
  '券商信息': 'Broker Information',
  '详情': 'Details',
  '请输入交易密码': 'Please enter transaction password',
  '资讯详情': 'Information Details',
  '资讯列表': 'Information List',
  '申购列表': 'Subscription list',
  '公告列表': 'Announcement list',
  '公告详情': 'Announcement Details',
  '交易': 'Trade',

  '群聊': 'Group',
  '创建群聊': 'Create group',
  '消息通知': 'notification',
  '搜索群名': 'Search group',
  '搜索': 'Search',
  '申请加入': 'Apply',
  '通知': 'Notification',
  '拒绝': 'Ref',
  '同意': 'Agree',
  '已同意': 'Agreed',
  '已拒绝': 'Rejected',

  '我的': 'Mine',
  '已实名': 'Already real name',
  '钱包余额': 'Wallet balance',
  '立即查看': 'View Now',
  '系统功能': 'System function',
  '我的订单': 'orders',
  '我的持股': 'sharehold',
  '我的知产': 'property',
  '邀请好友': 'friends',
  '其他功能': 'Other functions',
  '新手指南': 'Guide',
  '帮助中心': 'Help',
  '联系客服': 'service',
  '切换语言': 'switch language',
  '选择语言': 'switch language',
  '系统设置': 'Settings',

  '个人资料': 'Profile',
  '头像': 'Avatar',
  '昵称': 'Nickname',
  '手机号': 'Mobile',
  '实名信息': 'Real name',
  '个人简介': 'Profile',
  '注册时间': 'Registration time',

  '修改昵称': 'Modify nickname',
  '请输入您的昵称': 'Enter nickname',
  '保存': 'Preservation',
  '实名认证': 'Real name authentication',
  '身份证': 'ID card',
  '姓名': 'Name',
  '请输入您的手机号': 'Please enter your mobile ',
  '请输入您的身份证': 'Please enter your ID card',
  '请输入个人简介': 'Please enter a personal profile',
  '请输入您的姓名': 'Please enter your name',
  '修改成功': 'Modified successfully',

  '我的交易': 'My transaction',
  '买入委托': 'Buying entrustment',
  '卖出委托': 'Sales entrustment',
  '订单号': 'No',
  '已报': 'Reported',
  '已成': 'Finished',
  '撤单': 'Cancellation',
  '委托数量': 'Entrusted',
  '知产名称': 'Property',
  '撤单数量': 'Cancellation',
  '成交数量': 'Transaction',
  '委托价格': 'Price',
  '我的买入': 'My Buy',
  '我的卖出': 'My selling',
  '单价': 'Unit price',
  '数量': 'Quantity',
  '价格': 'Price',
  '确权': 'Confirm',
  '锁仓': 'Lock',

  '买入知产': 'Purchase property',
  '申购知产': 'Subscription property',
  '名称代码': 'Name',
  '均价/数量': 'Average/quantity',
  '市值/盈亏': 'Market/profit',
  '申购价格': 'Purchase price',
  '申购数量': 'Subscription Qty',
  '邀请码': 'Invitation code',
  '请截图保存分享给好友': 'save the screenshot and share',
  '您好！': 'Hello!',
  '欢迎来到帮助中心！': 'Welcome to the Help Center!',
  '客服': 'service',

  '收款方式': 'Collection method',
  '机构码': 'Institution code',
  '修改登录密码': 'Modify login password',
  '修改支付密码': 'Modify payment password',

  '银行卡': 'Bank card',
  '微信': 'WeChat',
  '支付宝': 'Alipay',
  '未绑定': 'Unbound',

  '开启夜间模式': 'Turn on night mode',
  '关闭夜间模式': 'Turn off night mode',

  '⼿机号': 'Mobile',
  '验证码': 'code',
  '重复密码': 'Repeat password',
  '交易密码': 'Transaction password',
  '确认密码': 'Confirm password',
  '发送验证码': 'Send code',
  '请输入您的验证码': 'enter your code',
  '请输入6～12位数字+字母密码': 'enter a 6-12 digit + letter password',
  '请输入二级密码(6位纯数字)': 'enter the secondary password (6 digits)',
  '请确认6～12位数字+字母密码': 'confirm the 6-12 digit + letter password',
  '请确认二级密码(6位纯数字)': 'confirm the secondary password (6 digits)',
  '请仔细阅读并勾选《用户协议》和《隐私政策》': 'Please read carefully and check the "User Agreement" and "Privacy Policy"',

  '⽤户注册': 'User registration',
  '返回登录': 'Return to login',

  '请输入手机号': 'enter your mobile number',
  '请输入正确格式的手机号': 'enter the correct format of mobile number',
  '加载中...': 'Loading...',
  '发送成功': 'Send successfully',
  '验证中...': 'Verification...',
  '请输入登录密码': 'enter your login password',

  '请输入验证码': 'enter your code',
  '请确认登录密码': 'confirm your login password',

  '确定': 'confirm',
  '已绑定': 'bound',

  '银行卡号': 'card number',
  '开户行': 'Bank',
  '绑定银行卡': 'Bind bank card',
  '请输入持卡人姓名': 'enter the cardholder name',
  '请输入银行卡卡号': 'enter the card number',
  '请输入银行名称': 'enter the bank name',


  '绑定微信': 'Bind WeChat',
  '请输入微信账号': 'enter your WeChat account',
  '请确认微信账号': 'confirm your WeChat account',
  '微信账号': 'WeChat account',
  '重复账号': 'Repeat account',
  '微信收款码': 'WeChat payment code',
  '请上传微信收款码': 'upload your WeChat payment code',
  '两次账号不一致': 'The two accounts are inconsistent',




  '绑定支付宝': 'Bind Alipay',
  '支付宝账号': 'Alipay account',
  '请输入支付宝账号': 'enter your Alipay account',
  '请确认支付宝账号': 'confirm your Alipay account',
  '支付宝收款码': 'Alipay payment code',
  '请上传支付宝收款码': 'upload your Alipay payment code',

  '未认证': 'Unauthenticated',


  '请选择您要联系的客服': 'Please select the customer service you want to contact',
  '我们根据选择为您提供服务': 'We provide services according to your choice',
  '电话客服': 'Telephone service',
  '一键拨打': 'dialing',
  '微信客服': 'WeChat service',
  '一键复制': 'copy',

  '常见问题': 'Common problems',
  '用户': 'User',
  '这个家伙很懒,什么都没有写': 'This guy is lazy. He does write anything',
  '已绑定机构码': 'Institution code bound',
  '绑定机构': 'Binding',
  '我的机构码': 'My Institution code',
  '我是机构': 'Im a broker',
  '请输入机构码': 'enter your institution code',
  '填写机构码': 'Fill in the institution code',
  '解绑': 'Unbind',
  '退出登录': 'Log out',
  '立即绑定': 'Bind now',
  '钱包': 'Wallet',
  '充值': 'Recharge',
  '提现': 'Withdrawal',
  '可用余额(元)': 'Available balance (yuan)',
  '余额变动明细': 'Balance details',
  '全部': 'All',

  '提现记录': 'record',
  '提款金额': 'Withdrawal amount',
  '请输入您的提现金额': 'Please enter your withdrawal amount',
  '选择提现方式': 'Select withdrawal method',
  '申请提现': 'Apply for withdrawal',

  '充值记录': 'Recharge record',
  '充值金额': 'Recharge amount',
  '请输入您的充值金额': 'Please enter your recharge amount',
  '选择充值方式': 'Select recharge method',
  '上传凭证': 'Upload voucher',
  '申请充值': 'Apply for recharging',

  '报价': 'Quotation',
  '简况': 'Profile',
  '卖出': 'Sell',
  '买入': 'Buy',
  '自选': 'Optional',
  '是否': 'Yes No',
  '取消': 'Cancel',
  '限价模式': 'Price Limit Mode',
  '价格范围': 'Price range',
  '之间': 'Between',
  '买盘': 'Buy',
  '卖盘': 'Selling',
  '最高': 'Highest',
  '今开': 'Today',
  '最低': 'Lowest',
  '昨收': 'Yesterday',
  '换手率': 'Turnover',
  '委比': 'Commis',
  '成交额': 'Turnover',
  '公司简介': 'Company profile',
  '主席': 'Chairman',
  '上市时间': 'Listing time',
  '发行价格': 'Issue price',
  '发行数量': 'Issue quantity',
  '是否撤单': 'Cancellation',

  '创建您的群聊天': 'Create your group chat',
  '填写群名称（2-10个字）': 'Fill in group name 2-10 word',
  '群名称': 'Group name',
  '立即创建': 'Create now',
  '请输入群名称': 'Please enter the group name',
  '群头像': 'Group avatar',
  '请上传群头像': 'Please upload group avatar',
  '没有更多了': 'No more',
  '群号': 'Group number',
  '选择照片': 'Photos',
  '群聊创建成功': 'Group chat has been created successfully. Start your chat quickly',
  '[群系统通知]': '[Group notification]',

  '移出群聊': 'Kick out',
  '确定要移出群聊': 'Are you sure to move out of group chat',
  '邀请列表': 'Invitation list',
  '备注': 'Remark',
  '请输入备注': 'Please enter the remark',
  '暂无备注': 'No remark',
  '认证通过': 'Certified',
  '未认证': 'Unauthenticated',

  "机构钱包": "Institutional Wallet",
  "可用机构余额(元)": "Available institutional balance (yuan)",
  "机构余额变动明细": "Details of changes in institutional balances",
  "用户协议": "User Agreement",
  "隐私政策": "Privacy Policy",
  "注销账户": "Account cancellation",
  "注销账户后，您的账户将无法找回，确定要注销吗？": "After canceling your account, it will not be able to be retrieved. Are you sure you want to cancel it?",
  "未开始": "Not Started",
  "已结束": "Ended",
  "机构码": "Institution code",
  "请输入机构码": "Please enter the organization code",
  "购买套餐": "Purchase Package",
  "套餐": "Package",
  "收益率": "Yield",
  "周期": "cycle",
  "立即购买": "Buy Now",
  "到期收益": "yield of maturity",
  "单价": "unit price",
  "利率": "interest rate",
  "时间": "time",
  "天": "day",
  "合计": "total",
  "我的套餐": "My package",
  "投资中": "In investment",
  "投资金额": "Investment amount",
  "提前赎回": "called away ",
  "结束时间": "End time",
  "今日交易额": "Today's transaction volume",
  "知产交易": "Intellectual property transactions",
  "确认要提前取消套餐?": "Are you sure you want to cancel the package in advance?"
}
