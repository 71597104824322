<template>
  <div class='div_box' :class="theme" :style="'background-color: ' + $c(bg) + ';background-image: url(' + src + ')'">

    <!-- 页面主体 -->
    <div class="div_body">
      <slot></slot>
    </div>
    <div-height height="15vw" v-show="tabber"></div-height>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    src: {
      type: String,
      default: "",
    },
    bg: {
      type: String,
      default: "#fff",
    },

    top: {
      type: String,
      default: "12vw",
    },
    tabber: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  created() { },

  methods: {},
}
</script>
<style lang='less' scoped>
.div_body {
  width: 100%;
  height: auto;
}

.div_box {
  width: 100vw;
  min-height: 100vh;
  height: auto;
  box-sizing: border-box;
  padding: 0 !important;
  background-repeat: no-repeat !important;
  background-size: 100% !important;
}
</style>
