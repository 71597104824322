<template>
  <div-box class="gradient">
    <div-navber title="充值记录" />
    <div-height height="3vw"></div-height>
    <vant-up-down ref="list" @list="(val) => (list = val)" res="res.data.data" url="/recharge/lists">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div-padding padding="3vw 4vw">
          <div-both center>
            <div-left center>
              <div>
                <div-left center>
                  <div-text color="#333">{{ item.status_desc }}</div-text>
                  <div-text color="#333" v-if="item.remark">--{{item.remark}}</div-text>
                  <div-width width="1vw"></div-width>
                </div-left>
                <div-height height="2vw"></div-height>
                <div-text color="#8A8A8A">{{ item.created_at }}</div-text>
              </div>
            </div-left>
            <div-right center>
              <div-text color="#000000" bold v-if="item.money > 0">+</div-text>
              <div-text color="#000000" bold>{{ item.money }}</div-text>
            </div-right>
          </div-both>
        </div-padding>
      </div>
    </vant-up-down>
  </div-box>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    }
  },
  created() {
    // 滚动到顶部
    this.vant_list()
  },
  methods: {},
}
</script>

<style lang="less" scoped="scoped">
</style>
