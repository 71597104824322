<template>
  <div>
    <div-card :bg="$c('#ffffff')" radius="1vw" shadow="0px 1vw 2vw 0px rgba(0,0,0,0.02)">
      <div-padding padding="0">
        <div-img width="92vw" height="69vw" radius="2vw">
          <img :src="$img(info.company_img)" />
        </div-img>
      </div-padding>
      <div-height height="3vw"></div-height>
      <div-padding>
        <div-text size="5vw" bold>{{info.company_name}}</div-text>
        <div-height height="3vw"></div-height>
        <div-left>
          <div-text size="3vw" color="#636363">公司简介</div-text>
          <div-text size="3vw" color="#636363">：{{info.company_info}}</div-text>
        </div-left>

        <div-height height="3vw"></div-height>
        <div-left center>
          <div-img width="7vw" height="7vw" radius="50vw">
            <img :src="$img(info.company_icon)" alt="" />
          </div-img>
          <div-width width="2vw"></div-width>
          <div-left>
            <div-text size="4vw" color="#8A8A8A">主席</div-text>
            <div-text size="4vw" color="#8A8A8A">：{{info.company_chairman}}</div-text>
          </div-left>

        </div-left>
        <div-height height="4vw"></div-height>
        <van-divider />
        <div-height height="3vw"></div-height>
        <div-left>
          <div-text size="4vw" color="#8A8A8A">上市时间</div-text>
          <div-width width="9vw"></div-width>
          <div-text size="4vw" color="#000000">{{info.company_up}}</div-text>
        </div-left>

        <div-height height="1vw"></div-height>
        <div-left>
          <div-text size="4vw" color="#8A8A8A">发行价格</div-text>
          <div-width width="9vw"></div-width>
          <div-text size="4vw" color="#000000">{{info.price}}</div-text>
        </div-left>
        <div-height height="1vw"></div-height>
        <div-left>
          <div-text size="4vw" color="#8A8A8A">发行数量</div-text>
          <div-width width="9vw"></div-width>
          <div-text size="4vw" color="#000000">{{info.stock}}</div-text>
        </div-left>
        <div-height height="3vw"></div-height>
      </div-padding>
    </div-card>
  </div>
</template>
<script>
export default {
  name: "corp",
  props: ["info"],
  data() {
    return {}
  },
  created() {
    console.log("mmm", this.info)
  },
  methods: {},
}
</script>
<style lang="scss" scoped>
</style>