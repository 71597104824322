<template>
  <div-box>
    <div-navber title="邀请列表"></div-navber>
    <!-- 封装上拉下拉组件 坐标 vant-extend 文件 -->

    <div class="new_box">
      <div-padding padding="4vw 4vw">
        <vant-up-down ref="list" @list="(val) => (list = val)" res="res.data.data" url="/user/share/list" :data="{ classify_id: 1 }">
          <div class="item" @click="router('new', item.id)" v-for="(item, index) in list" :key="index">
            <div-card>
              <div-left>
                <div-img width="10vw" height="10vw" radius="5vw">
                  <img :src="$img(item.head_img)" />
                </div-img>
                <div-padding padding="0 3vw">
                  <div-left center>
                    <div-text size="4vw" color="#000000" one bold>
                      {{ item.phone }}
                    </div-text>
                    <div-width width="10vw"></div-width>
                    <div-text size="4vw" color="#000000" bold>{{
                      item.nickname
                    }}</div-text>
                  </div-left>
                </div-padding>

              </div-left>
            </div-card>
            <div v-if="index * 1 + 1 != list.length">
              <div-height height="4vw" />
              <div-height height="1px" bg="#F1F2F6"></div-height>
              <div-height height="4vw" />
            </div>
          </div>
          <!-- </page-list> -->
        </vant-up-down>
      </div-padding>
    </div>
  </div-box>
</template>

<script>
import divLeft from "../../../../components/div-ui/div-left.vue"
export default {
  components: { divLeft },
  data() {
    return {
      list: [],
    }
  },
  created() {
    // 滚动到顶部
    this.vant_list()
  },
  methods: {},
}
</script>

<style scoped lang='scss'>
</style>
